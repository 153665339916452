import React from "react";
import { useState } from "react";
import { useEffect } from "react";

const AuctionBidList = ({ bids, reservedPrice, symbol, isToken }) => {
  const [newBid, setNewBid] = useState([]);
  useEffect(() => {
    async function returnPercentage() {
      try {
        const bid = await Promise.all(
          bids.map((item) => {
            const percentage = calculateBidIncreasePercentage(
              item.highestBid,
              reservedPrice
            );
            ////console.log(percentage, reservedPrice);
            return {
              ...item,
              percentage,
            };
          })
        );
        setNewBid(bid);
        ////console.log(bid);
      } catch (err) {
        ////console.log(err.message);
      }
    }
    returnPercentage();
  }, [bids, reservedPrice]);

  function calculateBidIncreasePercentage(bidAmount, reservedPrice) {
    const increaseAmount = bidAmount - reservedPrice;
    const increasePercentage = (increaseAmount / reservedPrice) * 100;
    return increasePercentage;
  }

  return (
    <React.Fragment>
      {newBid.map((data) => (
        <div className="sasE_RfdsD_Y">
          <div className="saS_RRFFY_Yggfdg_D">
            <div className="sAS_eER_FfF_GGFD">
              <div className="BiddeR_asASE_s">
                <div className="sas_WWW_Ed_SD_RtW_E">Bidder:</div>
                <div className="sas_WWW_Ed_SD_Rt">
                  {data.bidder.substring(0, 5)}...
                  {data.bidder.substring(data.bidder.length - 5)}
                </div>
              </div>
              <div className="sasW_ERRTY_HGHJGddf4fd_ss">
                <div className="sas_WWW_Ed_SD_RttEE">Amount: </div>
                <div className="sas_WWW_Ed_SD_Rt">{data.highestBid}</div>
                {isToken ? (
                  <div className="sas_WWW_Ed_SD_Rt">{symbol}</div>
                ) : (
                  <div className="sas_WWW_Ed_SD_Rt">ETH</div>
                )}
              </div>

              <div className="sasW_ERRTY_HGHJGddf4fd_ss">
                <div className="sas_WWW_Ed_SD_RttEE">Volume: </div>
                <div className="sas_WWW_Ed_SD_Rt sEE_RFDFdf_D">
                  {Number(data.percentage).toFixed(2)} %
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </React.Fragment>
  );
};

export default AuctionBidList;
