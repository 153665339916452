// Import the functions you need from the SDKs you need
import firebase from "firebase/compat/app";
import { getAnalytics } from "firebase/analytics";
import "firebase/compat/firestore";
import { getAuth } from "firebase/auth";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import { onTokenChanged } from "firebase/app-check";
import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCEruhr20mQNoNJJutaXhFa2yISifzVP3A",
  authDomain: "mentalabz.firebaseapp.com",
  databaseURL: "https://mentalabz-default-rtdb.firebaseio.com",
  projectId: "mentalabz",
  storageBucket: "mentalabz.appspot.com",
  messagingSenderId: "507747666214",
  appId: "1:507747666214:web:2a0c3cbb1577e3a4fb99e7",
  measurementId: "G-49E4NQKJJQ",
};

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const firestore = getFirestore(app);

const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider("6LeT9JwlAAAAAK89TSFbrFVAmeROJd9PXnAlf81Q"),
  isTokenAutoRefreshEnabled: true,
});

// Listen for token changes
onTokenChanged(appCheck, async (token) => {
  return token;
});

// Export necessary modules
export { app, analytics, auth, firestore };
