import abi from "./abi.json"; 


function abiERC721() {
  return abi;
}

const abiImporter = abiERC721();

export default abiImporter;
