/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { getItem, getOwnerShipByItem } from "../../../../Utils/getTokenById";
import { useParams } from "react-router-dom";
import "./NFTInfoCard.css";
import loading from "../../../../Assets/Images/logo.gif";
import { makeId } from "../../../../Utils/nonceGenerator";
import {
  getActivitiesByItem,
  getBidsByItem,
  getSalesByItem,
} from "../../../../Utils/getStats";
import NftInfoData from "./NftInfoData";
import InfoDropdown from "./InfoDropdown";
import { faCircleInfo, faBars } from "@fortawesome/free-solid-svg-icons";
import TokenInfo from "./TokenInfo";
import ContentAttribute from "./ContentAttribute";
import {
  getSepoliaNftsByItem,
  getSepoliaOwnersByItem,
} from "../../../../Utils/sepolia/sepolia";
import { EvmChain } from "@moralisweb3/common-evm-utils";
import { generateToken } from "../../../../Utils/nonceTAGWEB3";

const NFTInfoCard = () => {
  const [nftsInfo, SetNftsInfo] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [attributes, setAttributes] = useState([]);
  const { contract, tokenId } = useParams();
  const [tokenOwner, setTokenOwner] = useState([]);
  const [activities, setActivities] = useState([]);

  /*useEffect(() => {
    const getOwner = async () => {
      try {
        const ownerByItem = await getOwnerShipByItem(contract, tokenId);
        const item = ownerByItem.ownerships;
        const owner = item.map((data) => ({
          ...data,
          owner: data.owner.replace(/^ETHEREUM:/, ""),
        }));
        setTokenOwner(owner);
      } catch (err) {
        ////console.log(err);
      }
    };
    getOwner();
  }, [contract, tokenId]);

  useEffect(() => {
    const getAttributes = async () => {
      const item = await getItem(contract, tokenId);
      if (item) {
        setAttributes(item.meta.attributes);
      }
    };
    getAttributes();
  }, [contract, tokenId]);*/

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 5000);
  }, []);

  useEffect(() => {
    const getNftsByItem = async () => {
      const chain = EvmChain.SEPOLIA;
      let token = [];
      const owner = await getSepoliaOwnersByItem(contract, chain, tokenId);
      const item = await getSepoliaNftsByItem(contract, tokenId);
      token.push(item);
      const data = token.map((item) => ({
        ...item,
        image: item.rawMetadata.image.replace(
          "ipfs://",
          "https://ipfs.io/ipfs/"
        ),
        attributes: item?.rawMetadata?.attributes || [],
        owners: [{ owner: owner.owner_of }],
      }));
      SetNftsInfo(data);
    };
    getNftsByItem();
  }, [contract, tokenId]);

  ////console.log(nftsInfo);

  return (
    <div className="iks-lk4">
      <div className="as4er1">
        {nftsInfo.length > 0 &&
          nftsInfo.map((data) => (
            <React.Fragment key={makeId(10)}>
              <div key={makeId(10)} className="sa45ww">
                <div className="a5a5ee">
                  <div className="ss4e5tu">
                    <div className="yjw-s4g">
                      <div className={`${isLoading ? "asw-ds5" : "asw-ds4"}`}>
                        {isLoading ? (
                          <img
                            src={loading}
                            alt={`NFT:${data.tokenId}`}
                            className={`${
                              isLoading ? "jawe-nfts-we2" : "jawe-nfts-we"
                            }`}
                          />
                        ) : (
                          <img
                            src={data.image && data.image}
                            alt={`NFT:${data.tokenId}`}
                            className={`${
                              isLoading ? "jawe-nfts-we2" : "jawe-nfts-we"
                            }`}
                          />
                        )}
                      </div>
                    </div>
                    <div className="info-aswerj">
                      <div className="aksj-dfg44h">
                        <div className="asakjd--gy">
                          <React.Fragment>
                            <InfoDropdown
                              icon={faCircleInfo}
                              title={"Token Details"}
                              info={
                                <TokenInfo
                                  tokenId={data.tokenId}
                                  contractAddress={data.contract.address}
                                  tokenOwner={data.owners}
                                />
                              }
                            />
                          </React.Fragment>
                        </div>
                      </div>
                      <div className="aksj-dfg44h">
                        <div className="asakjd--gy">
                          <InfoDropdown
                            title={"Description"}
                            info={data.description}
                            icon={faCircleInfo}
                          />
                        </div>
                      </div>
                      <div className="aksj-dfg44h">
                        <div className="asakjd--gy">
                          <InfoDropdown
                            title={"Properties"}
                            info={
                              data.attributes && (
                                <ContentAttribute data={data.attributes} />
                              )
                            }
                            icon={faBars}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="alsd-fd144td">
                <React.Fragment>
                  <NftInfoData
                    currentOwner={data.owners}
                    tokenName={data.contract?.name}
                    price={data.bestSellOrder && data.bestSellOrder.makePrice}
                    priceInUSD={
                      data.bestSellOrder && data.bestSellOrder.makePriceUsd
                    }
                    contractAddress={data && data.contract.address}
                    platform={data.platform && data.platform}
                    activities={activities}
                    tokenType={data && data.tokenType}
                  />
                </React.Fragment>
              </div>
            </React.Fragment>
          ))}
      </div>
    </div>
  );
};

export default NFTInfoCard;
