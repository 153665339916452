import React from "react";
import isLoadingGif from "../../../Assets/Images/logo.gif";
import "../../../Styles/isLoading.css";
import ParticlesEffect from "./ParticlesEffect";

const IsLoadingPage = () => {
  return (
    <div className="ieks-asw">
        <ParticlesEffect />
      <div className="pao-swe">
        <div className="ala-s">
          <div className="aol-aw">
            <img src={isLoadingGif} alt="loading" className="saw-errt" />
            {/*<p className="sak-sewr">Loading, please wait</p>*/}
          </div>
        </div>
      </div>
    </div>
  );
};

export default IsLoadingPage;
