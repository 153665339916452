/* eslint-disable no-unused-vars */
function calculateElapsedTime(startDate) {
  if (!(startDate instanceof Date)) {
    throw new Error("Invalid start date");
  }

  const currentDate = new Date();
  const startTime = startDate.getTime();
  const currentTime = currentDate.getTime();
  const elapsedTime = currentTime - startTime;

  // Calculate the elapsed time in different units
  const seconds = Math.floor(elapsedTime / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const months = Math.floor(days / 30);
  const years = Math.floor(months / 12);

  if (years > 0) {
    return years + " year" + (years > 1 ? "s" : "");
  } else if (months > 0) {
    return months + " month" + (months > 1 ? "s" : "");
  } else if (days > 0) {
    return days + " day" + (days > 1 ? "s" : "");
  } else if (hours > 0) {
    return hours + " hour" + (hours > 1 ? "s" : "");
  } else {
    return minutes + " minute" + (minutes > 1 ? "s" : "");
  }
}

function convertTimestampToDate(timestamp) {
  const date = new Date(timestamp * 1000); // Convert UNIX timestamp to milliseconds

  const year = date.getUTCFullYear();
  const month = `0${date.getUTCMonth() + 1}`.slice(-2); // Months are zero-based, so we add 1
  const day = `0${date.getUTCDate()}`.slice(-2);
  const hours = `0${date.getUTCHours()}`.slice(-2);
  const minutes = `0${date.getUTCMinutes()}`.slice(-2);
  const seconds = `0${date.getUTCSeconds()}`.slice(-2);

  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}Z`;
}

function getElapsedTime(startDate) {
  const now = new Date();
  const startTime = new Date(startDate * 1000); // Convert seconds to milliseconds
  const timeDifference = startTime.getTime() - now.getTime();

  if (timeDifference <= 0) {
    // If the startTime is in the past or current time, return 0 for all components
    return {
      hours: 0,
      minutes: 0,
      seconds: 0,
      elapsedTimeString: "0 seconds",
    };
  }

  const seconds = Math.floor(timeDifference / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);

  const remainingSeconds = seconds % 60;
  const remainingMinutes = minutes % 60;
  const remainingHours = hours % 24;

  let elapsedTimeString = "";

  if (remainingHours > 0) {
    elapsedTimeString += `${remainingHours} hour${
      remainingHours > 1 ? "s" : ""
    }, `;
  }

  if (remainingMinutes > 0) {
    elapsedTimeString += `${remainingMinutes} minute${
      remainingMinutes > 1 ? "s" : ""
    }, `;
  }

  elapsedTimeString += `${remainingSeconds} second${
    remainingSeconds > 1 ? "s" : ""
  }`;

  return {
    hours: remainingHours,
    minutes: remainingMinutes,
    seconds: remainingSeconds,
    elapsedTimeString: elapsedTimeString.trim(),
  };
}

function convertDateFormat(dateString) {
  if (!dateString || typeof dateString !== "string") {
    // Handle the case where dateString is not provided or is not a string
    console.error("Invalid dateString:", dateString);
    return null; // or return a default date or throw an error
  }

  const months = {
    enero: "01",
    febrero: "02",
    marzo: "03",
    abril: "04",
    mayo: "05",
    junio: "06",
    julio: "07",
    agosto: "08",
    septiembre: "09",
    octubre: "10",
    noviembre: "11",
    diciembre: "12",
  };

  const [day, monthStr, year, time] = dateString.split(/ de |, /);

  if (!day || !monthStr || !year || !time) {
    // Handle the case where the dateString format is unexpected
    console.error("Invalid dateString format:", dateString);
    return null; // or return a default date or throw an error
  }

  const [hours, minutes, seconds] = time.split(":");

  const month = months[monthStr.toLowerCase()]; // Convert monthStr to lowercase for case-insensitive matching

  if (!month) {
    // Handle the case where the monthStr is not valid
    console.error("Invalid month:", monthStr);
    return null; // or return a default date or throw an error
  }

  return new Date(year, month - 1, day, hours, minutes, seconds);
}

function calculateTimeElapsed(timeString) {
  const providedTime = new Date(timeString);
  const currentTime = new Date();

  const timeDifference = currentTime - providedTime;

  // Calculate the time components (days, hours, minutes, seconds)
  const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

  // Format the time elapsed into a human-readable string
  if (days >= 30) {
    const months = Math.floor(days / 30);
    return `${months} ${months === 1 ? "month" : "months"} ago`;
  } else if (days >= 1) {
    return `${days} ${days === 1 ? "day" : "days"} ago`;
  } else if (hours >= 1) {
    return `${hours} ${hours === 1 ? "hour" : "hours"} ago`;
  } else if (minutes >= 1) {
    return `${minutes} ${minutes === 1 ? "minute" : "minutes"} ago`;
  } else {
    return `${seconds} ${seconds === 1 ? "second" : "seconds"} ago`;
  }
}

export {
  calculateElapsedTime,
  convertDateFormat,
  convertTimestampToDate,
  calculateTimeElapsed,
  getElapsedTime,
};
