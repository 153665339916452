/* eslint-disable no-unused-vars */
import { rpcapi } from "../Components/Web3/RPCChain";
import { isTrustWallet, web3Connector } from "../Components/Web3/web3";
import { ethers } from "ethers";
import abi from "@chainlink/contracts/abi/v0.4/AggregatorV3Interface.json";
import abiErc20 from "./abiERC20.json";
import { sepoliaAPI } from "./APIs/APIList";

const priceFeedAddress = "0x5f4eC3Df9cbd43714FE2740f5E3616155c5b8419"; // Chainlink ETH/USD price feed contract address
const weth = "0xE67ABDA0D43f7AC8f37876bBF00D1DFadbB93aaa";

const priceFeedABI = abi;

async function getCurrentEthPrice() {
  try {
    const web3 = await web3Connector(sepoliaAPI);
    const priceFeed = new web3.eth.Contract(priceFeedABI, priceFeedAddress);
    const result = await priceFeed.methods.latestRoundData().call();
    const decimals = await priceFeed.methods.decimals().call();
    const price = ethers.utils.formatUnits(result.answer, decimals);
    return parseFloat(price).toFixed(2);
  } catch (err) {}
}

async function getCurrentWethPrice() {
  try {
    const web3 = await web3Connector(sepoliaAPI);
    const priceFeed = new web3.eth.Contract(priceFeedABI, priceFeedAddress);
    const result = await priceFeed.methods.latestRoundData().call();
    const decimals = await priceFeed.methods.decimals().call();
    const price = ethers.utils.formatUnits(result.answer, decimals);
    return parseFloat(price).toFixed(2);
  } catch (err) {}
}
async function getBalanceInWeth(address) {
  let wethBalance;
  try {
    const web3 = await web3Connector(rpcapi);
    const contract = new web3.eth.Contract(abiErc20, weth);
    const balance = await contract.methods.balanceOf(address).call();
    if (balance === 0) {
      wethBalance = balance;
    } else {
      const balanceFormatted = web3.utils.fromWei(balance, "ether");
      wethBalance = balanceFormatted;
    }
    return wethBalance;
  } catch (err) {}
}

async function getWallet(provider) {
  const web3 = await web3Connector(provider);
  const wallet = await web3.eth.getAccounts();
  if (wallet[0] === undefined) {
  } else {
    const lowerAddress = wallet[0].toLowerCase();
    return lowerAddress;
  }
}

async function getBalance() {
  const isTrust = isTrustWallet(rpcapi);
  const web3 = await web3Connector(rpcapi);
  try {
    let account;
    if (typeof rpcapi !== "undefined") {
      account = await getWallet(rpcapi);
    } else if (isTrust === true) {
      account = await getWallet(rpcapi);
    }
    const balance = await web3.eth.getBalance(account);
    const etherBalance = web3.utils.fromWei(balance, "ether");
    const numberBalance = Number(etherBalance).toFixed(4);
    return numberBalance;
  } catch (err) {}
}

async function getBalanceInUSD(address) {
  const ethPrice = await getCurrentEthPrice();
  const balance = await getBalance(address);
  const balanceInUSD = balance * ethPrice;
  return balanceInUSD.toFixed(2);
}

export {
  getBalance,
  getBalanceInUSD,
  getCurrentEthPrice,
  getCurrentWethPrice,
  getBalanceInWeth,
};
