
import React, { useState, useEffect } from "react";
import { app } from "../../../../Database/Firebase/firebaseConfig";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar as solidStar } from "@fortawesome/free-solid-svg-icons";
import { faStar as regularStar } from "@fortawesome/free-regular-svg-icons";
import { toast } from "react-hot-toast";
import "../../../../Styles/Favorite.css";

const FavoriteButton = ({ collectionAddress, userAddress }) => {
  const [isFavorite, setIsFavorite] = useState(false);

  const db = app.firestore();

  useEffect(() => {
    // Check if the current user has added this collection to their watchlist
    if (userAddress) {
      const userRef = db.collection("userWatchlist").doc(userAddress);
      userRef.get().then((doc) => {
        if (doc.exists) {
          const watchlist = doc.data().watchlist;
          setIsFavorite(watchlist.includes(collectionAddress));
        }
      });
    }
  }, [userAddress, collectionAddress, db]);

  const handleFavorite = () => {
    if (userAddress) {
      const userRef = db.collection("userWatchlist").doc(userAddress);
      userRef.get().then((doc) => {
        const watchlist = doc.exists ? doc.data().watchlist : [];
        if (!watchlist.includes(collectionAddress)) {
          watchlist.push(collectionAddress);
          userRef.set({ watchlist });
          setIsFavorite(true);
          toast.success(<span>Added to Watchlist</span>, {
            duration: 3000,
          });
        } else {
          const index = watchlist.indexOf(collectionAddress);
          watchlist.splice(index, 1);
          userRef.set({ watchlist });
          setIsFavorite(false);
        }
      });
    } else {
      toast.error("You must log in with your wallet");
    }
  };

  return (
    <div className="favorite-button" onClick={handleFavorite}>
      <FontAwesomeIcon
        icon={isFavorite ? solidStar : regularStar}
        className={isFavorite ? "yellow" : ""}
      />
    </div>
  );
};

export default FavoriteButton;
