import React, { useEffect, useState } from "react";
import Grid from "./Grid";
import { makeId } from "../../../Utils/nonceGenerator";
import { app } from "../../../Database/Firebase/firebaseConfig";
import { getCollectionDataById } from "../../../Utils/getAllNfts";

const db = app.firestore();

const RouterData = ({
  contract,
  id,
  floor,
  volume,
  floorChange,
  items,
  owners,
}) => {
  const [name, setName] = useState("");
  const [cover, setCover] = useState("");

  useEffect(() => {
    const getName = async () => {
      if (contract) {
        try {
          const dataRef = db.collection("collection").doc(contract);
          const dataDoc = await dataRef.get();
          const response = await getCollectionDataById(contract);
          const type = response.type;
          const nameColl = response.name;
          const descriptionColl = response.meta?.description;
          const addressCollection = response.id.replace(/^ETHEREUM:/, "");
          const coverColl = response.meta?.content[0]?.url;
          const externalLink = response.meta?.externalUri;
          const ownerColl = response.owner.replace(/^ETHEREUM:/, "");
          const symbol = response.symbol;
          setName(nameColl);
          if (dataDoc.exists) {
            const data = dataDoc.data();
            const description = data.description;
            const cover = data.cover;
            const owner = data.owner;
            const address = data.address;
            setCover(cover);
            if (!description) {
              await dataRef.update({
                description: descriptionColl,
                type,
                externalLink,
                totalSuppy: items,
                owners,
                symbol,
              });
            } else if (!cover) {
              await dataRef.update({
                cover: coverColl,
                type,
                externalLink,
                totalSuppy: items,
                owners,
                symbol,
              });
            } else if (!owner) {
              await dataRef.update({
                owner: ownerColl,
                type,
                externalLink,
                totalSuppy: items,
                owners,
                symbol,
              });
            } else if (!address) {
              await dataRef.update({
                address: addressCollection,
                type,
                externalLink,
                totalSuppy: items,
                owners,
                symbol,
              });
            }
          } else {
            setCover(coverColl);
            await dataRef.update({
              name: nameColl,
              description: descriptionColl,
              cover: coverColl,
              owner: ownerColl,
              address: addressCollection,
              owners: owners,
              externalLink,
              type,
              totalSuppy: items,
              symbol,
            });
          }
        } catch (err) {
          console.error(err);
        }
      }
    };

    getName();
  }, [contract, owners, items]);

  return (
    <React.Fragment>
      <Grid
        key={makeId(10)}
        id={id}
        name={name}
        contract={contract}
        floor={floor}
        volume={volume}
        floorChange={floorChange}
        items={items}
        owners={owners}
        cover={cover}
      />
    </React.Fragment>
  );
};

export default RouterData;
