import React from "react";
import img1 from "../../../Assets/Images/0x0000000000000000000000000000000000000000000000000000000000000003.png";
import img2 from "../../../Assets/Images/0x0000000000000000000000000000000000000000000000000000000000000004.png";
import img3 from "../../../Assets/Images/QmdsxYwtbDKmx6fz6CP5FLCH3hkkuZ3EDBFBTcHHRrsSRM.png";
import "./NftCard.css";
import coversw from "../../../Assets/Images/0x23581767a106ae21c074b2276d25e5c3e136a68b.png";
import cover2 from "../../../Assets/Images/0xed5af388653567af2f388e6224dc7c4b3241c544.png";
import cover3 from "../../../Assets/Images/0x60e4d786628fea6478f785a6d7e704777c86a7c6.png";
import "boxicons";

const NFTCard = () => {
  return (
    <div className="si-ew">
      <div className="aso-aa">
        <div className="as-ssw ps-llw">
          <div className="aaa-ees sla main-page">
            <img src={img1} alt="img1" className="skl " />
            <div className="ass-aawwe">
              <div className="ool-ppo">
                TOKEN ID: 3
              </div>
            </div>
            <div className="olls">
              <img src={coversw} alt="" className="sas" />
              <div className="awe-gg">
                Moonbird{" "}
                <div className="kk-kks">
                  <box-icon name="badge-check" type="solid" color="#465ee8" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="as-ssw poo-saw">
          <div className="aaa-ees sk-aaw main-page">
            <img src={img2} alt="img1" className="skl" />
            <div className="ass-aawwe">
              <div className="ool-ppo">
                TOKEN ID: 4
              </div>
            </div>
            <div className="olls">
              <img src={cover2} alt="" className="sas" />
              <div className="awe-gg">
                Azuki{" "}
                <div className="kk-kks">
                  <box-icon name="badge-check" type="solid" color="#465ee8" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="as-ssw ps-lle">
          <div className="aaa-ees sla main-page">
            <img src={img3} alt="img1" className="skl " />
            <div className="ass-aawwe">
              <div className="ool-ppo">
                TOKEN ID: 2
              </div>
            </div>
            <div className="olls">
              <img src={cover3} alt="" className="sas" />
              <div className="awe-gg">
                Mutant Ape Yatch Club
                <div className="kk-kks">
                  <box-icon name="badge-check" type="solid" color="#465ee8" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NFTCard;
