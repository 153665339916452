import React, { useEffect, useState } from "react";
import "../../../../Styles/cover.css";

const Cover = ({ cover, name }) => {
  const [newCover, setNewCover] = useState();

  useEffect(() => {
    const checkCover = () => {
      if (cover.startsWith("https://ipfs.io/ipfs/https://ipfs")) {
        const coverFormatter = cover.replace(
          "https://ipfs.io/ipfs/https://ipfs.io/ipfs",
          "https://ipfs.io/ipfs"
        );
        setNewCover(coverFormatter);
      } else if (cover.startsWith("https://ipfs.io/ipfs/https://")) {
        const coverFormatter = cover.replace(
          "https://ipfs.io/ipfs/https://",
          "https://"
        );
        setNewCover(coverFormatter);
      } else {
        setNewCover(cover);
      }
    };
    checkCover();
  }, [cover]);

  return (
    <div className="oki-dlo">
      <div className="kk-llo">
        <div className="kk-awweryt">
          <img src={newCover} alt={name} className="lak-sawe" />
        </div>
      </div>
    </div>
  );
};

export default Cover;
