/* eslint-disable no-unused-vars */
import React from "react";
import { useState } from "react";
import { getBalanceByOwner } from "../../../../Utils/utilWeb3";
import { useEffect } from "react";
import { makeId } from "../../../../Utils/nonceGenerator";
import { Link, useParams } from "react-router-dom";
import NFTCard from "../BodyCollection/NFTCard";
import "../../../../Styles/Holding.css";
import { getSepoliaNftsByOwners } from "../../../../Utils/sepolia/sepolia";

const Holding = () => {
  const { address } = useParams();
  const [continuationString, setContinuationString] = useState("");
  const [prevContinuation, setPrevContinuation] = useState("");
  const [nfts, setNfts] = useState([]);

  /* get All Nfts */
  const getDataColl = async (continuation, userAddress) => {
    const data = await getSepoliaNftsByOwners(userAddress, continuation);
    const dataCursor = data.pageKey;
    const result = data.ownedNfts;

    if (dataCursor) {
      setContinuationString(dataCursor);
    }
    if (result) {
      return result;
    }
  };

  /*Function to get the nfts using cursor */
  const loadNFTs = async (cursor) => {
    const data = await getDataColl(cursor, address);
    return data;
  };

  /*  Handle to charge more NFTs */
  const handleLoadMore = async () => {
    if (continuationString !== prevContinuation) {
      const data = await loadNFTs(continuationString);
      setPrevContinuation(continuationString);
      setNfts(data);
    }
  };

  /* Hook to fetch all data */
  useEffect(() => {
    const fetchData = async () => {
      const data = await loadNFTs(null);
      setPrevContinuation(null);
      setNfts(data);
    };
    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address]);

  /*Handle to scroll and has more items */
  const handleScroll = () => {
    const scrollTop =
      (document.documentElement && document.documentElement.scrollTop) ||
      document.body.scrollTop;
    const scrollHeight =
      (document.documentElement && document.documentElement.scrollHeight) ||
      document.body.scrollHeight;
    const clientHeight =
      document.documentElement.clientHeight || window.innerHeight;
    const scrolledToBottom =
      Math.ceil(scrollTop + clientHeight) >= scrollHeight;

    if (scrolledToBottom) {
      handleLoadMore();
    }
  };

  /* Hooks and sort settings */
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nfts]);

  /*async function getBalance() {
    try {
      const balance = await getBalanceByOwner(
        address,
        continuationString || ""
      );

      if (balance.continuation && !continuationString) {
        setContinuationString(balance.continuation);
      }

      const result = balance.items;
      if (result && result.length > 0) {
        const modifiedResult = result.map((item) => ({
          ...item,
          contract: item.contract.replace(/^ETHEREUM:/, ""),
        }));

        // Append new results to existing state using setNfts
        setNfts((prevNfts) => [...prevNfts, ...modifiedResult]);

        if (balance.continuation) {
          setContinuationString(balance.continuation);
        }
      }
    } catch (err) {
      ////console.log(err);
    }
  }

  useEffect(() => {
    getBalance();
  }, []);*/

  return (
    <div className="uja45">
      <div className="aw5r44">
        <div className="aek11">
          <div className="ss5rr">
            {nfts &&
              nfts.map((nft) => (
                <div key={makeId(10)} className="aty55">
                  <div className="a74ee">
                    <Link
                      to={`/collection/${nft.contract.address}/${nft.tokenId}`}
                      className="dfyy5i"
                    >
                      <NFTCard
                        contract={nft.contract.address && nft.contract.address}
                        tokenId={nft.tokenId && nft.tokenId}
                        image={nft.rawMetadata?.image}
                        keyPass={makeId(10)}
                      />
                    </Link>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Holding;
