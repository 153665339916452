/* eslint-disable no-mixed-operators */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import {
  calculateElapsedTime,
  calculateTimeElapsed,
  convertDateFormat,
  convertTimestampToDate,
} from "../../../../Utils/getTimeElapsed";
import { makeId } from "../../../../Utils/nonceGenerator";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRightArrowLeft,
  faCartShopping,
} from "@fortawesome/free-solid-svg-icons";
import "./ActivitiesList.css";
import eth from "../../../../Assets/Images/Ethereum Icon.svg";
import { Link } from "react-router-dom";
import { web3Connector } from "../../../Web3/web3";
import { rpcapi } from "../../../Web3/RPCChain";
import {
  erc20Symbol,
  nullAddress,
} from "../../../../Utils/getExchangeData";
import { BigNumber } from "ethers";
import { sepoliaAPI } from "../../../../Utils/APIs/APIList";

const ActivitiesList = ({ activities, isActive }) => {
  const haveActivities = Boolean(isActive);
  const [newActivities, setNewActivities] = useState([]);
  const [checkType, setCheckType] = useState(false);

  ////console.log(newActivities);

  useEffect(() => {
    async function activitieFormated() {
      const web3 = await web3Connector(sepoliaAPI);

      ////console.log(activities);

      const tokenActivities = await Promise.all(
        activities.map(async (data) => {
          const timeStampConverted = convertTimestampToDate(data.date);
          const timeElapsed = calculateTimeElapsed(timeStampConverted);
          let symbol;
          if (data.paymentToken !== nullAddress) {
            symbol = await erc20Symbol(data.paymentToken);
          }

          return {
            ...data,
            elapsedTime: timeElapsed,
            price:
              (data.price && web3.utils.fromWei(data.price, "ether")) ||
              (data.highestBid && web3.utils.fromWei(data.highestBid, "ether")),
            buyer: data.bidder || data.buyer || data.highestBidder,
            seller: data.taker || data.seller,
            symbol,
          };
        })
      );
      setNewActivities(tokenActivities);
    }
    activitieFormated();
  }, [activities]);

  return (
    <React.Fragment>
      {haveActivities ? (
        newActivities &&
        newActivities.slice(0, 5).map((data) => (
          <div key={makeId(10)} className="asd--fghgh4">
            <div className="saskff11">
              {data.from ? (
                <div className="df4g4g-fdfr">
                  <div className="fragment-sasl-h44">
                    <FontAwesomeIcon icon={faArrowRightArrowLeft} />
                  </div>
                  <div className="asf-sdslld_Fd">{data && data.type}</div>
                </div>
              ) : (
                <div className="df4g4g-salle-s">
                  <div className="fragment-sasl-h44">
                    <FontAwesomeIcon icon={faCartShopping} />
                  </div>
                  <div className="asf-sdslld_Fd">{data && data.type}</div>
                  <div className="saslk-ff44g">
                    {data.price && (
                      <React.Fragment>
                        <div className="saslk-s444fff">
                          <div
                            className={`as-fdtt ${
                              data.symbol && "sasee_RRTRTgggfD_ff4"
                            }`}
                          >
                            {data.paymentToken !== nullAddress ? (
                              <div className="sAS_WERTGGgg_HUu">
                                {data.symbol}
                              </div>
                            ) : (
                              <img src={eth} alt="ETH" className="saslk-ff" />
                            )}
                          </div>
                          <div className="sas4-sas45">
                            {Number(data.price).toFixed(4)}
                          </div>
                        </div>
                      </React.Fragment>
                    )}
                  </div>
                </div>
              )}
              <div className="dksd4h-hgh4">
                <div className="sas-gfg11h">
                  {data.from ? (
                    <React.Fragment>
                      <div className="sas--fdty-yy">
                        <div className="salsk--frtttyy">From</div>
                        <div className="sas44f-jjh4">
                          <Link
                            className="sasl-dd4"
                            to={`/account/${data.from}`}
                          >
                            {data.from && data.from.substring(0, 5)}...
                            {data.from &&
                              data.from.substring(data.from.length - 5)}
                          </Link>
                        </div>
                      </div>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <div className="sas--fdty-yy">
                        <div className="salsk--frtttyy">From</div>
                        <div className="sas44f-jjh4">
                          <Link
                            className="sasl-dd4"
                            to={`/account/${data.seller}`}
                          >
                            {data.seller && data.seller.substring(0, 5)}...
                            {data.seller &&
                              data.seller.substring(data.seller.length - 5)}
                          </Link>
                        </div>
                      </div>
                    </React.Fragment>
                  )}
                </div>
                <div className="sasl-rr44t-uu">
                  {data.to ? (
                    <React.Fragment>
                      <div className="sas--fdty-yy">
                        <div className="salsk--frtttyy">To</div>
                        <div className="sas44f-jjh4">
                          <Link className="sasl-dd4" to={`/account/${data.to}`}>
                            {data.to && data.to.substring(0, 5)}...
                            {data.to && data.to.substring(data.to.length - 5)}
                          </Link>
                        </div>
                      </div>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <div className="sas--fdty-yy">
                        <div className="salsk--frtttyy">To</div>
                        <div className="sas44f-jjh4">
                          <Link
                            className="sasl-dd4"
                            to={`/account/${data.buyer}`}
                          >
                            {data.buyer && data.buyer.substring(0, 5)}...
                            {data.buyer &&
                              data.buyer.substring(data.buyer.length - 5)}
                          </Link>
                        </div>
                      </div>
                    </React.Fragment>
                  )}
                </div>
                <div className="time-elapsed-s445s">
                  <div className="sas12d-ffgg">
                    <div className="sa414d-ffg">
                      {data.elapsedTime && data.elapsedTime}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))
      ) : (
        <div className="sase-rrt11y">
          <div className="sasf_ff4g4">
            <div className="saskiFD_Fbg">
              <div className="dsdfdgglg">Not Has Activities</div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default ActivitiesList;
