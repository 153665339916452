/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import "../../../Styles/table.css";
import "boxicons";
import { app } from "../../../Database/Firebase/firebaseConfig";
import eth from "../../../Assets/Images/800px-Ethereum_logo_2014.svg.png";
import { Link } from "react-router-dom";

const db = app.firestore();

const gateway = "https://ipfs.io/ipfs";
const noCover = "QmaoRr4Rdgrnod7Hs6ULZVJibzH2xmLq4fGySR3Tg2Dfzj";

const TableTrend = ({
  name,
  totalSales,
  average,
  volume,
  floor,
  tokenAddr,
  address,
  index,
  checked,
  onMainnet,
}) => {
  //Hooks
  const [blueCheck, setBlueCheck] = useState();
  const [image, setImage] = useState("");

  //CheckIsVerified
  async function checkIfVerified() {
    const collectionRef = db.collection("collection").doc(address);
    const collectionDoc = await collectionRef.get();
    try {
      if (collectionDoc.exists) {
        const { verified } = collectionDoc.data();
        if (verified) {
          const isBlueCheck = (
            <box-icon name="badge-check" type="solid" color="#465ee8" />
          );
          setBlueCheck(isBlueCheck);
        }
      }
    } catch (err) {
      //console.log(err);
    }
  }

  useEffect(() => {
    checkIfVerified();
  }, []);

  async function checkVerification() {
    try {
      const addrInit = db.collection("collection").doc(address);

      const addrDoc = await addrInit.get();

      if (addrDoc.exists) {
        const { cover, name } = addrDoc.data();

        if (cover) {
          const image = (
            <img key={index} src={cover} alt={name} className="saj-ees" />
          );
          setImage(image);
        } else {
          const imageNoCover = (
            <img
              key={index}
              src={`${gateway}/${noCover}`}
              alt={name}
              className="saj-ees"
            />
          );
          setImage(imageNoCover);
        }
      } else {
        setBlueCheck(null);
        await addrInit.set({
          name: name,
        });
        const imageNoCover = (
          <img
            key={index}
            src={`${gateway}/${noCover}`}
            alt={name}
            className="saj-ees"
          />
        );
        setImage(imageNoCover);
      }
    } catch (err) {
      //console.log(err);
    }
  }

  //Number Suffix Aggregator
  function abbreviateNumber(num) {
    if (num >= 1000) {
      var suffixes = ["", "K", "M", "B", "T"]; // Define the suffixes for thousands, millions, billions, trillions, etc.
      var suffixIndex = Math.floor((("" + num).length - 1) / 3); // Calculate the index of the suffix to use based on the number of digits
      var shortNum = parseFloat(
        (suffixIndex != 0
          ? num / Math.pow(1000, suffixIndex)
          : num
        ).toPrecision(3)
      ); // Divide the number by the appropriate power of 1000 and round it to 3 significant digits
      return shortNum + suffixes[suffixIndex]; // Add the suffix to the shortened number and return it
    }
    return num; // If the number is less than 1000, return it unchanged
  }

  const numberCoverted = abbreviateNumber(volume.toFixed(0));

  useEffect(() => {
    checkVerification();
  }, []);
  return (
    <div className="saki-ss" onClick={onMainnet}>
      <div className="lsai-sawe">
        <div className="skdk">
          <Link /*to={`/collection/${address}`}*/ className="loa-swee ski-looi">
            <div className="sasl-eas boxasw">
              <div className="sakis-awerws sli-sli">
                <div className="skai">{image}</div>
                <div className="sals mx-width">
                  <p className="jjsue-saw text-left nmul lskk-sdae">{name}</p>
                  {blueCheck}
                </div>

                <div className="iksa">
                  <img src={eth} alt="eth" className="sik-swe" />
                  <p className="jjsue-saw text-right mn-width lo-sallh">
                    {floor.toFixed(3)}
                  </p>
                </div>
                <div className="iksa-h">
                  <img src={eth} alt="eth" className="sik-swe" />
                  <p className="jjsue-saw text-right mn-width lo-sall">
                    {numberCoverted}
                  </p>
                </div>
                <p className="jjsue-saw text-right mn-width sales-aee">
                  {totalSales}
                </p>
              </div>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default TableTrend;
