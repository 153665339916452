import React, { useState } from "react";
import { getElapsedTime } from "../../../../Utils/getTimeElapsed";
import { useEffect } from "react";

const AuctionTimeElapsed = ({ startDate }) => {
  const [elapsedTime, setElapsedTime] = useState(getElapsedTime(startDate));

  useEffect(() => {
    const interval = setInterval(() => {
      const newElapsedTime = getElapsedTime(startDate);
      setElapsedTime(newElapsedTime);
    }, 1000); // Update the counter every second

    return () => {
      clearInterval(interval); // Clean up the interval on unmount
    };
  }, [startDate]);

  return (
    <span className="aS_wE_RRT">
      {elapsedTime.hours > 0 && (
        <>
          <span className="sasW_SwEER_r_Time">{elapsedTime.hours}</span> hour
          {elapsedTime.hours > 1 ? "s" : ""},{" "}
        </>
      )}
      {elapsedTime.minutes > 0 && (
        <>
          <span className="sasW_SwEER_r_Time">{elapsedTime.minutes}</span>{" "}
          minute{elapsedTime.minutes > 1 ? "s" : ""},{" "}
        </>
      )}
      <span className="sasW_SwEER_r_Time">{elapsedTime.seconds}</span> second
      {elapsedTime.seconds > 1 ? "s" : ""}
    </span>
  );
};

export default AuctionTimeElapsed;
