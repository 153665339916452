/* eslint-disable no-unused-vars */
import React, { useState, useEffect, lazy, Suspense } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import Navbar from "./Components/Navbar/Navbar";
import Home from "./Components/Pages/Home";
import NewCollectionPage from "./Components/Pages/Collection/NewCollectionPage";
import EditSection from "./Components/Pages/Collection/EditSection/EditSection";
import Profile from "./Components/Pages/Collection/Profile/Profile";
import NFTInfo from "./Components/Pages/Collection/NFTPage/NFTInfo";
import NotFound from "./Components/Pages/404/NotFound";
import Test1 from "./Test/test#1";
import Foot from "./Components/Pages/Sections/Foot";
import IsLoadingPage from "./Components/Pages/Sections/isLoading";
import "./Styles/App.css";
import "./Styles/Responsive.css";
import { makeId } from "./Utils/nonceGenerator";
import Collections from "./Components/Pages/Explore/Collections";
import TxWindow from "./Components/Pages/TransactionStat/TxWindow";
import { app } from "./Database/Firebase/firebaseConfig";
import { web3Connector } from "./Components/Web3/web3";
import { rpcapi } from "./Components/Web3/RPCChain";

const db = app.firestore();

function App() {
  const [isLoading, setIsLoading] = useState(false);
  const [showTxWindow, setShowTxWindow] = useState();
  const [pending, setPending] = useState();
  const [sucess, setSucess] = useState();
  const [fail, setFail] = useState();
  const [txHash, setTxHash] = useState("");
  const [wallet, setWallet] = useState();

  useEffect(() => {
    const unsubscribe = db
      .collection("buttons")
      .doc(`showTxWindow:${wallet}`)
      .onSnapshot((snapshot) => {
        if (snapshot.exists) {
          const { isPending, isSucess, isFail, showItem, txHash } =
            snapshot.data();
          setPending(isPending);
          setTxHash(txHash);
          setSucess(isSucess);
          setFail(isFail);
          setShowTxWindow(showItem);
        }
      });

    return () => unsubscribe();
  }, [wallet]);

  async function sendTx(txHash) {
    try {
      const ref = db.collection("buttons").doc(`showTxWindow:${wallet}`);
      const doc = await ref.get();
      if (doc.exists) {
        await ref.update({
          txHash,
        });
      } else {
        await ref.set({
          txHash,
        });
      }
    } catch (err) {
      console.log(err);
    }
  }

  async function handleDataTxWindow(showItem) {
    try {
      const ref = db.collection("buttons").doc(`showTxWindow:${wallet}`);
      const doc = await ref.get();
      if (doc.exists) {
        await ref.update({
          showItem,
        });
      } else {
        await ref.set({
          showItem,
        });
      }
    } catch (err) {
      console.log(err);
    }
  }

  const handleUpdateState = async () => {
    try {
      await handleDataTxWindow(false);
      await sendTx("");
    } catch (err) {}
  };

  useEffect(() => {
    async function getWalletAddress(provider) {
      const web3 = await web3Connector(provider);
      const wallet = await web3.eth.getAccounts();
      if (wallet[0] === undefined) {
      } else {
        const lowerAddress = wallet[0].toLowerCase();
        setWallet(lowerAddress);
        return lowerAddress;
      }
    }

    getWalletAddress(rpcapi);
  }, []);

  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  return (
    <React.Fragment>
      {isLoading ? (
        <Suspense fallback={<div>Loading...</div>}>
          <IsLoadingPage />
        </Suspense>
      ) : (
        <Suspense fallback={<div>Loading...</div>}>
          <section className="App">
            <header className="App-header">
              <Navbar />
              <div className="toasterBox">
                <div className="boxdw">
                  <Toaster
                    containerClassName="Toaster"
                    toastOptions={{
                      style: {
                        height: "auto",
                        width: "auto",
                        top: "120px",
                        position: "relative",
                      },
                    }}
                    position="top-right"
                    reverseOrder={true}
                  />
                </div>
              </div>
            </header>

            <Routes>
              <Route index path="/" element={<Home />} />
              <Route
                key={makeId(10)}
                path="/collection/:contract"
                element={<NewCollectionPage key={makeId(10)} />}
              />
              <Route
                key={makeId(10)}
                path="/explore/collections"
                element={<Collections key={makeId(10)} />}
              />
              <Route
                path="/collection/:contract/edit"
                element={<EditSection />}
              />
              <Route
                path="/account/:address"
                element={<Profile key={makeId(10)} />}
              />
              <Route
                key={makeId(10)}
                path="/collection/:contract/:tokenId"
                element={<NFTInfo />}
              />
              <Route path="/PageNotFound" element={<NotFound />} />
              <Route
                path="*"
                element={<Navigate to="/PageNotFound" replace />}
              />
            </Routes>

            <div className="lskao">
              <div className="ds-saew">
                <div className="olap-saol">
                  <Foot />
                </div>
              </div>
            </div>
            <Test1 />
          </section>
        </Suspense>
      )}
      <div
        className={`saSW_ERRFffD_GFTY ${showTxWindow ? "shuwR_RFdfff_YT" : ""}`}
      >
        <div onClick={() => handleUpdateState()} className="saW_ERTYhhgf_R">
          X
        </div>
        <TxWindow
          pending={pending}
          sucess={sucess}
          fail={fail}
          txHash={txHash}
        />
      </div>
    </React.Fragment>
  );
}

export default App;
