/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-useless-concat */
/* eslint-disable no-unused-vars */
import "../../Styles/Dropdown.css";
import "../../Styles/Navbar.css";
import React, { useState } from "react";
import logo from "../../Assets/Images/logo3.gif";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faBars, faWallet } from "@fortawesome/free-solid-svg-icons";
import { useEffect } from "react";
import { rpcapi } from "../Web3/RPCChain";
import { makeId } from "../../Utils/nonceGenerator";
import { toast } from "react-hot-toast";
import Web3 from "web3";
import { app } from "../../Database/Firebase/firebaseConfig";
import "../../Styles/searchBar.css";
import { checkAddress } from "../../Utils/utilWeb3";
import { isTrustWallet, web3Connector } from "../Web3/web3";
import { handleConnectAccount } from "../../Utils/sytemConnector";
import { Link, useNavigate } from "react-router-dom";
import UserSideBar from "../Pages/UserSideBar/UserSideBar";
import Blockies from "../Web3/Blockies/BlockiesGen";
import ConnectWindow from "../Pages/ConnectPage/connectWindow";
import SearchbarResult from "./SearchbarResult";

const db = app.firestore();

const Navbar = () => {
  //Chains Supported
  const evmChain = "0xaa36a7";
  const nameChain = "Sepolia";
  const wrongChain = "Wrong Chain";

  //Hooks
  const [isConnected, setIsConnected] = useState(false);
  const [userWallet, setUserWallet] = useState([]);
  const [chainSupported, setChainSupported] = useState(wrongChain);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [showMenu, setShowMenu] = useState(false);
  const [showUserLi, setShowUserLi] = useState(false);
  const [profileLink, setProfileLink] = useState();
  const [isName, setIsName] = useState(true);
  const [showSideBar, setShowSideBar] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, []);

  useEffect(() => {
    if (isLoading === true) {
      setSearchTerm("");
    }
  }, [isLoading]);

  const navigate = useNavigate();

  const toggleSideBar = () => {
    setShowSideBar(!showSideBar);
  };

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const toggleConnector = () => {
    setIsVisible(!isVisible);
  };

  useEffect(() => {
    //Checar si es la blockchain soportada

    async function checkBlockchain() {
      if (rpcapi) {
        const chainID = await rpcapi.request({
          method: "eth_chainId",
        });

        if (chainID === evmChain) {
          setChainSupported(nameChain);
        } else {
          toast.error(<span>Chain Not Supported</span>, {
            duration: 3000,
          });
        }
      }
    }
    checkBlockchain();
  }, [evmChain, nameChain]);

  // HandleConnect

  //Get Wallet
  async function getWallet(provider) {
    const web3 = await web3Connector(provider);
    const wallet = await web3.eth.getAccounts();
    if (wallet[0] === undefined) {
      // Notifications
      toast.error(<span>Error: You Must Install Or Unlock Your Wallet</span>, {
        duration: 3000,
      });
    } else {
      const lowerAddress = wallet[0].toLowerCase();
      setUserWallet(lowerAddress);
      return lowerAddress;
    }
  }

  useEffect(() => {
    const checkIfConnected = async () => {
      try {
        let wallet;
        if (typeof rpcapi !== undefined) {
          wallet = await getWallet(rpcapi);
        }
        const initRef = db.collection("users").doc(wallet);
        const initDoc = await initRef.get();
        // Condicional para la base de datos
        if (initDoc.exists) {
          const { isLogin } = initDoc.data();
          if (!isLogin) {
            setIsConnected(false);
          } else {
            setIsConnected(true);
            await handleConnectAccount(); // assuming that this function is defined elsewhere
          }
        }
      } catch (err) {
        ////console.log(err);
      }
    };
    checkIfConnected();
  }, []);

  //SearchBar Consult
  useEffect(() => {
    let unsubscribe;

    try {
      if (searchTerm !== "") {
        const isAddress =
          searchTerm.startsWith("0x") && searchTerm.length === 42;

        if (isAddress) {
          let collectionName;
          if (checkAddress(searchTerm)) {
            collectionName = "users";
            setIsName(false);
          } else {
            collectionName = "collection";
          }

          try {
            unsubscribe = db
              .collection(collectionName)
              .where("address", "==", searchTerm)
              .onSnapshot((snapshot) => {
                const results = [];
                snapshot.forEach((doc) => {
                  results.push({ id: doc.id, ...doc.data() });
                });
                setSearchResults(results);
              });
          } catch (err) {
            ////console.log(err);
          }
        } else if (!searchTerm.startsWith("0x")) {
          try {
            unsubscribe = db
              .collection("collection")
              .orderBy("name")
              .startAt(searchTerm.toUpperCase())
              .endAt(searchTerm + "\uf8ff")
              .limit(5)
              .onSnapshot((snapshot) => {
                const results = [];
                snapshot.forEach((doc) => {
                  results.push({ id: doc.id, ...doc.data() });
                });
                setSearchResults(results);
              });
          } catch (err) {
            ////console.log(err);
          }
        } else {
          try {
            unsubscribe = db
              .collection("collection")
              .orderBy("name")
              .where("name", ">=", searchTerm.charAt(0))
              .where("name", "<=", searchTerm.charAt(0) + "\uf8ff")
              .where("name", ">=", searchTerm)
              .where("name", "<=", searchTerm + "\uf8ff")
              .limit(5)
              .onSnapshot((snapshot) => {
                const results = [];
                snapshot.forEach((doc) => {
                  results.push({ id: doc.id, ...doc.data() });
                });
                setSearchResults(results);
              });
          } catch (err) {
            ////console.log(err);
          }
        }
      } else {
        setSearchResults([]);
      }
    } catch (err) {
      ////console.log(err);
    }

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [searchTerm]);

  const handleSelectResult = () => {
    setSearchTerm("");
  };

  const handleSearchTermChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    const web3 = new Web3();
    if (!web3.utils.isAddress(searchTerm)) {
      toast.error(<span>Invalid ETH Address</span>);
      return;
    }

    const isContract = await checkAddress(searchTerm);

    if (isContract !== "0x") {
      navigate(`/collection/${searchTerm}`);
    } else {
      navigate(`/account/${searchTerm}`);
    }

    setSearchTerm(""); // Reset the searchTerm to an empty string
  };

  return (
    <section className="navbar">
      {/* Same as */}
      <div className="box-md1">
        <div className="box-md2">
          <div className="box-md3">
            <div className="box-logo">
              <Link to="/" className="d1d2g-hhy">
                <img src={logo} alt="Home Logo" className="logo-nav" />
              </Link>
              <div className="hms-sas">
                <Link to="/" className="home-lsi">
                  <div className="slo-aswe">
                    TAGWEB3
                    <p className="skjaw">BETA</p>
                  </div>
                </Link>
              </div>
            </div>
            <form onSubmit={handleSearch} className="search-container">
              <input
                type="text"
                placeholder="Search by collection name or address"
                value={searchTerm}
                onChange={handleSearchTermChange}
              />
              <button type="submit">
                <FontAwesomeIcon className="iksu-alk" icon={faSearch} />
              </button>
              <div className="sika-wer">
                {searchResults.map((item) => (
                  <div key={makeId(10)} className="ikj-saaw">
                    <Link
                      onClick={handleSelectResult}
                      to={`/collection/${item.id}`}
                      className="iku-ujsw"
                    >
                      <div className="jusl-asd" key={item.id}>
                        <div className="oska-sww">
                          <SearchbarResult
                            cover={item.cover && item.cover}
                            name={item.name && item.name}
                          />
                        </div>
                        <p>{isName ? item.name : item.id}</p>
                      </div>
                    </Link>
                  </div>
                ))}
              </div>
            </form>
            <div className="navbar-md">
              <FontAwesomeIcon
                onClick={toggleMenu}
                className="icon-navbar"
                icon={faBars}
              />
              <ul className={`navbar-ul ${showMenu ? "show-menu" : ""}`}>
                <li className="navbar-li">
                  <Link to="/" className="a-md-q ijsw5">
                    Home
                  </Link>
                </li>
                <li className="navbar-li dropdown-li ijsw5">
                  <Link to={`/explore/collections`} className="as-dffg-hrr">
                    Collections
                  </Link>
                </li>
                <li className="navbar-li">
                  <span></span>
                </li>
              </ul>
            </div>
            <div className="s_Dse_tty">
              <div className="E_Rsaw">
                {isConnected ? (
                  <div className="wallet">
                    <div onClick={() => toggleSideBar()} className="ss-df4g">
                      <div className="sasl">
                        <Blockies seed={userWallet} size={15} scale={3} />
                      </div>
                    </div>

                    <ul
                      className={`mks-w navbar-uls ${
                        showUserLi ? "show-li" : ""
                      }`}
                    >
                      <li className="navbar-li2 li2">
                        {" "}
                        <Link
                          to={`/account/${profileLink}`}
                          className="uji-lki"
                        >
                          Profile
                        </Link>
                      </li>
                      <li className="navbar-li2 li2">Settings</li>
                      <li className="navbar-li2 li2">
                        <Link className="uji-lki">Sign Out</Link>
                      </li>
                    </ul>
                  </div>
                ) : (
                  <div className="buttonWallet">
                    <Link
                      onClick={() => toggleConnector()}
                      className="uji-lki ijsw5"
                    >
                      <div className="saslkjrsd_Rfd1">
                        <div className="sas_rtyty">
                          <FontAwesomeIcon icon={faWallet} />
                        </div>
                      </div>
                    </Link>
                  </div>
                )}
              </div>
            </div>
            <div
              className={`asjh_Sae visible ${
                isVisible ? "visible_Se" : "notVisible"
              }`}
            >
              <div className="sas_f1r_SD">
                <div className="AS_Ertt">
                  <ConnectWindow />
                </div>
              </div>
            </div>
            <div className="aaw-tyyu4">
              <div>
                <UserSideBar
                  showSideBar={showSideBar}
                  setShowSIdeBar={setShowSideBar}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Navbar;
