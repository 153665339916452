/* eslint-disable no-unused-vars */
import React from "react";
import NFTInfoCard from "./NFTInfoCard";

const NFTInfo = () => {
  return (
    <div className="a45w-a4s5">
      <div className="aka-d45">
        <div className="aa1s">
          <div className="yui8">
            <div className="a8w4">
              <NFTInfoCard />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NFTInfo;
