/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import { useState } from "react";
import {
  balanceOf,
  executeSellOrCancel,
  getDecimals,
  nullAddress,
} from "../../../../Utils/getExchangeData";
import { useParams } from "react-router-dom";
import { toast } from "react-hot-toast";
import { BigNumber, ethers } from "ethers";
import { rpcapi } from "../../../Web3/RPCChain";
import { web3Connector } from "../../../Web3/web3";
import {
  templateAuction,
  templateBid,
  templateOrder,
} from "../../../../Utils/templates";
import { app } from "../../../../Database/Firebase/firebaseConfig";

/*
 * New Update: Date Picker - New methods from smart contract
 * Date: 16 August, 2023
 */

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DropdownAddress from "../Utils/DropdownAddress/DropdownAddress";
import { erc20Tokens } from "../../../../Utils/AddressAllowed";

const db = app.firestore();

const CreateOrder = ({
  isActive,
  isOwner,
  isActiveBid,
  haveSaleActive,
  tokenType,
}) => {
  const [inputValuePrice, setInputValuePrice] = useState();
  const [inputValueEndTime, setInputValueEndTime] = useState();
  const { contract, tokenId } = useParams();
  const [showSaleButton, setShowSaleButton] = useState(false);
  const [wallet, setWallet] = useState();

  const formatedContractAddrr = contract.toLowerCase();

  /*
   * New Update: Date picker, Dropdown Address selector
   *
   * Date: 16 August, 2023
   */

  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedAddress, setSelectedAddress] = useState("");

  const handleAddressSelect = (address) => {
    setSelectedAddress(address);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const convertToTimestamp = () => {
    if (selectedDate) {
      return selectedDate.getTime() / 1000; // Convert to seconds
    }
    return null;
  };

  async function checkIfConnect() {
    try {
      const account = await getWallet(rpcapi);
      const dbRef = db.collection("users").doc(account);
      const dbDoc = await dbRef.get();
      if (dbDoc.exists) {
        const { isLogin } = dbDoc.data();
        return isLogin;
      }
    } catch (err) {
      ////console.log(err);
    }
  }

  //Create order function

  const handleExecuteSell = async () => {
    const isLogin = await checkIfConnect();
    if (isLogin) {
      try {
        await showSellModal(false);
        await handleDataTxWindow(true);
        await dataTxWindow(true, false, false);
        const web3 = await web3Connector(rpcapi);
        const action = 0;
        let tknType;
        let tknAmnt;

        if (tokenType === "ERC721") {
          tknType = 0;
          tknAmnt = 1;
        } else if (tokenType === "ERC1155") {
          tknType = 1;
        }
        let priceFormatted;

        if (selectedAddress !== "") {
          const decimals = await getDecimals(selectedAddress);
          priceFormatted = Number(inputValuePrice * 10 ** decimals).toString();
        } else {
          const newPrice = Number(inputValuePrice).toString();
          priceFormatted = web3.utils.toWei(newPrice, "ether");
        }

        const account = await getWallet(rpcapi);

        const timestamp = selectedDate.getTime() / 1000;
        ////console.log(timestamp);
        const execute = await executeSellOrCancel(
          "",
          tknType,
          action,
          contract,
          selectedAddress,
          account,
          nullAddress,
          tokenId,
          priceFormatted,
          timestamp,
          tknAmnt
        );
        await execute.wait();

        if (execute.hash) {
          await sendTx(execute.hash);
          await dataTxWindow(false, true, false);
          toast.success("Order created");
        }
      } catch (err) {
        ////console.log(err);
        await dataTxWindow(false, false, true);
      }
    } else {
      toast.error("Please connect your wallet");
    }
  };

  const showSellModal = async (boolean) => {
    try {
      const ref = db
        .collection("buttons")
        .doc(`showSaleButton:${formatedContractAddrr}:${tokenId}:${wallet}`);
      const doc = await ref.get();
      if (doc.exists) {
        const { showItem } = doc.data();
        if (showItem) {
          await ref.update({
            showItem: boolean,
          });
        }
      }
    } catch (err) {
      ////console.log(err);
    }
  };

  const handleShowSaleOption = async () => {
    try {
      setShowSaleButton((prevState) => !prevState);

      const firestoreRef = db
        .collection("buttons")
        .doc(`showSaleButton:${formatedContractAddrr}:${tokenId}:${wallet}`);
      const firestoreDoc = await firestoreRef.get();
      if (firestoreDoc.exists) {
        firestoreRef.update({ showItem: !isActive });
      } else {
        firestoreRef.set({ showItem: !isActive });
      }
    } catch (err) {
      ////console.log(err);
    }
  };

  async function getWallet(provider) {
    const web3 = await web3Connector(provider);
    const wallet = await web3.eth.getAccounts();
    if (wallet[0] === undefined) {
    } else {
      const lowerAddress = wallet[0].toLowerCase();
      return lowerAddress;
    }
  }

  useEffect(() => {
    async function getWallet(provider) {
      const web3 = await web3Connector(provider);
      const wallet = await web3.eth.getAccounts();
      if (wallet[0] === undefined) {
      } else {
        const lowerAddress = wallet[0].toLowerCase();
        setWallet(lowerAddress);
        return lowerAddress;
      }
    }

    getWallet(rpcapi);
  }, []);

  const handleChangePrice = (e) => {
    e.preventDefault();
    const value = parseFloat(e.target.value);
    setInputValuePrice(value);
  };

  /*
   * New Update : Window Confirmation
   *
   *
   */

  async function dataTxWindow(isPending, isSucess, isFail) {
    try {
      const account = await getWallet(rpcapi);
      const ref = db.collection("buttons").doc(`showTxWindow:${account}`);
      const doc = await ref.get();
      if (doc.exists) {
        await ref.update({
          isPending,
          isSucess,
          isFail,
        });
      } else {
        await ref.set({
          isPending,
          isSucess,
          isFail,
        });
      }
    } catch (err) {
      console.log(err);
    }
  }

  async function sendTx(txHash) {
    try {
      const account = await getWallet(rpcapi);
      const ref = db.collection("buttons").doc(`showTxWindow:${account}`);
      const doc = await ref.get();
      if (doc.exists) {
        await ref.update({
          txHash,
        });
      } else {
        await ref.set({
          txHash,
        });
      }
    } catch (err) {
      console.log(err);
    }
  }

  async function handleDataTxWindow(showItem) {
    try {
      const account = await getWallet(rpcapi);
      const ref = db.collection("buttons").doc(`showTxWindow:${account}`);
      const doc = await ref.get();
      if (doc.exists) {
        await ref.update({
          showItem,
        });
      } else {
        await ref.set({
          showItem,
        });
      }
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <React.Fragment>
      {isOwner ? (
        <React.Fragment>
          <div className="sask-e552d-saw">
            <div className="sas_Fdlld">
              <div className="sasaw_ERTTds">
                <div className="slW_ERtYF_r">Create Order</div>
                <div className="as_Erdas">
                  <div className="sA_WWeR_Fd">
                    <div className="saik_wer">
                      <DropdownAddress
                        addresses={erc20Tokens}
                        onSelect={handleAddressSelect}
                      />
                    </div>

                    <div className="salk_E85r">
                      Note: please leave in blank if you will not use Token
                      ERC20.
                    </div>

                    <div className="saik_wer">
                      <input
                        className="sh_Err-teew stye_sew1-sw"
                        placeholder="Price"
                        onChange={handleChangePrice}
                        value={inputValuePrice}
                        type="number"
                      />
                    </div>

                    <div className="saik_wer">
                      <div className="sa_WWtrtu_UTT">Select Date</div>
                      <React.Fragment>
                        <DatePicker
                          selected={selectedDate}
                          onChange={handleDateChange}
                          placeholderText="Select Date Of Expiration"
                          className="SWrT_DSe"
                        />
                      </React.Fragment>
                    </div>
                    <div className="salk_E85r"></div>

                    <div className="aw_swE_Sw">
                      <button
                        className="slA_we4W_k"
                        type="button"
                        onClick={() => handleExecuteSell()}
                      >
                        Create Order
                      </button>
                    </div>
                    <div className="aw_swE_Sw">
                      <button
                        type="button"
                        className="slA_we4W_k W_ert_w"
                        onClick={handleShowSaleOption}
                      >
                        Cancell
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <div className="sask-e552d-saw">
            <div className="sas_Fdlld">
              <div className="sasaw_ERTTds">
                <div className="slW_ERtYF_r">Make A Bid</div>
                <div className="as_Erdas">
                  <div className="saik_wer">
                    <DropdownAddress
                      addresses={erc20Tokens}
                      onSelect={handleAddressSelect}
                    />
                  </div>

                  <div className="salk_E85r">
                    Note: please leave in blank if you will not use Token ERC20.
                  </div>

                  <div className="saik_wer">
                    <input
                      className="sh_Err-teew stye_sew1-sw"
                      placeholder="Amount"
                      onChange={handleChangePrice}
                      value={inputValuePrice}
                      type="number"
                    />
                  </div>

                  <div className="aw_swE_Sw">
                    <button
                      className="slA_we4W_k"
                      type="button"
                      /* onClick={() => handleMakeABid()} */
                    >
                      Send Bid
                    </button>
                  </div>
                  <div className="aw_swE_Sw">
                    <button
                      type="button"
                      className="slA_we4W_k W_ert_w"
                      onClick={handleShowSaleOption}
                    >
                      Cancell
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default CreateOrder;
