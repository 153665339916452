/* eslint-disable no-unused-vars */
import React from "react";
import { app } from "../../../../Database/Firebase/firebaseConfig";
import { useState } from "react";
import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHand } from "@fortawesome/free-solid-svg-icons";
import { rpcapi } from "../../../Web3/RPCChain";
import { toast } from "react-hot-toast";
import { web3Connector } from "../../../Web3/web3";
import { ethers } from "ethers";
import { useParams } from "react-router-dom";
import {
  _auction,
  _order,
  abi721,
  getRealExchangeData,
} from "../../../../Utils/getExchangeData";

const db = app.firestore();

const NotInSalePage = ({ isOwner }) => {
  const [showSaleButton, setShowSaleButton] = useState(false);
  const [showAuctionButton, setShowAuctionButton] = useState(false);
  const [showBidButton, setShowBidButton] = useState(false);
  const { contract, tokenId } = useParams();
  const [wallet, setWallet] = useState();

  const addrFormated = contract.toLowerCase();

  const handleShowAuctionOption = async () => {
    try {
      setShowAuctionButton((prevState) => !prevState);
      const firestoreRef = db
        .collection("buttons")
        .doc(`showAuctionButton:${addrFormated}:${tokenId}:${wallet}`);
      const firestoreDoc = await firestoreRef.get();
      if (firestoreDoc.exists) {
        firestoreRef.update({ showItem: !showAuctionButton });
      } else {
        firestoreRef.set({ showItem: !showAuctionButton });
      }
    } catch (err) {
      ////console.log(err);
    }
  };

  const handleShowSaleOption = async () => {
    try {
      setShowSaleButton((prevState) => !prevState);
      const firestoreRef = db
        .collection("buttons")
        .doc(`showSaleButton:${addrFormated}:${tokenId}:${wallet}`);
      const firestoreDoc = await firestoreRef.get();
      if (firestoreDoc.exists) {
        firestoreRef.update({ showItem: !showSaleButton });
      } else {
        firestoreRef.set({ showItem: !showSaleButton });
      }
    } catch (err) {
      ////console.log(err);
    }
  };

  const handleShowBidOption = async () => {
    try {
      setShowBidButton((prevState) => !prevState);
      const firestoreRef = db.collection("buttons").doc("showBidButton");
      const firestoreDoc = await firestoreRef.get();
      if (firestoreDoc.exists) {
        firestoreRef.update({ showItem: !showBidButton });
      } else {
        firestoreRef.set({ showItem: !showBidButton });
      }
    } catch (err) {
      ////console.log(err);
    }
  };

  useEffect(() => {
    async function getWallet(provider) {
      const web3 = await web3Connector(provider);
      const wallet = await web3.eth.getAccounts();
      if (wallet[0] === undefined) {
      } else {
        const lowerAddress = wallet[0].toLowerCase();
        setWallet(lowerAddress);
        return lowerAddress;
      }
    }

    getWallet(rpcapi);
  }, []);

  useEffect(() => {
    const unsubscribe = db
      .collection("buttons")
      .doc(`showSaleButton:${addrFormated}:${tokenId}:${wallet}`)
      .onSnapshot((snapshot) => {
        if (snapshot.exists) {
          const { showItem } = snapshot.data();
          setShowSaleButton(showItem);
        }
      });

    return () => unsubscribe();
  }, [wallet, addrFormated, tokenId]);
  return (
    <React.Fragment>
      <div className="s_ER_TygF">
        <div className="a_ErTT_y">
          <div className="s_WER_tR">
            {isOwner ? (
              <React.Fragment>
                <div className="lask1s-fd44">
                  <div className="ss145f-ds14d">
                    <div className="s_ERRY_ytR_RhD">
                      <div className="sW_EEE_TBGG_BnH">Not For Sale</div>
                    </div>
                    <div className="sW_eRTT_yuutR">
                      <div className="sas44d-fgh">
                        <button
                          onClick={handleShowSaleOption}
                          type="button"
                          className="sal11ff-rrt4-54 ksA_wws2"
                        >
                          Put On Sale
                        </button>
                      </div>
                    </div>
                    <div className="sW_eRTT_yuutR">
                      <div className="sas44d-fgh">
                        <button
                          onClick={handleShowAuctionOption}
                          type="button"
                          className="sal11ff-rrt4-54 ksA_wws2"
                        >
                          Put On Auction
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <div className="lask1s-fd44">
                  <div className="ss145f-ds14d">
                    <div className="s_ERRY_ytR_RhD">
                      <div className="sW_EEE_TBGG_BnH">Not For Sale</div>
                    </div>
                    <div className="sW_eRTT_yuutR">
                      <div className="sas44d-fgh">
                        <button
                          onClick={handleShowSaleOption}
                          type="button"
                          className="sal11ff-rrt4-54 ksA_wws2"
                        >
                          <div className="sak-fg11j4">
                            <FontAwesomeIcon icon={faHand} />
                          </div>
                          Make A Bid
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default NotInSalePage;
