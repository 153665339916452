import { checkAuth } from "../lib/auth/auth.js";
import abiImporter from "../lib/web3/abiFetcher.js";
import { chainRpc } from "../lib/web3/connector.js";
import axios from "axios"; // Importing the axios library

async function checkApiKey(apiKey) {
  const userId = apiKey.slice(-15);
  const auth = await checkAuth(apiKey, userId);
  if (!auth) {
    throw new Error("Invalid API Key, please provide a valid API Key");
  }
}

class PolarysSDK {
  constructor(apiKey) {
    if (!apiKey) {
      throw new Error("apiKey is missing.");
    }

    try {
      checkApiKey(apiKey);
      this.apiKey = apiKey;
    } catch (error) {
      console.error(error); // Handle possible errors
      throw error; // Re-throw the error so that the caller can handle it
    }
  }

  async getTokenOwner(tokenAddress, chain, tokenId) {
    const web3 = chainRpc(chain);
    const contract = new web3.eth.Contract(abiImporter, tokenAddress);

    try {
      const owner = await contract.methods.ownerOf(tokenId).call();
      return owner;
    } catch (error) {
      console.error("Error getting token owner:", error);
      // handle error appropriately
    }
  }

  async getTotalSupply(tokenAddress, chain) {
    const web3 = chainRpc(chain);
    const contract = new web3.eth.Contract(abiImporter, tokenAddress);

    try {
      const totalSupply = await contract.methods.totalSupply().call();
      return totalSupply;
    } catch (err) {
      console.error("Error getting total supply", err);
    }
  }

  async getTokenURI(tokenAddress, chain, tokenId) {
    const web3 = chainRpc(chain);
    const contract = new web3.eth.Contract(abiImporter, tokenAddress);

    try {
      const tokenURI = await contract.methods.tokenURI(tokenId).call();
      return tokenURI;
    } catch (err) {
      console.error("Error getting tokenURI", err);
    }
  }

  async getMetadataInfo(ipfsLink) {
    const url = ipfsLink.replace("ipfs://", "https://ipfs.io/ipfs/");
    const response = await axios(url);

    const dataParse = response.data;
    return dataParse;
  }

  async getName(tokenAddress, chain) {
    const web3 = chainRpc(chain);
    const contract = new web3.eth.Contract(abiImporter, tokenAddress);
    try {
      const name = await contract.methods.name().call();
      return name;
    } catch (err) {
      console.error("Error getting name", err);
    }
  }

  async getSymbol(tokenAddress, chain) {
    const web3 = chainRpc(chain);
    const contract = new web3.eth.Contract(abiImporter, tokenAddress);
    try {
      const symbol = await contract.methods.symbol().call();
      return symbol;
    } catch (err) {
      console.error("Error getting symbol", err);
    }
  }

  async checkIfTokenExist(tokenAddress, chain, tokenId) {
    try {
      const URI = await this.getTokenOwner(tokenAddress, chain, tokenId);
      if (!URI) {
        return false;
      } else {
        return true;
      }
    } catch (err) {
      console.error(`Error checking if token: ${tokenId} exist`, err);
    }
  }

  async getContractOwner(tokenAddress, chain) {
    if (typeof tokenAddress === "object") {
      const options = tokenAddress;
      tokenAddress = options.tokenAddress;
      chain = options.chain;
    }

    const web3 = chainRpc(chain);
    const contract = new web3.eth.Contract(abiImporter, tokenAddress);
    try {
      const ownerContract = await contract.methods.owner().call();
      return ownerContract;
    } catch (err) {
      console.error("Error getting contract owner", err);
    }
  }

  async getNftsByContract(tokenAddress, chain, limit = 100, cursor = 0) {
    if (typeof tokenAddress === "object") {
      const options = tokenAddress;
      tokenAddress = options.tokenAddress;
      chain = options.chain;
      limit = options.limit;
      cursor = options.cursor;
    }
    const maxTokenId = await this.getTotalSupply(tokenAddress, chain);
    const contractName = await this.getName(tokenAddress, chain);
    const symbol = await this.getSymbol(tokenAddress, chain);
    const contractOwner = await this.getContractOwner(tokenAddress, chain);

    let start = Number(cursor);
    if (isNaN(start) || start < 0 || start >= maxTokenId) {
      start = 0;
    }

    const nfts = [];
    for (let i = start; i < maxTokenId && nfts.length < limit; i++) {
      const exists = await this.checkIfTokenExist(tokenAddress, chain, i);
      if (exists) {
        const tokenId = i;

        const owner = await this.getTokenOwner(tokenAddress, chain, tokenId);
        const uri = await this.getTokenURI(tokenAddress, chain, tokenId);
        const data = await this.getMetadataInfo(uri);
        let { image, name, description, attributes } = data;

        if (image.startsWith("ipfs://")) {
          image = `https://ipfs.io/ipfs/${image.slice(7)}`;
        }

        const nft = {
          name: contractName,
          image,
          tokenId,
          tokenURI: uri,
          owner,
          contract: tokenAddress,
          symbol,
          contractOwner,
          metadata: {
            _name: name,
            description,
            attributes,
          },
        };
        nfts.push(nft);
      }
    }

    const nextCursor = nfts.length > 0 ? String(start + nfts.length) : "";
    const prevCursor = start - limit > 0 ? String(start - limit) : "";

    return {
      data: nfts,
      nextCursor,
      prevCursor,
    };
  }

  async getTokenIdByOwner(tokenAddress, chain, walletAddress) {
    const totalSupply = await this.getTotalSupply(tokenAddress, chain);
    let start = 0;
    try {
      let tokens = [];
      for (let i = start; i <= totalSupply; i++) {
        const exist = await this.checkIfTokenExist(tokenAddress, chain, i);
        if (exist) {
          const owner = await this.getTokenOwner(tokenAddress, chain, i);
          if (owner === walletAddress) {
            tokens.push(i);
          }
        }
      }
      return tokens;
    } catch (err) {
      console.error(`Error getting tokens of address ${walletAddress}`, err);
    }
  }
}

export default PolarysSDK;
