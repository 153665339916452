import Moralis from "moralis/.";
import PolarysSDK from "./SDKPolarysNFTs/src";

const apiKey1 =
  "ghyb0bAh3B5Uyl0AgLT11JKgHVKT7CmmkfXiMwCc0UQoCPdXe3UcUcKuFOVskQNF";

const apiKey2 =
  "bhRpoD9unRtOMKHQ03MiHZnx6WoMbiV5Da33ejpCsPs7f4HOQhzNUd1zLp3TITeT";

async function getNfts(options) {
  const apiKey = "polar-663ade85-ad80-4c24-bb4b-5352c455bcf1-afJq1gMJNFS2uuU";
  const polarys = new PolarysSDK(apiKey);
  try {
    const nft = await polarys.getNftsByContract(options);
    return nft;
  } catch (err) {
    //console.log(err);
  }
}

async function getTotalNFTs(options) {
  let response;
  try {
    const apiKey = apiKey1;
    if (!Moralis.Core.isStarted) {
      Moralis.start({
        apiKey,
      });
    }
    response = await Moralis.EvmApi.nft.getContractNFTs(options);
    if (!response) {
      const apiKey = apiKey2;
      if (!Moralis.Core.isStarted) {
        Moralis.start({
          apiKey,
        });
      }
      response = await Moralis.EvmApi.nft.getContractNFTs(options);
    }

    return response.toJSON();
  } catch (err) {
    console.error(err);
  }
}



export { getNfts, getTotalNFTs };
