import React from "react";
import "../../../Styles/tablefooter.css";

const TableFooter = ({
  titleUl,
  listLi,
  listLi2,
  listLi3,
  listLi4,
  listLi5,
  link1,
  link2,
  link3,
  link4,
  link5,
}) => {
  return (
    <div className="iks-ai">
      <div className="ksu-saj">
        <div className="kaju-akju">
          <div className="alk-sawe">{titleUl}</div>
          <a
            rel="noreferrer noopener"
            target="_blank"
            href={link1}
            className="ksk-slai"
          >
            {listLi}
          </a>
          <a
            rel="noreferrer noopener"
            target="_blank"
            href={link2}
            className="ksk-slai"
          >
            {listLi2}
          </a>
          <a
            rel="noreferrer noopener"
            target="_blank"
            href={link3}
            className="ksk-slai"
          >
            {listLi3}
          </a>
          <a
            rel="noreferrer noopener"
            target="_blank"
            href={link4}
            className="ksk-slai"
          >
            {listLi4}
          </a>
          <a
            rel="noreferrer noopener"
            target="_blank"
            href={link5}
            className="ksk-slai"
          >
            {listLi5}
          </a>
        </div>
      </div>
    </div>
  );
};

export default TableFooter;
