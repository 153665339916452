/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  useTrendingCollections,
  TrendingCollectionsTimePeriod,
} from "@quicknode/icy-nft-hooks";
import React from "react";
import { useState } from "react";
import { makeId } from "../../../Utils/nonceGenerator";
import TableTrend from "./table";
import "../../../Styles/Grid.css";
import Moralis from "moralis/.";
import { EvmChain } from "@moralisweb3/common-evm-utils";
import { app } from "../../../Database/Firebase/firebaseConfig";
import "boxicons";
import { useEffect } from "react";
import { toast } from "react-hot-toast";

const db = app.firestore();

const GridHotColl = () => {
  //Hooks
  const [blueCheck, setBlueCheck] = useState([]);
  const [imageData, setImageData] = useState([]);
  const [cursor, setCursor] = useState("");
  const [timePeriod, setTimePeriod] = useState(
    TrendingCollectionsTimePeriod.TWELVE_HOURS
  );
  const [orderBy, setOrderBy] = useState("VOLUME");
  const [orderDir, setOrderDir] = useState("DESC");
  const { collections, pageInfo } = useTrendingCollections({
    orderBy: orderBy,
    orderDirection: orderDir,
    timePeriod: timePeriod,
    first: 10,
    after: cursor,
  });

  const onMainnet = () => {
    toast.error("This collections are in Mainnet");
  };

  const dataStr = JSON.stringify(collections);
  const dataPrs = Object.values(collections);

  const dataCov1 = dataPrs.map((data, index) => data.address);

  ////console.log();

  /*async function nftData() {
    try {
      if (!Moralis.Core.isStarted) {
        await Moralis.start({
          apiKey:
            "ghyb0bAh3B5Uyl0AgLT11JKgHVKT7CmmkfXiMwCc0UQoCPdXe3UcUcKuFOVskQNF",
        });
      }

      const data = [];
      const chain = EvmChain.ETHEREUM;
      const limit = 1;
      const imagePromises = [];

      for (let i = 0; i < dataCov.length; i++) {
        const address = dataCov[i];
        const resultData = await Moralis.EvmApi.nft.getContractNFTs({
          chain,
          address,
          limit,
        });

        const result = resultData.result;
        const resultStr = JSON.stringify(result);
        const resultPrs = JSON.parse(resultStr);

        const resultMap = resultPrs.map((metadata) => {
          metadata.metadata = JSON.stringify(metadata.metadata);
          const metaPrs = JSON.parse(metadata.metadata);
          data.push(metaPrs);
          return metaPrs;
        });

        // Add image promises to array
        const imagePromise = resultMap.map((meta) => {
          const { image } = meta;
          const response = image;

          const strD = { image: response };
          const strD2 = JSON.stringify(strD);
          const prsD = JSON.parse(strD2);

          imagePromises.push(prsD);

          return prsD;
        });
      }
      setImageData(imagePromises);
    } catch (err) {
      ////console.log(err);
    }
  }

  useEffect(() => {
    nftData();
  }, [dataCov]);*/

  return (
    <div className="sl-dw">
      <div className="saks-saw">
        <div className="ska-wwe">
          <h2 className="sla-eer">
            The hottest collections of the day in Mainnet
          </h2>
        </div>
        <div className="sjau-sawe">
          <div className="dse-ty">
            <div className="sa-ertt">
              <div className="slai-aslkf">
                <div className="salski-dsds fadeIn">
                  <div className="lol-asaee">
                    <div className="salsi-aswee">
                      <div className="skaik-loid ln-sas">
                        <p className="saki-sawee text-left mx-widthC">
                          Collection
                        </p>
                        <div className="askewe mn-widthC">
                          <p className="saki-sawee ika-aw">Floor</p>
                        </div>
                        <p className="saki-sawee text-right mn-widthC2">
                          Volume
                        </p>
                        <p className="saki-sawee text-right mn-widthC3">
                          Total Sales
                        </p>
                      </div>

                      {dataPrs &&
                        dataPrs.map((data, index) => (
                          <TableTrend
                            onMainnet={() => onMainnet()}
                            key={makeId(30)}
                            name={data.name}
                            volume={data.stats.volume}
                            floor={data.stats.floor}
                            totalSales={data.stats.totalSales}
                            address={data.address}
                            index={index}
                          />
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GridHotColl;
