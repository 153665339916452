/* eslint-disable no-unused-vars */
import { apiRarible } from "./keys";
import axios from "axios";
import blockies from "ethereum-blockies";
import { web3Connector } from "../Components/Web3/web3";
import { sepoliaAPI } from "./APIs/APIList";

const generateBlockies = (address) => {
  const icon = blockies.create({ seed: address.toLowerCase() });
  return icon.toDataURL();
};

const checkAddress = async (address) => {
  const web3 = await web3Connector(sepoliaAPI);
  try {
    if (address.length === 42) {
      const code = await web3.eth.getCode(address);
      return code;
    }
  } catch (err) {
    //console.log(err);
  }
};

const getBalanceByOwner = async (address, nextData) => {
  try {
    if (nextData === null) {
      const url = `${apiRarible}${address}`;
      const response = await axios(url);
      return response.data;
    } else {
      const url = `${apiRarible}${address}&continuation=${nextData}`;
      const response = await axios(url);
      return response.data;
    }
  } catch (err) {
    throw new Error(err);
  }
};

export { checkAddress, getBalanceByOwner, generateBlockies };
