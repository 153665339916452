import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";

const Grid = ({
  cover,
  name,
  contract,
  floor,
  volume,
  floorChange,
  items,
  owners,
  id,
}) => {
  const [isNegative, setIsNegative] = useState(false);

  useEffect(() => {
    async function checkPercentage() {
      if (floorChange < 0) {
        setIsNegative(true);
      } else {
        setIsNegative(false);
      }
    }

    checkPercentage();
  }, [floorChange]);
  return (
    <div className="AS_eerT_Y">
      <Link to={`/collection/${contract}`} className="aSdjj-eeer">
        <div className="sas_EEE_Ttg">
          <div className="asas_WWER_T id">
            <div className="sa_Errtty">{id}</div>
          </div>
          <div className="asas_WWER_T name">
            <div className="ass_Err">
              <div className="saS_EEE_Rrt_R">
                <img src={cover} alt={name} className="as_Errt_YRTre" />
              </div>
            </div>
            <div className="sa_Errtty">{name}</div>
          </div>
          <div className="asas_WWER_T floor">
            <div className="sa_Errtty">
              {Number(floor).toFixed(2)}
              <div className="saW_RrTty_U"> ETH</div>
            </div>
          </div>
          <div className="asas_WWER_T volumeChange">
            <div
              className={`sa_Errtty ${isNegative ? "negative" : "positive"}`}
            >
              {Number(floorChange).toFixed(2)}%
            </div>
          </div>
          <div className="asas_WWER_T volume">
            <div className="sa_Errtty">
              {Number(volume).toFixed(2)}
              <div className="saW_RrTty_U"> ETH</div>
            </div>
          </div>
          <div className="asas_WWER_T items">
            <div className="sa_Errtty">{items}</div>
          </div>
          <div className="asas_WWER_T owners">
            <div className="sa_Errtty">{owners}</div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default Grid;
