/* eslint-disable no-useless-concat */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import "../../../../Styles/DropdownPoint.css"; // import the CSS file for styling
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsis } from "@fortawesome/free-solid-svg-icons";
import Web3 from "web3";
import { rpcapi } from "../../../Web3/RPCChain";
import { useEffect } from "react";
import { generateToken } from "../../../../Utils/nonceTAGWEB3";
import { app } from "../../../../Database/Firebase/firebaseConfig";
import { toast } from "react-hot-toast";

const web3 = new Web3(rpcapi);
const db = app.firestore();

const DropdownButton = ({
  option1,
  option2,
  option3,
  option4,
  option5,
  option6,
  contract,
}) => {
  const [isOpen, setIsOpen] = useState(false); // initialize state for whether the dropdown is open or not
  const [wallet, setWallet] = useState("");

  async function reportCollection() {
    try {
      const date = new Date().toISOString();
      const token = generateToken(50);
      const reportRef = db.collection("reports").doc(token);
      const reportDoc = await reportRef.get();
      const account = await getWallet();
      const report = reportDoc.data();
      if (!reportDoc.exists) {
        try {
          await reportRef.set({
            reportedBy: account,
            reported: true,
            reportedAt: date,
            collectionReported: contract, // assuming 'contract' has been defined
            reportId: generateToken(50),
          });
          toast.success("You report have been sent");
        } catch (err) {
          ////console.log(err);
          toast.error(err);
        }
      }
    } catch (err) {
      ////console.log(err);
    }
  }
  //Get Wallet
  async function getWallet() {
    const wallet = await web3.eth.getAccounts();
    if (wallet[0] === undefined) {
    } else {
      const lowerAddress = wallet[0].toLowerCase();
      setWallet(lowerAddress);
      return lowerAddress;
    }
  }

  const storeOwner = async () => {
    const account = await getWallet();
    const ownerRef = db.collection("collection").doc(contract);
    const ownerDoc = await ownerRef.get();
    if (ownerDoc.exists) {
      const { owner, signature } = ownerDoc.data();
      if (!owner && !signature) {
        try {
          const sign = await claimCollection();
          if (sign) {
            ////console.log(sign);
            await ownerRef.update({
              owner: account,
              signature: sign,
            });
          }
        } catch (err) {
          ////console.log(err);
        }
      }
    }
  };

  const headMsg = "I claim ownership of the collection with address: ";
  const bodyMsg =
    "\n verifying that I am the legitimate owner of the smart contract. \n \n according to the terms and services of TAGWEB3. \n";

  async function claimCollection() {
    const account = await getWallet();
    const msgToSign =
      headMsg +
      `\n` +
      contract +
      `\n` +
      bodyMsg +
      `\n` +
      "owner: " +
      `\n` +
      account +
      `\n` +
      "Nonce: " +
      `\n` +
      generateToken(20);
    if (rpcapi) {
      try {
        const msg = `0x${Buffer.from(msgToSign, "utf8").toString("hex")}`;
        const signature = await rpcapi.request({
          method: "personal_sign",
          params: [msg, account],
        });
        return signature;
      } catch (err) {
        ////console.log(err);
      }
    }
  }

  useEffect(() => {
    getWallet();
  }, []);

  const handleToggleDropdown = () => {
    setIsOpen(!isOpen); // toggle the state of the dropdown
  };

  return (
    <div className="dropdown-container">
      <button className="dropdown-button" onClick={handleToggleDropdown}>
        <div className="lsaw-w">
          <FontAwesomeIcon icon={faEllipsis} />
        </div>
      </button>
      {isOpen && ( // show the dropdown menu only if isOpen is true
        <div className="dropdown-menu">
          {option1 && (
            <a className="kijs-wwwe" onClick={() => storeOwner()}>
              {option1}
            </a>
          )}
          {option2 && (
            <a className="kijs-wwwe" href={`/collection/${contract}/edit`}>
              {option2}
            </a>
          )}
          {option3 && (
            <a className="kijs-wwwe" onClick={() => reportCollection()}>
              {option3}
            </a>
          )}
          {option4 && <a href="#">{option4}</a>}
          {option5 && <a href="#">{option5}</a>}
          {option6 && <a href="#">{option6}</a>}
        </div>
      )}
    </div>
  );
};

export default DropdownButton;
