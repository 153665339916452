import { Network, Alchemy } from "alchemy-sdk";
import Moralis from "moralis/.";

const apiKey1 =
  "ghyb0bAh3B5Uyl0AgLT11JKgHVKT7CmmkfXiMwCc0UQoCPdXe3UcUcKuFOVskQNF";

const apiKey2 =
  "bhRpoD9unRtOMKHQ03MiHZnx6WoMbiV5Da33ejpCsPs7f4HOQhzNUd1zLp3TITeT";

const settings = {
  apikey: "rQ-oMVEjhkAJCDilJjBdHwur88ZTZo5v",
  network: Network.ETH_SEPOLIA,
};

const alchemy = new Alchemy(settings);

const getSepoliaNftByCollection = async (contractAddress, options) => {
  let arrayResponse = [];
  try {
    const collection = await alchemy.nft.getNftsForContract(
      contractAddress,
      options
    );
    arrayResponse.push(collection);
    return arrayResponse;
  } catch (err) {
    console.error(err);
  }
};

const getSepoliaNftsByOwners = async (ownerAddress, options) => {
  try {
    const nfts = await alchemy.nft.getNftsForOwner(ownerAddress, options);
    return nfts;
  } catch (err) {
    console.error(err);
  }
};

const getSepoliaNftsByItem = async (ownerAddress, tokenId) => {
  try {
    const nfts = await alchemy.nft.getNftMetadata(ownerAddress, tokenId);
    return nfts;
  } catch (err) {
    console.error(err);
  }
};

const getSepoliaOwnersByItem = async (address, chain, tokenId) => {
  let response;
  try {
    const apiKey = apiKey1;
    if (!Moralis.Core.isStarted) {
      Moralis.start({
        apiKey,
      });
    }
    response = await Moralis.EvmApi.nft.getNFTMetadata({
      address,
      chain,
      tokenId,
    });
    if (!response) {
      const apiKey = apiKey2;
      if (!Moralis.Core.isStarted) {
        Moralis.start({
          apiKey,
        });
      }
      response = await Moralis.EvmApi.nft.getNFTMetadata({
        address,
        chain,
        tokenId,
      });
    }

    return response.toJSON();
  } catch (err) {
    console.error(err);
  }
};

export {
  getSepoliaNftByCollection,
  getSepoliaNftsByOwners,
  getSepoliaNftsByItem,
  getSepoliaOwnersByItem,
};
