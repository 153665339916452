import { ethers } from "ethers";
import { nullHex } from "../getExchangeData";

const BigNumber = ethers.BigNumber;

async function templateOrderCreated(orderData) {
  let data;
  if (orderData.orderHash !== nullHex) {
    data = {
      orderHash: orderData.orderHash,
      asset: orderData.order.asset,
      action: orderData.order.action,
      collection: orderData.order.collection.toLowerCase(),
      paymentToken: orderData.order.paymentToken.toLowerCase(),
      seller: orderData.seller.toLowerCase(),
      buyer: orderData.order.buyer.toLowerCase(),
      tokenId: BigNumber.from(orderData.order.tokenId).toString(),
      price: BigNumber.from(orderData.order.price).toString(),
      expirationTime: BigNumber.from(orderData.order.expirationTime).toString(),
      tokenAmount: BigNumber.from(orderData.order.tokenAmount).toString(),
    };
  } else {
    data = false;
  }
  return data;
}

async function templateAuctionCreated(orderData) {
  let data;
  if (orderData.orderHash !== nullHex) {
    data = {
      orderHash: orderData.orderHash,
      asset: orderData.auction.asset,
      action: orderData.auction.action,
      collection: orderData.auction.collection.toLowerCase(),
      paymentToken: orderData.auction.paymentToken.toLowerCase(),
      seller: orderData.seller.toLowerCase(),
      highestBidder: orderData.auction.highestBidder.toLowerCase(),
      tokenId: BigNumber.from(orderData.auction.tokenId).toString(),
      reservedPrice: BigNumber.from(orderData.auction.reservedPrice).toString(),
      highestBid: BigNumber.from(orderData.auction.highestBid).toString(),
      startTime: BigNumber.from(orderData.auction.startTime).toString(),
      expirationTime: BigNumber.from(
        orderData.auction.expirationTime
      ).toString(),
      tokenAmount: BigNumber.from(orderData.auction.tokenAmount).toString(),
      price: BigNumber.from(orderData.auction.reservedPrice).toString(),
    };
  } else {
    data = false;
  }
  return data;
}

async function templateAuctionBids(orderData) {
  let data;
  if (orderData.orderHash !== nullHex) {
    data = {
      orderHash: orderData.orderHash,
      bidder: orderData.bidder.toLowerCase(),
      collection: orderData.collection.toLowerCase(),
      seller: orderData.seller.toLowerCase(),
      paymentToken: orderData.paymentToken.toLowerCase(),
      highestBid: BigNumber.from(orderData.highestBid).toString(),
      tokenId: BigNumber.from(orderData.tokenId).toString(),
    };
  } else {
    data = false;
  }
  return data;
}

async function templateOrderCanceled(orderData) {
  let data;
  if (orderData.orderHash !== nullHex) {
    data = {
      orderHash: orderData.orderHash,
      tokenId: BigNumber.from(orderData.tokenId).toString(),
    };
  } else {
    data = false;
  }
  return data;
}

async function templateAuctionCanceled(orderData) {
  let data;
  if (orderData.orderHash !== nullHex) {
    data = {
      orderHash: orderData.orderHash,
      tokenId: BigNumber.from(orderData.tokenId).toString(),
    };
  } else {
    data = false;
  }
  return data;
}

async function templateOrderFilled(orderData) {
  let data;
  if (orderData.orderHash !== nullHex) {
    data = {
      orderHash: orderData.orderHash,
      seller: orderData.seller.toLowerCase(),
      buyer: orderData.buyer.toLowerCase(),
      collection: orderData.collection.toLowerCase(),
      tokenId: BigNumber.from(orderData.tokenId).toString(),
      paymentToken: orderData.paymentToken.toLowerCase(),
      price: BigNumber.from(orderData.price).toString(),
    };
  } else {
    data = false;
  }
  return data;
}

async function templateTransfer(dataInfo) {
  let data;
  if (dataInfo.orderHash !== nullHex) {
    data = {
      from: dataInfo.from.toLowerCase(),
      to: dataInfo.to.toLowerCase(),
      tokenId: BigNumber.from(dataInfo.tokenId).toString(),
    };
  } else {
    data = false;
  }
  return data;
}

export {
  templateOrderCreated,
  templateOrderCanceled,
  templateOrderFilled,
  templateTransfer,
  templateAuctionCreated,
  templateAuctionCanceled,
  templateAuctionBids
};
