/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import "boxicons";
import "./NftCard.css";
import "../../../../Styles/CollNfts.css";
import { getLatestSale } from "../../../../Utils/getLastSale";
import logo from "../../../../Assets/Images/jhj.png";
import { rpcapi } from "../../../Web3/RPCChain";
import { getCurrentOwner } from "../../../../Utils/getNFTInfo";
import { getItem } from "../../../../Utils/getTokenById";
import loadGif from "../../../../Assets/Images/gif_NFTCard.gif";
import { web3Connector } from "../../../Web3/web3";
import {
  executeBuy,
  executeSellOrCancel,
  getOrders,
  nullAddress,
} from "../../../../Utils/getExchangeData";
import { Link } from "react-router-dom";
import {
  templateOrderCanceled,
  templateOrderCreated,
  templateOrderFilled,
} from "../../../../Utils/Templates/templates";
import { toast } from "react-hot-toast";
import { erc20Tokens } from "../../../../Utils/AddressAllowed";
import { app } from "../../../../Database/Firebase/firebaseConfig";

const db = app.firestore();

const NFTCard = ({ contract, tokenId, keyPass, image, selected, owner }) => {
  //Hooks
  const [amount, setAmount] = useState(0);
  const [price, setPrice] = useState();
  const [isSetForSale, setIsSetForSale] = useState(false);
  const [isLastSales, setIsLastSales] = useState(false);
  const [nftImage, setnftImage] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [exchangePrice, setExchangePrice] = useState();

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  useEffect(() => {
    const checkImage = async () => {
      if (image === null) {
        setnftImage(image);
      } else if (image === undefined) {
        setnftImage(image);
      } else {
        const newImage = image.replace("ipfs://", "https://ipfs.io/ipfs/");
        setnftImage(newImage);
      }
    };
    checkImage();
  }, [image]);

  /*
   * New Updates - changing the new smart contract abi, will needed change all process of get all events from smart contract
   * the update will make it first in test (Sepolia) and later will be realeased in Mainnet
   *
   * This update will have better optimization
   * Date: August 16 - 2023
   */

  const [orderData, setOrderData] = useState([]);
  const [activeSales, setActiveSales] = useState();
  const [importantSale, setImportantSale] = useState();
  const [importantPrice, setImportantPrice] = useState();
  const [isOwner, setIsOwner] = useState();
  const [paymentToken, setPaymentToken] = useState();
  const [txHash, setTxHash] = useState("");
  const [showItem, setShowItem] = useState(false);

  //Get Orders Executed:
  useEffect(() => {
    async function orders() {
      try {
        const data = await getOrders(tokenId);
        let pushOrder = [];

        const result = await Promise.all(
          data.map(async (item) => {
            let order;
            let activeSale;
            let blockNumber;
            if (item.event === "OrderCreated") {
              order = await templateOrderCreated(item.args);
              activeSale = true;
              blockNumber = item.blockNumber;
            } else if (item.event === "OrderCanceled") {
              activeSale = false;
              order = await templateOrderCanceled(item.args);
              blockNumber = item.blockNumber;
            } else if (item.event === "OrderFilled") {
              activeSale = false;
              order = await templateOrderFilled(item.args);
              blockNumber = item.blockNumber;
            }

            return {
              ...order,
              activeSale,
            };
          })
        );

        pushOrder.push(result[0]);

        const web3 = await web3Connector(rpcapi);
        const iptPrice = web3.utils.fromWei(result[0].price, "ether");

        setPaymentToken(result[0].paymentToken);

        setActiveSales(result[0].activeSale);
        setOrderData(pushOrder);
        setImportantPrice(iptPrice);

        ////console.log(result);
      } catch (err) {
        ////console.log(err);
      }
    }

    orders();
  }, [tokenId]);

  useEffect(() => {
    if (activeSales) {
      setImportantSale(true);
    } else {
      setImportantSale(false);
    }
  }, [isSetForSale, activeSales]);

  //Get Wallet
  async function getWallet(provider) {
    const web3 = await web3Connector(provider);
    const wallet = await web3.eth.getAccounts();
    if (wallet[0] === undefined) {
    } else {
      const lowerAddress = wallet[0].toLowerCase();
      return lowerAddress;
    }
  }

  /*
   *
   * Quickly buy fast button
   *
   */

  const handleBuyButton = async () => {
    try {
      await handleDataTxWindow(true);
      await dataTxWindow(true, false, false);

      const data = orderData[0];

      ////console.log(data);
      const account = await getWallet(rpcapi);
      const execute = await executeBuy(
        data.orderHash,
        data.asset,
        1,
        contract,
        data.paymentToken,
        data.seller,
        account,
        tokenId,
        data.price,
        data.expirationTime,
        data.tokenAmount
      );

      await execute.wait();

      if (execute.hash) {
        toast.success("Order Executed And Filled");
        await dataTxWindow(false, true, false);
      }
    } catch (err) {
      ////console.log(err.message);
      await dataTxWindow(false, false, true);
    }
  };

  useEffect(() => {
    async function checkOwner() {
      try {
        const account = await getWallet(rpcapi);
        const addressFormatted = await getCurrentOwner(
          contract,
          rpcapi,
          tokenId
        );
        ////console.log(addressFormatted);
        if (addressFormatted === account) {
          setIsOwner(true);
        } else {
          setIsOwner(false);
        }
      } catch (err) {
        ////console.log(err.message);
      }
    }
    checkOwner();
  }, [contract, owner, tokenId]);

  /* *
   *
   * Fast cancel button
   *
   */

  const handleExecuteCancel = async () => {
    try {
      await handleDataTxWindow(true);
      await dataTxWindow(true, false, false);
      const action = 5;
      const data = orderData[0];

      ////console.log(data);

      const execute = await executeSellOrCancel(
        data.orderHash,
        data.asset,
        action,
        contract,
        data.paymentToken,
        data.seller,
        data.buyer,
        tokenId,
        data.price,
        data.expirationTime,
        data.tokenAmount
      );

      await execute.wait();

      if (execute.hash) {
        await sendTx(execute.hash);
        await dataTxWindow(false, true, false);
        toast.success("Order canceled");
      }
    } catch (err) {
      ////console.log(err);
      await dataTxWindow(false, false, true);
    }
  };

  function isWithin15Days(date) {
    const today = new Date();
    const maxDate = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() - 15
    );
    const dateToCheck = new Date(date);
    return dateToCheck >= maxDate;
  }

  function isWithinLastMonth(date) {
    const oneMonthAgo = new Date();
    oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
    const dateToCheck = new Date(date);
    return (
      dateToCheck >= oneMonthAgo &&
      dateToCheck.getMonth() === oneMonthAgo.getMonth()
    );
  }

  useEffect(() => {
    async function dataPrice() {
      try {
        if (!activeSales) {
          const data = await getItem(contract, tokenId);
          if (data) {
            const currentPrice = data.bestSellOrder;
            if (currentPrice) {
              const priceInNumber = Number(currentPrice.makePrice).toFixed(2);
              setIsSetForSale(true);
              setPrice(priceInNumber);
            } else {
              setIsSetForSale(false);
            }
          }
        }
      } catch (err) {
        console.error(err);
      }
    }

    dataPrice();
  }, [activeSales, contract, tokenId]);

  useEffect(() => {
    async function dataSales() {
      const data = await getLatestSale(contract, tokenId);
      if (data) {
        setAmount(Number(data).toFixed(2));
        setIsLastSales(true);
      } else {
        setAmount(0);
        setIsLastSales(false);
      }
    }
    dataSales();
  }, [contract, tokenId]);

  /*
   * New Update
   *
   *
   */

  const [showBuyButton, setShowBuyButton] = useState(false);
  const [tokenLogo, setTokenLogo] = useState();

  const handleShowBuyButton = () => {
    setShowBuyButton(true);
  };

  const handleHideBuyButton = () => {
    setShowBuyButton(false);
  };

  useEffect(() => {
    for (let i = 0; i < erc20Tokens.length; i++) {
      if (paymentToken === erc20Tokens[i].address) {
        setTokenLogo(erc20Tokens[i].logo);
      }
    }
  }, [orderData, paymentToken]);

  async function dataTxWindow(isPending, isSucess, isFail) {
    try {
      const account = await getWallet(rpcapi);
      const ref = db.collection("buttons").doc(`showTxWindow:${account}`);
      const doc = await ref.get();
      if (doc.exists) {
        await ref.update({
          isPending,
          isSucess,
          isFail,
        });
      } else {
        await ref.set({
          isPending,
          isSucess,
          isFail,
        });
      }
    } catch (err) {
      console.log(err);
    }
  }

  async function sendTx(txHash) {
    try {
      const account = await getWallet(rpcapi);
      const ref = db.collection("buttons").doc(`showTxWindow:${account}`);
      const doc = await ref.get();
      if (doc.exists) {
        await ref.update({
          txHash,
        });
      } else {
        await ref.set({
          txHash,
        });
      }
    } catch (err) {
      console.log(err);
    }
  }

  async function handleDataTxWindow(showItem) {
    try {
      const account = await getWallet(rpcapi);
      const ref = db.collection("buttons").doc(`showTxWindow:${account}`);
      const doc = await ref.get();
      if (doc.exists) {
        await ref.update({
          showItem,
        });
      } else {
        await ref.set({
          showItem,
        });
      }
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <React.Fragment>
      <div
        className="saW_RYy_UGF"
        onMouseEnter={() => handleShowBuyButton()}
        onMouseLeave={() => handleHideBuyButton()}
      >
        <div key={keyPass} className="si-ew">
          <div className="aso-aa">
            <div className="sal-wwe collection-page">
              <div className="as-dder collection-buddy">
                <Link
                  to={`/collection/${contract}/${tokenId}`}
                  className="kjs-sjue"
                >
                  <div className="awe-trty">
                    {isLoading ? (
                      <img
                        src={loadGif}
                        alt={tokenId}
                        className="skl loadGif"
                      />
                    ) : (
                      <img
                        src={nftImage}
                        alt={tokenId}
                        className="skl iksa_WE"
                      />
                    )}
                  </div>
                </Link>
                <Link
                  to={`/collection/${contract}/${tokenId}`}
                  className="kjs-sjue"
                >
                  <div className="S_Wrr_TY_de_R">
                    <div className="ass-aawwe">
                      <div className="ool-ppo so-ski">
                        TOKEN ID:
                        <div className="ool-ppo ool-siu bold-sl">
                          {tokenId}{" "}
                        </div>
                      </div>
                    </div>
                    <div className="iksu-kik">
                      <div className="iaku sjau">
                        {importantSale ? (
                          <React.Fragment>
                            {paymentToken !== nullAddress ? (
                              <img src={tokenLogo} alt="ETH" className="sas" />
                            ) : (
                              <img src={logo} alt="ETH" className="sas" />
                            )}
                            {exchangePrice
                              ? exchangePrice
                              : Number(importantPrice)}
                          </React.Fragment>
                        ) : (
                          <React.Fragment>Not For Sale</React.Fragment>
                        )}
                      </div>
                    </div>
                    <div className="aS_Wee_RTgfgfd_D">
                      <div className="sas_WWertt">Last Sale: N/a</div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>

            <div
              className={`s_WrrDS_eRT_D ${showBuyButton && "showBuyButton_S"}`}
            >
              {isOwner ? (
                activeSales ? (
                  <button
                    onClick={() => handleExecuteCancel()}
                    className="as_ERTffD_s"
                  >
                    CANCEL
                  </button>
                ) : (
                  <Link to={`/collection/${contract}/${tokenId}`}>
                    <button className="as_ERTffD_s">SELL</button>
                  </Link>
                )
              ) : (
                activeSales && (
                  <button
                    onClick={() => handleBuyButton()}
                    className="as_ERTffD_s"
                  >
                    BUY
                  </button>
                )
              )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default React.memo(NFTCard);
