/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React from "react";
import "./Collection.css";
import { useState } from "react";
import { getAllNfts, getAllNftsByVolume } from "../../../Utils/getAllNfts";
import { useEffect } from "react";
import Grid from "./Grid";
import { makeId } from "../../../Utils/nonceGenerator";
import RouterData from "./RouterData";

const Collections = () => {
  const [continuationString, setContinuationString] = useState("");
  const [prevContinuation, setPrevContinuation] = useState("");
  const [nfts, setNfts] = useState([]);

  const getDataColl = async (continuation) => {
    const data = await getAllNftsByVolume(continuation);
    const dataCursor = data.continuation;
    const result = data.result.map((item) => ({
      ...item,
      contract: item.id.replace(/^ETHEREUM:/, ""),
    }));

    if (dataCursor) {
      setContinuationString(dataCursor);
    }
    if (result) {
      return result;
    }
  };

  /*Function to get the nfts using cursor */
  const loadNFTs = async (cursor) => {
    const data = await getDataColl(cursor);
    return data;
  };

  /*  Handle to charge more NFTs */
  const handleLoadMore = async () => {
    if (continuationString !== prevContinuation) {
      const data = await loadNFTs(continuationString);
      setPrevContinuation(continuationString); // Move this line here
      setNfts((prevNfts) => [...prevNfts, ...data]);
    }
  };

  /* Hook to fetch all data */
  useEffect(() => {
    const fetchData = async () => {
      const data = await loadNFTs(null);
      setPrevContinuation(null);
      setNfts(data);
    };
    fetchData();
  }, []);

  /*Handle to scroll and has more items */
  const handleScroll = () => {
    const scrollTop =
      (document.documentElement && document.documentElement.scrollTop) ||
      document.body.scrollTop;
    const scrollHeight =
      (document.documentElement && document.documentElement.scrollHeight) ||
      document.body.scrollHeight;
    const clientHeight =
      document.documentElement.clientHeight || window.innerHeight;
    const scrolledToBottom =
      Math.ceil(scrollTop + clientHeight) >= scrollHeight;

    if (scrolledToBottom) {
      handleLoadMore();
    }
  };

  /* Hooks and sort settings */
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [nfts]);

  ////console.log(nfts);

  return (
    <div className="aslk_Eer">
      <div className="saslkE_Rrtty">
        <div className="tittleA_swrrt">Collections</div>
        <div className="as_ErTTY_yuyyt">
          <div className="as_Ert_YTYffd_Er">
            <div className="aswWw_ERtT_YY number">#</div>
            <div className="aswWw_ERtT_YY name">Collection</div>
            <div className="aswWw_ERtT_YY floor">Floor Price</div>
            <div className="aswWw_ERtT_YY change">Floor Change</div>
            <div className="aswWw_ERtT_YY volume">Volume</div>
            <div className="aswWw_ERtT_YY item">Items</div>
            <div className="aswWw_ERtT_YY owner">Owners</div>
          </div>
          <div className="sas_ERrtty_TRew">
            <div className="YYT_rerTtrT_">
              {nfts.map((data, index) => (
                <React.Fragment key={makeId(10)}>
                  <RouterData
                    contract={data.contract}
                    id={index}
                    floor={data.floorPrice.value}
                    volume={data.volumeNative.value}
                    floorChange={data.floorPrice.changePercent}
                    items={data.totalItemSupply}
                    owners={data.ownersCount}
                  />
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Collections;
