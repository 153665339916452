/* eslint-disable no-unused-vars */
import { IcyProvider } from "@quicknode/icy-nft-hooks";
import React from "react";
import "../../Styles/Home.css";
import Data from "./Sections/Data";
import Data2 from "./Sections/Data2";
import Foot from "./Sections/Foot";
import GridHotColl from "./Sections/GridHotColl";
import TextBelt from "./Collection/Utils/TextBelt";

const Home = () => {
  return (
    <section className="home">
      <div className="asfdf-44_41gg">
        <TextBelt
          text={"THIS IS JUST A TEST ON SEPOLIA NETWORK, NO REAL LIFE DATA"}
        />
      </div>
      <div className="home-md">
        <div className="home-md2">
          <div className="home-md3">
            <div className="home-md4">
              <div className="sasw-weeer">
                <div className="sli-asww">
                  <div className="TopNFTCard">
                    <Data />
                  </div>
                </div>
              </div>
              <div className="asli-awet">
                <div className="ki-wwer">
                  <div className="aswe-ett">
                    <Data2 />
                  </div>
                </div>
              </div>
              <div className="sawl-wqwr">
                <div className="sasww">
                  <IcyProvider apiKey="5b96005d1adb4777b57bedad9d0a89de">
                    <GridHotColl />
                  </IcyProvider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Home;
