import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShareNodes } from "@fortawesome/free-solid-svg-icons";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import "../../../../Styles/ShareButton.css";

const ShareButton = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [copied, setCopied] = useState(false);

  const handleShareButtonClick = () => {
    setIsOpen(!isOpen);
  };

  const handleCopyClick = () => {
    const url = window.location.href;
    navigator.clipboard.writeText(url);
    setCopied(true);
  };

  return (
    <div className="dropdown-container">
      <button className="dropdown-button" onClick={handleShareButtonClick}>
        <FontAwesomeIcon icon={faShareNodes} />
      </button>
      {isOpen && (
        <div className="dropdown-menu1">
          <div className="dropdown-item">
            <span className="dropdown-url">
              {window.location.href.substring(0, 8)}...
              {window.location.href.substring(window.location.href.length - 8)}
            </span>
            <button className="copy-button" onClick={handleCopyClick}>
              <FontAwesomeIcon icon={faCopy} />
              {copied ? "Copied!" : "Copy"}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ShareButton;
