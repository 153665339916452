import { ethers } from "ethers";
import { nullHex } from "./getExchangeData";

const BigNumber = ethers.BigNumber;

async function templateOrder(orderData) {
  let data;
  if (orderData.orderId !== nullHex) {
    data = {
      buyer: orderData.buyer.toLowerCase(),
      nonce: BigNumber.from(orderData.nonce).toString(),
      orderId: orderData.orderId,
      seller: orderData.seller.toLowerCase(),
      price: BigNumber.from(orderData.price).toString(),
      tokenId: BigNumber.from(orderData.tokenId).toString(),
      orderEndTime: BigNumber.from(orderData.orderEndTime).toString(),
      status: orderData.status,
      tokenAddress: orderData.tokenAddress.toLowerCase(),
      tokenCurrency: orderData.tokenCurrency.toLowerCase(),
      nonceKey: orderData.nonceKey,
      id: orderData.orderId,
    };
  } else {
    data = false;
  }
  return data;
}

async function templateAuction(auctionData) {
  let data;
  if (auctionData.auctionId !== nullHex) {
    data = {
      highestBidder: auctionData.highestBidder.toLowerCase(),
      nonce: BigNumber.from(auctionData.nonce).toString(),
      highestBid: BigNumber.from(auctionData.highestBid).toString(),
      auctionId: auctionData.auctionId,
      seller: auctionData.seller.toLowerCase(),
      reservedPrice: BigNumber.from(auctionData.reservedPrice).toString(),
      tokenId: BigNumber.from(auctionData.tokenId).toString(),
      endTime: BigNumber.from(auctionData.endTime).toString(),
      status: auctionData.status,
      tokenAddress: auctionData.tokenAddress.toLowerCase(),
      tokenCurrency: auctionData.tokenCurrency.toLowerCase(),
      nonceKey: auctionData.nonceKey,
      id: auctionData.auctionId,
      price: BigNumber.from(auctionData.reservedPrice).toString(),
    };
  } else {
    data = false;
  }

  return data;
}

async function templateBid(bidData) {
  let data;
  if (bidData.bidId !== nullHex) {
    data = {
      taker: bidData.taker.toLowerCase(),
      nonce: BigNumber.from(bidData.nonce._hex).toString(),
      bidId: bidData.bidId,
      bidder: bidData.bidder.toLowerCase(),
      amount: BigNumber.from(bidData.amount).toString(),
      tokenId: BigNumber.from(bidData.tokenId._hex).toString(),
      endTime: 0,
      status: BigNumber.from(bidData.status).toString(),
      tokenAddress: bidData.tokenAddress.toLowerCase(),
      tokenCurrency: bidData.tokenCurrency.toLowerCase(),
      nonceKey: bidData.nonceKey,
      id: bidData.bidId,
      price: BigNumber.from(bidData.amount).toString(),
    };
  } else {
    data = false;
  }

  return data;
}

export { templateOrder, templateAuction, templateBid };
