/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import PolarysSDK from "../Utils/SDKPolarysNFTs/src";
import EvmChain from "../Utils/SDKPolarysNFTs/lib/EvmChain";
import { useEffect } from "react";
import {
  getSepoliaNftByCollection,
  getSepoliaNftsByOwners,
} from "../Utils/sepolia/sepolia";

const test = "0x109031B19E33fe7A4Cc66796c9492A3beC64467A";
const test2 = "0x3aa5Ed3428795320cB2F8aD58532C671De5AaA29";
const options = {
  limit: 50,
  startToken: 0,
  withMetadata: true,
  pageKey: "0x0000000000000000000000000000000000000000000000000000000000000065",
};

const pageKey =
  "MHgxMDkwMzFiMTllMzNmZTdhNGNjNjY3OTZjOTQ5MmEzYmVjNjQ0NjdhOjB4MDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDA3NDpmYWxzZQ==";

const Test1 = () => {
  async function getNfts() {
    const collection = await getSepoliaNftByCollection(test, options);
    const nfts = await getSepoliaNftsByOwners(test2, pageKey);
  }
  return (
    <div className="1j5-dse">
      <div className="skaiw"></div>
    </div>
  );
};

export default Test1;
