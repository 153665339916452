/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import "./UserSideBar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faAngleRight,
  faGear,
  faUser,
  faMessage,
} from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import {
  getBalance,
  getBalanceInUSD,
  getBalanceInWeth,
  getCurrentEthPrice,
  getCurrentWethPrice,
} from "../../../Utils/accountStats";
import { rpcapi } from "../../Web3/RPCChain";
import { web3Connector } from "../../Web3/web3";
import { app } from "../../../Database/Firebase/firebaseConfig";
import { handleDisconnectAccount } from "../../../Utils/sytemConnector";
import { Link } from "react-router-dom";
import Blockies from "../../Web3/Blockies/BlockiesGen";
import ethereum from "../../../Assets/Images/Ethereum Icon.svg";
import weth from "../../../Assets/Images/Bff.svg";

const UserSideBar = ({ showSideBar, setShowSIdeBar }) => {
  const [balanceUSD, setBalanceUSD] = useState(0);
  const [balance, setBalance] = useState(0);
  const [address, setAddress] = useState("");
  const [username, setUsernameData] = useState("");
  const [ethPrice, setEthPrice] = useState(0);
  const [wethPrice, setWethPrice] = useState(0);
  const [balanceInWeth, setBalanceWeth] = useState(0);
  const [balanceInWethUSD, setBalanceWethUSD] = useState(0);

  const db = app.firestore();

  async function checkUser() {
    try {
      const wallet = await getWallet(rpcapi);
      const checkRef = db.collection("users").doc(wallet);
      // Fix typo in unsubscribe variable name
      const unsubscribe = checkRef.onSnapshot((data) => {
        if (data.exists) {
          const { username } = data.data();
          if (username) {
            setUsernameData(username);
          } else {
            setUsernameData(
              wallet.substring(0, 8) +
                "..." +
                wallet.substring(wallet.length - 8)
            );
          }
        }
      });
      return unsubscribe;
    } catch (err) {
      //console.log(err);
    }
  }

  useEffect(() => {
    async function fetchData() {
      await checkUser();
    }
    // Call checkUser only once when the component mounts
    fetchData();
  }, []);

  async function getWallet(provider) {
    const web3 = await web3Connector(provider);
    const wallet = await web3.eth.getAccounts();
    if (wallet[0] === undefined) {
    } else {
      const lowerAddress = wallet[0].toLowerCase();
      setAddress(lowerAddress);
      return lowerAddress;
    }
  }



  useEffect(() => {
    async function getEthPrice() {
      const wallet = await getWallet(rpcapi);
      try {
        const price = await getCurrentEthPrice();
        const weth = await getCurrentWethPrice();
        const balanceInWeth = await getBalanceInWeth(wallet);
        const balanceWethUSD = balanceInWeth * price;
        setBalanceWethUSD(balanceWethUSD);
        setBalanceWeth(balanceInWeth);
        setEthPrice(price);
        setWethPrice(weth);
      } catch (err) {
        //console.log(err);
      }
    }
    getEthPrice();
  }, [rpcapi]);

  useEffect(() => {
    getWallet(rpcapi);
  }, []);

  useEffect(() => {
    const getBalanceAccount = async () => {
      const balanceAccountInUSD = await getBalanceInUSD(address);
      const balanceAccount = await getBalance();
      setBalance(balanceAccount);
      setBalanceUSD(balanceAccountInUSD);
    };
    getBalanceAccount();
  }, [address]);

  const toggleHideSideBar = () => {
    setShowSIdeBar(!showSideBar);
  };

  return (
    <div className={`al4 ${showSideBar ? "blur-set" : ""}`}>
      <div className={`aa4we5-ools ${showSideBar ? "show-sidebar" : ""}`}>
        <div className="1a5wwe">
          <div className="aks11w">
            <div className="4see-fd" onClick={() => toggleHideSideBar()}>
              <FontAwesomeIcon icon={faArrowRight} className="a41w-44" />
            </div>
          </div>
        </div>
        <div className="aa-wrr4f">
          <div className="aa4wr1-ss4">
            <div className="options-a45">
              <div className="feuu-ssl">
                <div className="aak-ffg1">
                  <div className="content-box-s41 ks-dkkuer">
                    <div className="sald-flik">
                      <div className="sask-ee">
                        <Blockies seed={username} size={15} scale={3} />
                      </div>
                    </div>
                    <div className="full-arr0">{username}</div>
                  </div>
                  <div className="s112s">
                    <hr className="saslo-f22g" />
                  </div>
                  <div className="content-box-s41">
                    <div className="full-arr1">
                      <div className="balance-saw4">Funds in wallet</div>
                      <div className="sask-asaws">
                        <div className="sskk-dsd11-f">
                          <div className="lsas-ewert-">
                            <div className="as4v45t">
                              <div className="sa-452-ee45">
                                <img
                                  src={ethereum}
                                  alt="ETH"
                                  className="ssla-41-sas11e"
                                />
                              </div>
                              <div className="sasl-fdf44-6645">
                                <div className="sask-rr44-uo1oy">
                                  <div className="saslk-ETH-asl">ETH</div>
                                  <div className="sask-tt1de">${ethPrice}</div>
                                </div>
                                <div className="amount-ssd-r1">
                                  <div className="saw-amount">{balance} </div>
                                  <div className="saw-amount-dsk ">
                                    ${balanceUSD}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="sask-rrt">
                            <hr className="sas-gf1dr4" />
                          </div>
                          <div className="lsas-ewert-">
                            <div className="as4v45t">
                              <div className="sa-452-ee45">
                                <img
                                  src={weth}
                                  alt="ETH"
                                  className="ssla-41-sas11e"
                                />
                              </div>
                              <div className="sasl-fdf44-6645">
                                <div className="sask-rr44-uo1oy">
                                  <div className="saslk-ETH-asl">WETH</div>
                                  <div className="sask-tt1de">${wethPrice}</div>
                                </div>
                                <div className="amount-ssd-r1">
                                  <div className="saw-amount">
                                    {balanceInWeth}
                                  </div>
                                  <div className="saw-amount-dsk ">
                                    ${balanceInWethUSD}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="sas-ee44-gg44">
                    <div className="sas-d444-r4">
                      <div className="content-box-s41">
                        <div className="full-arr2">
                          <Link to={`/account/${address}`} className="swe-gf4t">
                            <div className="slka-dsd22">
                              <FontAwesomeIcon icon={faUser} />
                              PROFILE
                              <FontAwesomeIcon icon={faAngleRight} />
                            </div>
                          </Link>
                        </div>
                      </div>
                      <div className="sasl-d11dsw">
                        <hr className="salk-44e3" />
                      </div>
                      <div className="content-box-s41">
                        <div className="full-arr2">
                          {" "}
                          <Link to={`/personal/settings`} className="swe-gf4t">
                            <div className="slka-dsd22">
                              <FontAwesomeIcon icon={faGear} />
                              SETTING
                              <FontAwesomeIcon icon={faAngleRight} />
                            </div>
                          </Link>
                        </div>
                      </div>
                      <div className="sasl-d11dsw">
                        <hr className="salk-44e3" />
                      </div>

                      <div className="content-box-s41 we4t-se">
                        <FontAwesomeIcon icon={faMessage} />
                        <div className="saskj-e">
                          <div className="full-arr2">CHAT ROOM</div>
                          <div className="a45e-ry5">soon</div>
                        </div>
                      </div>
                      <div className="sasl-d11dsw">
                        <hr className="salk-44e3" />
                      </div>

                      <div className="content-box-s41">
                        <div
                          onClick={handleDisconnectAccount}
                          className="full-arr2 sign-out"
                        >
                          <div className="slka-dsd22">SIGN OUT</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserSideBar;
