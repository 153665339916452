import React from "react";
import img403 from "../../../Assets/Images/403forbidden.png";
import "./403.css";

const Forbidden = () => {
  return (
    <div className="forbidden">
      <div className="aa4e-sw4w7">
        <div className="1wer1">
          <div className="1s55t">
            <img src={img403} alt="" className="aw4-aa1w4" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Forbidden;
