import React from "react";
import "./TextBelt.css";

const TextBelt = ({ text }) => {
  return (
    <div className="text-belt">
      <div className="text-belt__content">
        <span className="text-belt__text">{text}</span>
        {/* Add more text spans as needed */}
      </div>
    </div>
  );
};

export default TextBelt;
