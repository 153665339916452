import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import waiting from "../../../Assets/Images/waiting.gif";
import sucessLogo from "../../../Assets/Images/sucess.png";
import failLogo from "../../../Assets/Images/fail.png";
import "./TxWindow.css";
import { Link } from "react-router-dom";

const TxWindow = ({ pending, sucess, fail, txHash }) => {
  const [status, setStatus] = useState();
  useEffect(() => {
    if (pending) {
      setStatus(0);
    } else if (sucess) {
      setStatus(1);
    } else if (fail) {
      setStatus(2);
    }
  }, [fail, pending, sucess]);
  return (
    <React.Fragment>
      <div className="sa_WWerDEDSGHHH_fdf">
        <div className="sasEERRT_GgfN_HJHg">
          <div className="sas_WEFFDSsds_Ww">
            Waiting transaction confirmation...
          </div>
          {status === 0 && (
            <div className="saW_ERRTYffd_DEaD">
              <img src={waiting} alt="waiting" className="ddsEE_TTgF_S" />
            </div>
          )}
          {status === 1 && (
            <div className="saW_ERRTYffd_DEaD">
              <img src={sucessLogo} alt="sucess" className="ddsEE_TTgF_S" />
            </div>
          )}
          {status === 2 && (
            <div className="saW_ERRTYffd_DEaD">
              <img src={failLogo} alt="fail" className="ddsEE_TTgF_S" />
            </div>
          )}
          {txHash !== "" && (
            <div className="sasW_Errrt_GGfgTYTFD_Ss">
              Transaction hash:&nbsp;
              <span>
                <Link className="saseee_Rreew" to={`https://sepolia.etherscan.io/tx/${txHash}`}>
                  {txHash.substring(0, 10)}...
                  {txHash.substring(txHash.length - 10)}
                </Link>
              </span>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default TxWindow;
