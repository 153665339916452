// Import the functions you need from the SDKs you need
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDsPg8_eBiUu7eJ2_Nb4XEu6rp49pVqLxo",
  authDomain: "polaryssdk.firebaseapp.com",
  projectId: "polaryssdk",
  storageBucket: "polaryssdk.appspot.com",
  messagingSenderId: "362357093029",
  appId: "1:362357093029:web:6b1d78c5b05b712433134c",
  measurementId: "G-T3SYZ7MW6L",
};

// Initialize Firebase
const secondaryApp = firebase.initializeApp(firebaseConfig, "secondaryApp");

export { secondaryApp };
