/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import React from "react";
import { app } from "../../../../Database/Firebase/firebaseConfig";
import { useState } from "react";
import { toast } from "react-hot-toast";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import Web3 from "web3";
import { rpcapi } from "../../../Web3/RPCChain";
import { faPencil } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../../../Styles/EditSection.css";
import PolarysSDK from "../../../../Utils/SDKPolarysNFTs/src";
import EvmChain from "../../../../Utils/SDKPolarysNFTs/lib/EvmChain";
import Forbidden from "../../403/Forbidden";

const gateway = "https://ipfs.io/ipfs/";

const web3 = new Web3(new Web3(rpcapi));
const ipfsClient = require("ipfs-http-client");

const projectId = "2N26Gq2iiYdgMbtyNQB00oI2IiV";
const projectSecret = "20cb978b5228da15a95e82f3b8d5555a";

const auth =
  "Basic " + Buffer.from(projectId + ":" + projectSecret).toString("base64");

const ipfs = ipfsClient.create({
  host: "ipfs.infura.io",
  port: 5001,
  protocol: "https",
  headers: {
    authorization: auth,
  },
});

const db = app.firestore();

const EditSection = () => {
  const [owner, setOwner] = useState("");
  const [fileBanner, setFileBanner] = useState(null);
  const [fileCover, setFileCover] = useState(null);
  const [uploaded, setUploaded] = useState("");
  const { contract } = useParams(); //Get Adress Contract from Collection URI
  const [wallet, setWallet] = useState("");
  const [descriptionData, setDescriptionData] = useState("");
  const [addressRoyalties, setAddressRoyalties] = useState("");
  const [percentageData, setPercentageData] = useState(0);
  const [addField, setAddFields] = useState(false);
  const [fieldsCount, setFieldsCount] = useState(1); // new state hook to keep track of number of fields
  const [royaltyData, setRoyaltyData] = useState([]);

  const apiKey = "polar-663ade85-ad80-4c24-bb4b-5352c455bcf1-afJq1gMJNFS2uuU";

  useEffect(() => {
    const polarys = new PolarysSDK(apiKey);

    const options = {
      tokenAddress: contract,
      chain: EvmChain.ETHEREUM,
    };
    async function getOwner() {
      try {
        const crrOwner = await polarys.getContractOwner(options);
        setOwner(crrOwner);
      } catch (err) {
        ////console.log(err);
      }
    }
    getOwner();
  }, [contract]);

  useEffect(() => {
    //Get Wallet
    async function getWallet() {
      const wallet = await web3.eth.getAccounts();
      if (wallet[0] === undefined) {
      } else {
        const lowerAddress = wallet[0].toLowerCase();
        setWallet(lowerAddress);
        return lowerAddress;
      }
    }
    getWallet();
  }, []);

  useEffect(() => {
    const getAddrRoyalties = () => {
      const royaltiesRef = db.collection("collection").doc(contract);
      const unsuscribe = royaltiesRef.onSnapshot((doc) => {
        if (doc.exists) {
          const { royalties } = doc.data();
          setRoyaltyData(royalties);
        }
      });
      return unsuscribe;
    };
    const unsuscribe = getAddrRoyalties();
    return () => unsuscribe();
  }, [contract]);

  ////console.log(royaltyData);

  if (owner === wallet) {
    async function uploadBannerIPFS() {
      const bannerRef = db.collection("collection").doc(contract);
      const bannerDoc = await bannerRef.get();

      if (bannerDoc.exists) {
        const { banner } = bannerDoc.data();
        if (!banner) {
          try {
            const cid = await uploadBanner();
            setUploaded(cid);
            if (cid) {
              await bannerRef.update({
                banner: cid,
              });
            }
          } catch (err) {
            ////console.log(err);
          }
        } else if (banner) {
          try {
            const cid = await uploadBanner();
            setUploaded(cid);
            if (cid) {
              await bannerRef.update({
                banner: cid,
              });
            }
          } catch (err) {
            ////console.log(err);
          }
        }
      }
    }

    async function updateDescription() {
      const descriptionRef = db.collection("collection").doc(contract);
      const descriptionDoc = await descriptionRef.get();
      if (descriptionDoc.exists) {
        const { description } = descriptionDoc.data();
        if (description) {
          try {
            await descriptionRef.update({
              description: descriptionData,
            });
            toast.success("Description Updated");
          } catch (err) {
            toast.error("Description Update Failed");
          }
        } else {
          await descriptionRef.update({
            description: descriptionData,
          });
        }
      }
    }

    async function updateRoyalties() {
      const royaltiesRef = db.collection("collection").doc(contract);
      const royaltiesDoc = await royaltiesRef.get();
      if (royaltiesDoc.exists) {
        const { royalties } = royaltiesDoc.data();
        if (royalties) {
          const newRoyalties = [
            ...royalties,
            {
              address: addressRoyalties.toLowerCase(),
              percentage: Number(percentageData),
            },
          ];
          // Check if the newRoyalties array has more than 5 elements
          if (newRoyalties.length > 5) {
            toast.error("Cannot add more than 5 addresses");
            return; // Stop execution
          }
          try {
            await royaltiesRef.update({
              royalties: newRoyalties,
            });
            toast.success("Royalties Updated");
          } catch (err) {
            toast.error("Royalties Update Failed");
          }
        } else {
          const newRoyalties = [
            {
              address: addressRoyalties.toLowerCase(),
              percentage: Number(percentageData),
            },
          ];
          try {
            await royaltiesRef.update({
              royalties: newRoyalties,
            });
            toast.success("Royalties Updated");
          } catch (err) {
            toast.error("Royalties Update Failed");
          }
        }
      } else {
        toast.error(`Royalties document ${contract} not found`);
      }
    }

    async function uploadCoverIPFS() {
      const coverRef = db.collection("collection").doc(contract);
      const coverDoc = await coverRef.get();

      if (coverDoc.exists) {
        const { cover } = coverDoc.data();
        if (!cover) {
          try {
            const cid = await uploadCover();
            setUploaded(cid);
            if (cid) {
              await coverRef.update({
                cover: `${gateway}${cid}`,
              });
            }
          } catch (err) {
            // //console.log(err);
          }
        } else if (cover) {
          try {
            const cid = await uploadCover();
            setUploaded(cid);
            if (cid) {
              await coverRef.update({
                cover: `${gateway}${cid}`,
              });
            }
          } catch (err) {
            ////console.log(err);
          }
        }
      }
    }

    const uploadBanner = async () => {
      try {
        const added = await ipfs.add(fileBanner);
        return added.cid.toString();
      } catch (err) {
        ////console.log(err);
      }
    };

    const uploadCover = async () => {
      try {
        const added = await ipfs.add(fileCover);
        return added.cid.toString();
      } catch (err) {
        ////console.log(err);
      }
    };

    const handleFileChangeBanner = (event) => {
      const selectedFile = event.target.files[0];
      if (selectedFile.size > 10 * 1024 * 1024) {
        toast.error("File size is too large! Please select a file under 10MB.");
        setFileBanner(null);
      } else {
        setFileBanner(selectedFile);
      }
    };

    const handleFileChangeCover = (event) => {
      const selectedFile = event.target.files[0];
      if (selectedFile.size > 5 * 1024 * 1024) {
        toast.error("File size is too large! Please select a file under 10MB.");
        setFileCover(null);
      } else {
        setFileCover(selectedFile);
      }
    };

    const handleUpdateDescription = (e) => {
      const textDescription = e.target.value;
      if (textDescription === "") {
        toast.error("Please enter a description.");
      } else {
        setDescriptionData(textDescription);
      }
    };

    const handleAddRolyaltiesAddress = (e) => {
      const addressRoyalties = e.target.value;
      if (addressRoyalties === "") {
        toast.error("Please enter a address.");
      } else if (!web3.utils.isAddress(addressRoyalties)) {
        toast.error("Invalid ETH Address");
      } else {
        setAddressRoyalties(addressRoyalties);
      }
    };

    const handleAddRolyaltiesPercentage = (e) => {
      const addPercentage = e.target.value;
      if (addPercentage > 10) {
        toast.error("The royalties percentage can't be greater than 10");
      } else if (!Number(addPercentage)) {
        toast.error("Please enter a valid number"); // New error message for non-number input
      } else {
        setPercentageData(addPercentage);
      }
    };
    /*const handleAddFields = () => {
      if (fieldsCount < 5) {
        // limit to max 5 fields
        setFieldsCount(fieldsCount + 1);
      }
    };

    const handleQuitFields = () => {
      if (fieldsCount > 1) {
        // limit to max 5 fields
        setFieldsCount(fieldsCount - 1);
      }
    };*/

    const handleRemoveAddress = async (index) => {
      const royaltyRef = db.collection("collection").doc(contract);
      try {
        const royaltyDoc = await royaltyRef.get();
        if (royaltyDoc.exists) {
          const { royalties } = royaltyDoc.data();
          // Check whether index is valid
          if (Array.isArray(royalties) && royalties.length > index) {
            royalties.splice(index, 1); // Remove the address at index
            await royaltyRef.update({ royalties }); // Update the 'royalties' array
            toast.success("Address removed successfully");
          } else {
            ////console.log("Invalid index or 'royalties' is not an array");
            toast.error("Error removing: Invalid index");
          }
        } else {
          ////console.log("Document does not exist");
          toast.error("Error removing: Document does not exist");
        }
      } catch (err) {
        ////console.log(err);
        toast.error("Error removing: " + err.message); // Display the error message
      }
    };

    return (
      <div className="ujh-sh">
        <div className="kau-ee">
          <div className="akke-rr">
            <a href={`/collection/${contract}`} className="jhsy-aa">
              X
            </a>
          </div>
        </div>
        <div className="sija-rr">
          <div className="lak-ss">
            <div className="kaj-lki">
              <div className="kjuy-sg">
                <FontAwesomeIcon className="kjsi" icon={faPencil} />
                <h1 className="kkjyt">Edit Collection</h1>
              </div>
              <hr className="kjsa-okk" />
            </div>
          </div>
          <div className="lku-ujy">
            <div className="llklak">
              <div className="ika-lkk">
                <div className="kjai">
                  <h1 className="sset">Update or change you banner.</h1>
                </div>
                <hr className="kjsa-okk" />
                <div className="iksaa-tt">
                  <p className="kk-y-yt">
                    Note: we recommend that the banner have the dimensions
                    1400px x 400px for better experience.
                  </p>
                </div>
              </div>
              <div className="ujsi-kjja">
                <div className="ikss-jnn">
                  <h2 className="kjsi">Upload a Banner.</h2>
                  <input
                    className="kkj-sk"
                    type="file"
                    onChange={handleFileChangeBanner}
                  />
                  <button className="yhu-ee" onClick={uploadBannerIPFS}>
                    Upload
                  </button>
                  {uploaded && <p className="ika-sssr">Banner Uploaded</p>}
                </div>
              </div>
            </div>
          </div>
          <div className="lku-ujy">
            <div className="llklak">
              <div className="ika-lkk">
                <div className="kjai">
                  <h1 className="sset">Update or change you cover.</h1>
                </div>
                <hr className="kjsa-okk" />
                <div className="iksaa-tt">
                  <p className="kk-y-yt">
                    Note: we recommend that the cover have the dimensions 500px
                    x 500px for better experience.
                  </p>
                </div>
              </div>
              <div className="ujsi-kjja">
                <div className="ikss-jnn">
                  <h2 className="kjsi">Upload a Cover.</h2>
                  <input
                    className="kkj-sk"
                    type="file"
                    onChange={handleFileChangeCover}
                  />
                  <button className="yhu-ee" onClick={uploadCoverIPFS}>
                    Upload
                  </button>
                  {uploaded && <p className="ika-sssr">Cover Changed</p>}
                </div>
              </div>
            </div>
            <div className="lku-ujy">
              <div className="llklak">
                <div className="ika-lkk">
                  <div className="kjai">
                    <h1 className="sset">Update the description.</h1>
                  </div>
                  <hr className="kjsa-okk" />
                  <div className="iksaa-tt">
                    <p className="kk-y-yt">
                      Note: please, describe your project the best how you can,
                      do the collectors can get all information possible.
                    </p>
                  </div>
                </div>
                <div className="ujsi-kjja">
                  <div className="ikss-jnn">
                    <h2 className="kjsi">Update Description</h2>
                    <textarea
                      className="kkj-sk"
                      type="text"
                      onChange={handleUpdateDescription}
                    />
                    <button className="yhu-ee" onClick={updateDescription}>
                      Upload
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="lku-ujy">
              <div className="llklak">
                <div className="ika-lkk">
                  <div className="kjai">
                    <h1 className="sset">Update the royalties.</h1>
                  </div>
                  <hr className="kjsa-okk" />
                  <div className="iksaa-tt">
                    <p className="kk-y-yt">
                      Add the eth addresses needed corresponding to your
                      project, please the royalties can't be greater than 10%
                    </p>
                  </div>
                </div>
                <div className="ujsi-kjja">
                  <div className="ikss-jnn">
                    <h2 className="kjsi">Update royalties</h2>
                    <div className="aa4-ass7">
                      <div className="ty5-ak4">
                        {royaltyData &&
                          royaltyData.map((dataAddr, index) => {
                            return (
                              <div className="awe-a4er" key={index}>
                                {dataAddr.address ? (
                                  <>
                                    <div className="ak5-aw4">
                                      {dataAddr.address.substring(0, 10)}
                                      ...
                                      {dataAddr.address.substring(
                                        dataAddr.address.length - 10
                                      )}
                                    </div>
                                    <div className="55a-e44w">
                                      percentage: {dataAddr.percentage}
                                    </div>
                                    <div
                                      onClick={() => handleRemoveAddress(index)}
                                      className="w4s1-sw1r yhu-ee"
                                    >
                                      X
                                    </div>
                                  </>
                                ) : (
                                  <div>Address missing</div>
                                )}
                              </div>
                            );
                          })}
                      </div>
                    </div>
                    {[...Array(fieldsCount)].map(
                      (
                        _,
                        index // map over array to render multiple fields
                      ) => (
                        <div key={index} className="iksa-kjsu">
                          <div className="asa-eee wallet">
                            <input
                              className="kkj-sk eth-address"
                              type="text"
                              placeholder={`ETH Address ${index + 1}`}
                              onChange={handleAddRolyaltiesAddress}
                            />
                          </div>
                          <div className="asa-eee percentageData">
                            <input
                              className="kkj-sk percentage"
                              type="number"
                              placeholder={`Add Percentage ${index + 1}`}
                              onChange={handleAddRolyaltiesPercentage}
                            />
                          </div>
                          {/*fieldsCount > 1 && (
                            <div className="aaw-sff">
                              <a
                                onClick={handleQuitFields}
                                className="yhu-ee ikaj"
                              >
                                X
                              </a>
                            </div>
                          )*/}
                        </div>
                      )
                    )}
                    <div className="kk-ssqw">
                      <div className="kjak-hy">
                        <button className="yhu-ee" onClick={updateRoyalties}>
                          Upload
                        </button>
                      </div>
                      {/*fieldsCount < 5 && (
                        <div className="kjak-hy">
                          <button className="yhu-ee" onClick={handleAddFields}>
                            Add Other Field
                          </button>
                        </div>
                      )*/}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <>
        <Forbidden />
      </>
    );
  }
};

export default EditSection;
