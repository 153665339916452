import USDT from "../Assets/TokenImage/tether.png";
import BNB from "../Assets/TokenImage/Binance-coin-bnb-logo.png";
import LINK from "../Assets/TokenImage/Link.png";
import USDC from "../Assets/TokenImage/usdc.png";
import WBTC from "../Assets/TokenImage/wbtc.png";
import UNI from "../Assets/TokenImage/uni.png";
import AAVE from "../Assets/TokenImage/aave.png";
import DAI from "../Assets/TokenImage/dai.png";
import TCG from "../Assets/TokenImage/tcg.png";
import WETH from "../Assets/TokenImage/weth.png";
import PUSD from "../Assets/TokenImage/weth.png";

const erc20Tokens = [
  {
    symbol: "USDT",
    address: "0xdac17f958d2ee523a2206206994597c13d831ec7",
    logo: USDT,
  },
  {
    symbol: "BNB",
    address: "0xB8c77482e45F1F44dE1745F52C74426C631bDD52",
    logo: BNB,
  },
  {
    symbol: "LINK",
    address: "0x514910771AF9Ca656af840dff83E8264EcF986CA",
    logo: LINK,
  },
  {
    symbol: "USDC",
    address: "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
    logo: USDC,
  },
  {
    symbol: "WBTC",
    address: "0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599",
    logo: WBTC,
  },
  {
    symbol: "UNI",
    address: "0x5c69bee701ef814a2b6a3edd4b1652cb9cc5aa6f",
    logo: UNI,
  },
  {
    symbol: "AAVE",
    address: "0x7Fc66500c84A76Ad7e9c93437bFc5Ac33E2DDaE9",
    logo: AAVE,
  },
  /*   { symbol: "MAKER", address: "0x9f8f72aa9304c8b593d555f12ef6589cc3a579a2" },
  { symbol: "COMP", address: "0xc00e94cb662c3520282e6f5717214004a7f26888" },
  { symbol: "SNX", address: "0xc011a73ee8576fb46f5e1c5751ca3b9fe0af2a6f" },
  { symbol: "SUSHI", address: "0x6B3595068778DD592e39A122f4f5a5cF09C90fE2" },
  { symbol: "YFI", address: "0x0bc529c00C6401aEF6D220BE8C6Ea1667F6Ad93e" },
  { symbol: "HT", address: "0x6f259637dcd74c767781e37bc6133cd6a68aa161" }, */
  {
    symbol: "DAI",
    address: "0x6b175474e89094c44da98b954eedeac495271d0f",
    logo: DAI,
  },
  /*   { symbol: "BAT", address: "0x0D8775F648430679A709E98d2b0Cb6250d2887EF" },
  { symbol: "KNC", address: "0xdd974d5c2e2928dea5f71b9825b8b646686bd200" },
  { symbol: "REN", address: "0x408e41876cccdc0f92210600ef50372656052a38" },
  { symbol: "ZRX", address: "0xe41d2489571d322189246dafa5ebde1f4699f498" },
  { symbol: "LRC", address: "0xbbbbca6a901c926f240b89eacb641d8aec7aeafd" }, */
  /*   { symbol: "MANA", address: "0x0f5d2fb29fb7d3cfee444a200298f468908cc942" }, */
  {
    symbol: "TCG2.0",
    address: "0x0d31df7dedd78649a14aae62d99ccb23abcc3a5a",
    logo: TCG,
  },
  {
    symbol: "WETH",
    address: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
    logo: WETH,
  },
  {
    symbol: "PUSD",
    address: "0x8f3151750d73b31b4345f7a772468b737d8cd503",
    logo: PUSD,
  }, // Test
];

export { erc20Tokens };
