/* eslint-disable no-unused-vars */
import React from "react";
import "./NftInfoData.css";
import Blockies from "../../../Web3/Blockies/BlockiesGen";
import { formatNumber } from "../../../../Utils/NumberAbreviation";
import { useState } from "react";
import { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { app } from "../../../../Database/Firebase/firebaseConfig";
import { getName } from "../../../../Utils/getNFTInfo";
import { ipfsGateway, web3Connector } from "../../../Web3/web3";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "boxicons";
import opensea from "../../../../Assets/Images/Opensea NFT Marketplace.svg";
import looksrare from "../../../../Assets/Images/Looksrare.svg";
import {
  faHand,
  faChartLine,
  faUsers,
  faEllipsisV,
  faArrowsRotate,
} from "@fortawesome/free-solid-svg-icons";
import InfoAccordion from "./InfoDropdown";
import BidList from "./BidList";
import ActivitiesList from "./ActivitiesList";
import { makeId } from "../../../../Utils/nonceGenerator";
import { syncMetadata } from "../../../../Utils/getTokenById";
import { rpcapi } from "../../../Web3/RPCChain";
import {
  abi,
  exchangeData,
  getActivitiesByToken,
  getAllBidsByTokenId,
  getOrderCanceled,
  getOrders,
  nullAddress,
} from "../../../../Utils/getExchangeData";
import CreateOrder from "./CreateOrder";
import { BigNumber, ethers } from "ethers";
import {
  templateAuction,
  templateBid,
  templateOrder,
} from "../../../../Utils/templates";
import OrderData from "./OrderData";
import NotInSalePage from "./NotInSalePage";
import CreateAuction from "./CreateAuction";
import {
  templateAuctionBids,
  templateAuctionCanceled,
  templateAuctionCreated,
  templateOrderCanceled,
  templateOrderCreated,
  templateOrderFilled,
  templateTransfer,
} from "../../../../Utils/Templates/templates";
import "./DatePicker.css";
import { sepoliaAPI } from "../../../../Utils/APIs/APIList";

const db = app.firestore();

const noCover = "QmaoRr4Rdgrnod7Hs6ULZVJibzH2xmLq4fGySR3Tg2Dfzj";

const NftInfoData = ({
  currentOwner,
  tokenName,
  contractAddress,
  platform,
  bids,
  tokenType,
}) => {
  const [USDPrice, setUSDPrice] = useState(0);
  const [isOnSale, setIsOnSale] = useState(false);
  const [nameColl, setNameColl] = useState("");
  const [coverCID, setCoverCID] = useState("");
  const [isVeirified, setIsVerified] = useState(false);
  const [platformS, setPlatform] = useState();
  const [propertyLength, setPropertyLength] = useState(false);
  const hasName = Boolean(nameColl);
  const [showToggleRefresh, setShowToggleRefresh] = useState(false);
  const { contract, tokenId } = useParams();
  const [isOwner, setIsOwner] = useState(true);
  const [showSaleButton, setShowSaleButton] = useState();
  const [showAuctionButton, setShowAuctionButton] = useState();
  //const haveOrder = Boolean(dataOrder.status === 0);
  const [marketplace, setMarketplace] = useState("");
  const [wallet, setWallet] = useState();
  const [bidsData, setBidsData] = useState([]);
  const [tagActivities, setTagActivities] = useState([]);

  /*
   * New Update: Uranus Dev
   * Date: 16 August, 2023
   *
   * Changes: new smart contract, optimized and with new methods.
   * Events:
   * Basic Orders: OrderCreated, OrderFilled, OrderCanceled
   * Auctions: Auction Created, AuctionCompleted, BidPlaced
   *
   *
   */

  const [orderData, setOrderData] = useState([]);
  const [activeSales, setActiveSales] = useState();
  const [activities, setActivities] = useState([]);
  const [inAuction, setInAuction] = useState(false);
  const [priceRecovered, setPriceRecovered] = useState();

  useEffect(() => {
    async function getBids() {
      try {
        const lastOrderHash = activities[0].orderHash;
        const response = await getAllBidsByTokenId(tokenId, lastOrderHash);
        const result = await Promise.all(
          response.map(async (item) => {
            const bidData = await templateAuctionBids(item.args);
            const web3 = await web3Connector(rpcapi);

            return {
              ...bidData,
              highestBid: web3.utils.fromWei(bidData.highestBid, "ether"),
            };
          })
        );

        setPriceRecovered(result[0].highestBid && result[0].highestBid);

        ////console.log(result);
      } catch (err) {
        ////console.log(err.message);
      }
    }
    getBids();
  }, [activities, tokenId]);

  //Get Orders Executed:
  useEffect(() => {
    async function orders() {
      try {
        const data = await getOrders(tokenId);
        let pushOrder = [];

        const result = await Promise.all(
          data.map(async (item) => {
            let order;
            let activeSale;
            let blockNumber;
            let buyer;
            if (item.event === "OrderCreated") {
              order = await templateOrderCreated(item.args);
              activeSale = true;
              blockNumber = item.blockNumber;
              setInAuction(false);
            } else if (item.event === "OrderCanceled") {
              activeSale = false;
              order = await templateOrderCanceled(item.args);
              blockNumber = item.blockNumber;
            } else if (item.event === "OrderFilled") {
              if (item.args.buyer === nullAddress) {
                buyer = currentOwner[0].owner;
              }
              activeSale = false;
              order = await templateOrderFilled(item.args);
              blockNumber = item.blockNumber;
            } else if (item.event === "AuctionCreated") {
              activeSale = true;
              setInAuction(true);
              order = await templateAuctionCreated(item.args);
              blockNumber = item.blockNumber;
            } else if (item.event === "AuctionCanceled") {
              activeSale = false;
              setInAuction(false);
              order = await templateAuctionCanceled(item.args);
              blockNumber = item.blockNumber;
            }

            return {
              ...order,
              activeSale,
              buyer: item.args.buyer === nullAddress ? buyer : item.args.buyer,
            };
          })
        );

        pushOrder.push(result[0]);

        setActiveSales(result[0].activeSale);
        setOrderData(pushOrder);

        //console.log(result);
      } catch (err) {
        console.log(err);
      }
    }

    // Call orders function initially
    orders();

    // Subscribe to relevant events using on method
    const provider = new ethers.providers.JsonRpcProvider(sepoliaAPI);
    const contract = new ethers.Contract(exchangeData, abi, provider);

    contract.on("OrderCreated", orders);
    contract.on("OrderCanceled", orders);
    contract.on("OrderFilled", orders);
    contract.on("AuctionCreated", orders);
    contract.on("AuctionCanceled", orders);

    // Clean up subscriptions when the component unmounts
    return () => {
      contract.off("OrderCreated", orders);
      contract.off("OrderCanceled", orders);
      contract.off("OrderFilled", orders);
      contract.off("AuctionCreated", orders);
      contract.off("AuctionCanceled", orders);
    };
  }, [currentOwner, tokenId]);

  useEffect(() => {
    const unsubscribe = db
      .collection("buttons")
      .doc(`showSaleButton:${contract}:${tokenId}:${wallet}`)
      .onSnapshot((snapshot) => {
        if (snapshot.exists) {
          const { showItem } = snapshot.data();
          setShowSaleButton(showItem);
        }
      });

    return () => unsubscribe();
  }, [wallet, contract, tokenId]);

  useEffect(() => {
    const unsubscribe = db
      .collection("buttons")
      .doc(`showAuctionButton:${contract}:${tokenId}:${wallet}`)
      .onSnapshot((snapshot) => {
        if (snapshot.exists) {
          const { showItem } = snapshot.data();
          setShowAuctionButton(showItem);
        }
      });

    return () => unsubscribe();
  }, [wallet, contract, tokenId]);

  useEffect(() => {
    function checkPlatform() {
      if (platform !== "OpenSea" || platform !== "LooksRare") {
        setMarketplace("TAGWEB3");
      } else {
        setMarketplace(platform);
      }
    }
    checkPlatform();
  }, [platform]);

  async function getWallet(provider) {
    const web3 = await web3Connector(provider);
    const wallet = await web3.eth.getAccounts();
    if (wallet[0] === undefined) {
    } else {
      const lowerAddress = wallet[0].toLowerCase();
      return lowerAddress;
    }
  }

  useEffect(() => {
    async function getWalletAddress(provider) {
      const web3 = await web3Connector(provider);
      const wallet = await web3.eth.getAccounts();
      if (wallet[0] === undefined) {
      } else {
        const lowerAddress = wallet[0].toLowerCase();
        setWallet(lowerAddress);
        return lowerAddress;
      }
    }

    getWalletAddress(rpcapi);
  }, []);

  useEffect(() => {
    async function checkOwner() {
      try {
        const account = await getWallet(rpcapi);
        const owner = currentOwner[0].owner;
        if (owner !== account) {
          setIsOwner(false);
        }
      } catch (err) {
        ////console.log(err);
      }
    }
    checkOwner();
  }, [currentOwner]);

  const toggleShowRefresh = () => {
    setShowToggleRefresh(!showToggleRefresh);
  };

  async function refreshMetadata() {
    try {
      const response = await syncMetadata(contract, tokenId);
      return response;
    } catch (err) {
      console.error(err);
    }
  }

  useEffect(() => {
    if (currentOwner.length > 1) {
      setPropertyLength(true);
    } else {
      setPropertyLength(false);
    }
  }, [currentOwner]);

  useEffect(() => {
    function checkPlatfom() {
      if (platform === "OpenSea") {
        setPlatform(opensea);
      } else if (platform === "LooksRare") {
        setPlatform(looksrare);
      }
    }
    checkPlatfom();
  }, [platform]);

  useEffect(() => {
    async function getCollection() {
      try {
        const collectionRef = db.collection("collection").doc(contractAddress);
        const collectionDoc = await collectionRef.get();
        const nameCollection = await getName(contractAddress);

        if (collectionDoc.exists) {
          const { name, cover, verified } = collectionDoc.data();
          if (name && verified && cover) {
            setNameColl(name);
            setCoverCID(cover);
            setIsVerified(true);
          } else {
            setNameColl(nameCollection);
            setCoverCID(noCover);
            setIsVerified(false);
            await collectionRef.update({ name: nameCollection });
          }
        } else {
          setNameColl(nameCollection);
          setCoverCID(noCover);
          setIsVerified(false);
          await collectionRef.update({ name: nameCollection });
        }
      } catch (error) {
        ////console.log("Error occurred while retrieving collection:", error);
        // Handle the error or display an appropriate error message
      }
    }
    getCollection();
  }, [contractAddress]);

  ////console.log(orderData);

  /*
   *
   * New Update: Obtain all activities by token
   *
   */

  useEffect(() => {
    async function getActivities() {
      try {
        const web3 = await web3Connector(rpcapi);
        const result = await getActivitiesByToken(contract, tokenId);
        const response = await Promise.all(
          result.map(async (item) => {
            let data;
            let date;
            let buyer;
            const eventPrice =
              item.args.price && BigNumber.from(item.args.price).toString();
            let price;
            if (item.event === "OrderFilled") {
              if (item.args.buyer === nullAddress) {
                buyer = currentOwner[0].owner;
                price =
                  priceRecovered &&
                  web3.utils.toWei(Number(priceRecovered).toString(), "ether");
                //console.log(price, priceRecovered);
              }
              data = await templateOrderFilled(item.args);
              date = await getTimestampFromBlock(item.blockNumber);
            } else if (item.event === "Transfer") {
              data = await templateTransfer(item.args);
              date = await getTimestampFromBlock(item.blockNumber);
            }
            return {
              ...data,
              date,
              buyer: item.args.buyer === nullAddress ? buyer : item.args.buyer,
              price: eventPrice === 0 ? price : eventPrice,
            };
          })
        );
        setActivities(response);
        //console.log(result);
      } catch (err) {
        //console.log(err.message);
      }
    }

    async function getTimestampFromBlock(blockNumber) {
      try {
        const provider = new ethers.providers.JsonRpcProvider(sepoliaAPI); // Replace 'providerUrl' with your Ethereum provider URL
        const block = await provider.getBlock(blockNumber);
        return block.timestamp;
      } catch (err) {
        console.error("Error retrieving block timestamp:", err);
        return null;
      }
    }

    getActivities();
  }, [contract, currentOwner, priceRecovered, tokenId]);

  return (
    <React.Fragment>
      <div className="fg14k-lj4h">
        <div className="sd12-dlfg4">
          <div className="lsd1-yg22d">
            <div key={contract} className="s11fgh-dft">
              <div className="as15-dsd44">
                <div className="sask--154hg">
                  <Link
                    className="sas-lrrt4"
                    to={`/collection/${contractAddress}`}
                  >
                    <div className="as41-tty45f">
                      <div className="ska-sas-gt">
                        <img
                          src={
                            hasName
                              ? `${ipfsGateway}${coverCID}`
                              : `${ipfsGateway}${noCover}`
                          }
                          alt={coverCID}
                          className="sas-rytu4"
                        />
                      </div>
                      <div className="name-saler">
                        {hasName ? nameColl : tokenName}
                      </div>
                      <div className="verified-ask1">
                        {isVeirified && (
                          <div className="sakj--dsdre">
                            <box-icon
                              name="badge-check"
                              type="solid"
                              color="#465ee8"
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </Link>
                  <div className="asd_Tdfty-ygf">
                    <div className="sd_rrT_Rdsd">
                      <div
                        className="three-points"
                        onClick={() => toggleShowRefresh()}
                      >
                        <FontAwesomeIcon icon={faEllipsisV} />
                      </div>
                      <div className="sas-R_Tyyu_Tss">
                        <div
                          onClick={() => refreshMetadata()}
                          className={`rTfE_Tt1fdE ${
                            showToggleRefresh ? "ShowRefresh" : "notShowRefresh"
                          }`}
                        >
                          <div className="sasdsdE_TTTygss_WW">
                            <FontAwesomeIcon icon={faArrowsRotate} />
                            <div className="sask_Etty_Ths">
                              Refresh Metadata
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="as22f5-fds">
                  <div className="sl4e-fdgf">{tokenName}</div>
                </div>
                {propertyLength ? (
                  <React.Fragment key={makeId(10)}>
                    <div className="slk-s1d565">
                      <div className="tokenInfo-sss1d52">
                        <div className="crrOwner-asel-f14">
                          <div className="sas154e-gf5">
                            <div className="ask--fdsasf">
                              <FontAwesomeIcon icon={faUsers} />
                            </div>
                          </div>
                          <div className="adkfg-gfd55">
                            Owners
                            <div className="slak-d">
                              <div className="saskFf-f">
                                {currentOwner.length}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {currentOwner.map((data) => (
                      <div key={makeId(10)} className="slk-s1d565">
                        <div className="tokenInfo-sss1d52">
                          <div className="crrOwner-asel-f14">
                            <div className="sas154e-gf5">
                              <Blockies seed={data.owner} size={15} scale={3} />
                            </div>
                            <div className="adkfg-gfd55">
                              Current Owner
                              <div className="slak-d">
                                <Link
                                  to={`/account/${data.owner}`}
                                  className="sajr1s-d"
                                >
                                  {data.owner.substring(0, 5)}...
                                  {data.owner.substring(data.owner.length - 5)}
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </React.Fragment>
                )}
                <div className="ksn-er1j4">
                  <hr className="ask-ert2" />
                </div>
                {activeSales ? (
                  orderData &&
                  orderData.map((data) => (
                    <React.Fragment key={makeId(10)}>
                      <div className="lask1s-fd44">
                        <div className="ss145f-ds14d">
                          <OrderData
                            paymentToken={
                              data.paymentToken && data.paymentToken
                            }
                            price={data && data.price}
                            seller={data && data.seller}
                            orderHash={data && data.orderHash}
                            expirationTime={data && data.expirationTime}
                            asset={data && data.asset}
                            isOwner={isOwner}
                            buyer={data && data.buyer}
                            tokenAmount={data && data.tokenAmount}
                            startTime={data.startTime && data.startTime}
                            highestBidder={
                              data.highestBidder && data.highestBidder
                            }
                            highestBid={data.highestBid && data.highestBid}
                            inAuction={inAuction}
                          />
                        </div>
                      </div>
                    </React.Fragment>
                  ))
                ) : (
                  <React.Fragment>
                    <div className="s_WER_tR">
                      <NotInSalePage isOwner={isOwner} />
                    </div>
                  </React.Fragment>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bid-sawe155gd-sdrt">
        {/* <div className="aslki--45hgh">
          <div className="saslkrt44-yy">
            <InfoAccordion
              info={<BidList Bids={bidsData} />}
              title={"Bids"}
              icon={faHand}
              isNewClassName={true}
              className={"Bids"}
            />
          </div>
        </div> */}
        <div className="aslki--45hgh">
          <div className="saslkrt44-yy">
            <InfoAccordion
              info={
                <ActivitiesList activities={activities} isActive={activities} />
              }
              title={"Activities"}
              icon={faChartLine}
              isNewClassName={true}
              className={"Bids"}
            />
          </div>
        </div>
        <React.Fragment>
          <div
            className={`Iu_s414e5 sju_er7-sw ${
              showSaleButton ? "show_sw-yu" : "not_show_rt"
            }`}
          >
            <CreateOrder
              isOwner={isOwner}
              isActive={showSaleButton}
              tokenType={tokenType}
            />
          </div>
        </React.Fragment>
        <React.Fragment>
          <div
            className={`Iu_s414e5 sju_er7-sP ${
              showAuctionButton ? "show_sw-yu" : "not_show_rt"
            }`}
          >
            <CreateAuction
              isOwner={isOwner}
              isActive={showAuctionButton}
              asset={tokenType}
            />
          </div>
        </React.Fragment>
      </div>
    </React.Fragment>
  );
};

export default NftInfoData;
