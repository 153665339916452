/* eslint-disable eqeqeq */
//Number Suffix Aggregator
export function abbreviateNumber(num) {
  if (num >= 1000) {
    var suffixes = ["", "K", "M", "B", "T"]; // Define the suffixes for thousands, millions, billions, trillions, etc.
    var suffixIndex = Math.floor((("" + num).length - 1) / 3); // Calculate the index of the suffix to use based on the number of digits
    var shortNum = parseFloat(
      (suffixIndex != 0 ? num / Math.pow(1000, suffixIndex) : num).toPrecision(
        3
      )
    ); // Divide the number by the appropriate power of 1000 and round it to 3 significant digits
    return shortNum + suffixes[suffixIndex]; // Add the suffix to the shortened number and return it
  } else if (num < 1) {
    return parseFloat(num.toPrecision(3)); // If the number is less than 1, round it to 3 significant digits and return it
  } else {
    return num; // If the number is between 1 and 999, return it unchanged
  }
}

export function calculatePercentage(number, percentage) {
  return (number / percentage) * 100;
}

export function formatNumber(number) {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
