import axios from "axios";

export async function getLatestSale(contractAddress, tokenId) {
  const gateway = `https://api.rarible.org/v0.1/items/ETHEREUM:${contractAddress}:${tokenId}`;

  try {
    const response = await axios(gateway);
    if (response.data.lastSale && response.data.lastSale.price) {
      const price = response.data.lastSale.price;
      if (price) {
        return price;
      }
    }
  } catch (error) {
    console.error(`Error fetching latest sale for token ${tokenId}: ${error}`);
  }

  return 0;
}

export async function getCurrentPrice(contractAddress, tokenId) {
  const gateway = `https://api.rarible.org/v0.1/activities/byItem?type=LIST&type=SELL&itemId=ETHEREUM:${contractAddress}:${tokenId}`;

  try {
    const response = await axios(gateway);
    if (
      response.data.activities &&
      response.data.activities[0].price &&
      response.data.activities[0].date
    ) {
      const typeData = response.data.activities[0].type;
      if (typeData === "SELL") {
      } else {
        const data = response.data.activities[0].price;
        const date = response.data.activities[0].date;
        return { data, date };
      }
    }
  } catch (err) {}
}

export async function getSales() {}
