/* eslint-disable no-unused-vars */
import { rpcapi } from "../Components/Web3/RPCChain";
import { isTrustWallet, web3Connector } from "../Components/Web3/web3";
import { app } from "../Database/Firebase/firebaseConfig";
import { msgToSign } from "./msgToSign";
import { generateToken } from "./nonceTAGWEB3";
import { makeId } from "./nonceGenerator";
import { toast } from "react-hot-toast";

const db = app.firestore();

async function getWallet(provider) {
  const web3 = await web3Connector(provider);
  const wallet = await web3.eth.getAccounts();
  if (wallet[0] === undefined) {
  } else {
    const lowerAddress = wallet[0].toLowerCase();
    return lowerAddress;
  }
}

// Firmar mensaje
const SignIn = async (address) => {
  const msgToSignH =
    msgToSign +
    `\n` +
    "Wallet Address: " +
    address +
    `\n` +
    `\n` +
    "Nonce: " +
    generateToken(20);
  // Get the current user's wallet address
  const userAddress = address;
  // Buffer for the message to sign
  const msg = `0x${Buffer.from(msgToSignH, "utf8").toString("hex")}`;
  const isTrust = await isTrustWallet(rpcapi);
  if (rpcapi || isTrust === true) {
    try {
      const signature = await rpcapi.request({
        method: "personal_sign",
        params: [msg, userAddress],
      });
      return signature;
    } catch (err) {
      //console.log(err);
    }
  }
};

const handleConnectAccount = async () => {
  if (rpcapi) {
    const account = await getWallet(rpcapi);

    try {
      // Check if user has logged in before and get their login time
      const initRef = db.collection("users").doc(account);
      const initDoc = await initRef.get();
      const now = Date.now();
      const isLogin = true;

      let expiration;
      if (initDoc.exists) {
        const { expirationTime } = initDoc.data();
        if (now > expirationTime || !expirationTime) {
          // if expiration time has passed, sign a message and update the login time and expiration time
          const signature = await SignIn(account);
          const newLoginTime = now;
          const newExpirationTime = now + 24 * 60 * 60 * 1000;
          await initRef.update({
            loginTime: newLoginTime,
            expirationTime: newExpirationTime,
            signature,
            isLogin,
          });
        } else {
          // if expiration time hasn't passed, update the login time but don't sign a new message
          const newLoginTime = now;
          await initRef.update({
            loginTime: newLoginTime,
            isLogin,
          });
        }
        expiration = expirationTime;
      } else {
        // if user hasn't logged in before, sign a message and set expiration time
        const signature = await SignIn(account);
        const newLoginTime = now;
        const newExpirationTime = now + 24 * 60 * 60 * 1000;
        await initRef.set({
          userId: makeId(20),
          address: account,
          loginTime: newLoginTime,
          expirationTime: newExpirationTime,
          signature,
          isLogin,
        });
        expiration = newExpirationTime;
      }

      // Notifications
      toast.success(
        <span>
          Wallet Connected: {account.substring(0, 5)}...
          {account.substring(account.length - 5)}
        </span>,
        {
          duration: 3000,
        }
      );

      // Debug de todo lo realizado.
    } catch (err) {
      // Error
      const Error = JSON.stringify(err);
      const ErrN = Error.message;

      // Notifications
      toast.error(<span>Error: {ErrN}</span>, {
        duration: 3000,
      });
      //console.log(err);
    }
  }
};

const handleDisconnectAccount = async () => {
  const isTrust = await isTrustWallet(rpcapi);
  try {
    let wallet;
    if (typeof rpcapi !== undefined) {
      wallet = await getWallet(rpcapi);
    } else if (isTrust === true) {
      wallet = await getWallet(rpcapi);
    }
    const initRef = db.collection("users").doc(wallet);
    const initDoc = await initRef.get();
    //Condicional para la base de datos
    if (initDoc.exists) {
      const { isLogin } = initDoc.data();
      if (isLogin === true) {
        await initRef.update({
          isLogin: false,
        });
      }
    }

    // Recargar la pagina actual despues de desconectar
    window.location.reload();
  } catch (err) {
    //console.log(err);
  }
};

export { handleConnectAccount, handleDisconnectAccount };
