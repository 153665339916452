/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import "../../../Styles/Carousel.css";
import hg1 from "../../../Assets/Images/1.png";
import hg2 from "../../../Assets/Images/9.png";
import hg3 from "../../../Assets/Images/Qmd7z8WZAWrB42KNTq6Dyvm5D2UJ7FKPWctjRAhBe7tsKw.jpg";
import hg4 from "../../../Assets/Images/1389.png";
import { Link } from "react-router-dom";
import "boxicons";
import { toast } from "react-hot-toast";

const addressContract = [
  { address: "0x53870a83ad9f2a1aa17a7ce6566ed45d8bf0aa3e" },
  { address: "0x9bff6Bc431B3A7d41bdD8142c6BDEd37C5b7b562" },
  { address: "0x713863fCb078D4bFd34bfda6579F060A76a111EE" },
  { address: "0xc97774d55825b4db5cb250f9280f6564d8c8ac0d" },
];

const images = [
  {
    src: hg1,
    alt: "Image 1",
    name: "Mad Eagle Club 2.0",
    link: `/collection/${addressContract.madeagleclub}`,
  },
  {
    src: hg2,
    alt: "Image 2",
    name: "Bearded Buddies",
    link: `/collection/${addressContract.BeardedBuddies}`,
  },
  {
    src: hg3,
    alt: "Image 3",
    name: "Fire Prolog Series",
    link: `/collection/${addressContract.FirePrologSeries}`,
  },
  {
    src: hg4,
    alt: "Image 4",
    name: "#ToppDogs",
    link: `/collection/${addressContract.ToppDogs}`,
  },
];

const Carousel = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const onMainnet = () => {
    toast.error("This collections are in Mainnet");
  };

  const handlePrevClick = () => {
    setCurrentImageIndex(
      currentImageIndex === 0 ? images.length - 1 : currentImageIndex - 1
    );
  };

  const handleNextClick = () => {
    setCurrentImageIndex(
      currentImageIndex === images.length - 1 ? 0 : currentImageIndex + 1
    );
  };

  const showPrevArrow = currentImageIndex !== 0;
  const showNextArrow = currentImageIndex !== images.length - 1;

  return (
    <div className="aslk-fff">
      <div className="saslk-fdf4d">
        <div className="sdaslk-fdf44h">
          <Link
          onClick={() => onMainnet()}
            /*to={`/collection/${addressContract[currentImageIndex].address}`} */
            className="as44-fdf44"
          >
            <div className="sjkj-awew">
              <div className="carousel">
                <div className="carousel-image-container">
                  <div className="skall-waw ">
                    <div className="carousel-controls">
                      <img
                        className={`carousel-image ${
                          currentImageIndex === 0 ? "show" : "show"
                        }`}
                        src={images[currentImageIndex].src}
                        alt={images[currentImageIndex].alt}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="salk-s44d-rrt">
                <div className="sasl-ff44ht">
                  <div className="s54g-tty">
                    {images[currentImageIndex].name}
                    <div className="sasl-dd44ff">
                      <box-icon
                        name="badge-check"
                        type="solid"
                        color="#465ee8"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Link>
          <div className="ssa">
            <div className="sasdsd-f-dfgg">
              {showPrevArrow && (
                <button
                  className="carousel-control prev"
                  onClick={handlePrevClick}
                >
                  &lt;
                </button>
              )}
              {showNextArrow && (
                <button
                  className="carousel-control next"
                  onClick={handleNextClick}
                >
                  &gt;
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Carousel;
