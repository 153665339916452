import React, { useState } from "react";
import "./InfoAccordion.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const InfoAccordion = ({ title, info, icon, isNewClassName, className }) => {
  const [isActivated, setIsActivated] = useState(false);
  const isActive = Boolean(isNewClassName);

  const toggleAccordion = () => {
    setIsActivated(!isActivated);
  };

  return (
    <div className={`info-accordion ${isActivated ? "active" : ""}`}>
      <div className="accordion-header" onClick={toggleAccordion}>
        <div className="accordion-header-title">
          <FontAwesomeIcon icon={icon && icon} />
          <div className="title-header--sas44">{title}</div>
        </div>
      </div>
      <div className={`accordion-content ${isActive ? className : ""}`}>
        <div className="info">{info}</div>
      </div>
    </div>
  );
};

export default InfoAccordion;
