import { secondaryApp } from "./firebase.js";
import { v4 } from "uuid";

const db = secondaryApp.firestore();

function createUuid() {
  const token = v4();
  return token;
}

const tokenGenerator = () => {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const length = 10;
  let token = "";

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    const randomChar = characters[randomIndex];
    token += randomChar;
  }

  return token;
};

function userIdGen() {
  const characters = "QWERTYUJHGFDSAqwertyujhgfdsa0123456";
  const length = 5;
  let token = "";

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    const randomChar = characters[randomIndex];
    token += randomChar;
  }

  return token;
}

function apiGenerator() {
  const token = "-" + tokenGenerator();
  const validatorId = "polar-";
  const userId = userIdGen();
  const uuid = createUuid();
  const api = validatorId + uuid + token + userId;
  return api;
}

const addToken = async () => {
  const api = apiGenerator();
  if (!api) return console.error("api is undefined"); // Add this line
  const docApi = api.slice(-15);
  const tokenRef = db.collection("dataApiKey").doc(docApi);
  const doc = await tokenRef.get();
  try {
    if (doc.exists) {
      await tokenRef.update({
        id: docApi,
        api,
        status: "active",
      });
    } else {
      await tokenRef.set({
        id: docApi,
        api,
        status: "active",
      });
    }
    console.log(
      `UserId: ${docApi}` +
        `\n` +
        `API ${api} added and active, remember copy and not share with anybody`
    );
  } catch (err) {
    console.error(err);
    return `Error adding API ${api}. Please try again later.`;
  }
};

const checkAuth = async (apiKey, userId) => {
  const userRef = db.collection("dataApiKey").doc(userId);
  const doc = await userRef.get();

  if (!doc.exists) {
    // If the document doesn't exist, throw an error
    throw new Error(`User with ID ${userId} does not exist.`);
  }

  const { api } = doc.data();
  return api === apiKey;
};

export { addToken, checkAuth };
