/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import "../../../Styles/Data.css";
//import skia from "../../../Assets/Images/sagrid.gif";
import Carrousel from "./Carrousel";
import ParticlesEffect from "./ParticlesEffect";
import { Link } from "react-router-dom";
import { web3Connector } from "../../Web3/web3";
import { rpcapi } from "../../Web3/RPCChain";
import { useEffect } from "react";

const Data = () => {
  const [userWallet, setUserWallet] = useState("");

  async function getWallet() {
    const web3 = await web3Connector(rpcapi);
    const wallet = await web3.eth.getAccounts();
    if (wallet[0] === undefined) {
    } else {
      const lowerAddress = wallet[0].toLowerCase();
      setUserWallet(lowerAddress);
      return lowerAddress;
    }
  }

  useEffect(() => {
    getWallet();
  }, []);

  return (
    <div className="bsa-sle">
      <div className="skw-swa fadeIn">
        <div className="slik-saw">
          <div className="sju-aow">
            <div className="aksi-saw"></div>
            <div className="seer-rty">
              <div className="sip-eww">
                <h1 className="skoi-waw">Welcome to</h1>
                <h1 className="ski-wqaa">TAGWEB3</h1>
              </div>
              <div className="sasl_ettY_s">In Sepolia</div>
              <div className="skek">
                <p className="skek-esas">
                  TAGWEB3 is the marketplace where you can trade your digital
                  assets safely and quickly. Join our community and get access
                  to Exclusive Mints, Pre-Sales.
                </p>
              </div>
            </div>
            <div className="saslk--dsd4544fff">
              <div className="button-sassals--44k">
                <div className="saskldsd--dsde">
                  <Link className="sas-dsdert-gg" to={`/account/${userWallet}`}>
                    <div className="sasl-444gtt-t44">Vender</div>
                  </Link>
                </div>
              </div>
              <div className="button-sassals--44k">
                <div className="saskldsd--dsde">
                  <Link className="sas-dsdert-gg" to={`/explore/collections`}>
                    <div className="sasl-444gtt-t45">Explore</div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <Carrousel />
        </div>
      </div>
    </div>
  );
};

export default Data;
