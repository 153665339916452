import React from "react";

const SearchbarResult = ({ cover, name }) => {
  return (
    <React.Fragment>
      <img src={cover} alt={name} className="sikaj" />
    </React.Fragment>
  );
};

export default SearchbarResult;
