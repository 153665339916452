import React from "react";
import TableFooter from "./TableFooter";
import "../../../Styles/footer.css";
import { makeId } from "../../../Utils/nonceGenerator";
import { exchangeData } from "../../../Utils/getExchangeData";

const data = {
  title: "Marketplace",
  list1: "Explore",
  list2: "Blog",
};

const link = {
  link1: "https://sepolia.tagweb3.io/explore/collections",
  link2: "https://tagweb3.io/blog",
};

const linksRef = {
  smartContract: `https://sepolia.etherscan.io/address/${exchangeData}`,
  becomeAPartner: `https://tagweb3.io/form/request`,
  faq: "https://sepolia.tagweb3.io/faq",
};

const joinUs = {
  twitter: "https://twitter.com/TAGWEB3",
  discord: "",
  telegram: "https://t.me/theartistguildweb3",
};

const Foot = () => {
  return (
    <div className="sol-sli">
      <div className="kisl">
        <div className="skai-asa">
          <div className="laik-asay">
            <TableFooter
              key={makeId(10)}
              titleUl={data.title}
              listLi={data.list1}
              listLi2={data.list2}
              link1={link.link1}
              link2={link.link2}
            />
            <TableFooter
              key={makeId(10)}
              titleUl="Links"
              listLi="Smart Contract"
              listLi2="Become a Partner"
              listLi3="FAQs"
              link1={linksRef.smartContract}
              link2={linksRef.becomeAPartner}
              link3={linksRef.faq}
            />
            <TableFooter
              key={makeId(10)}
              titleUl="Join Us"
              listLi="Twitter"
              listLi2="Discord"
              listLi3="Telegram"
              link1={joinUs.twitter}
              link2={joinUs.discord}
              link3={joinUs.telegram}
            />
          </div>
          <hr className="sas-ewr" />
        </div>
        <div className="osl-asl">
          <div className="olas-was">
            <p className="aso-easa">© TAGWEB3 - 2023</p>
            <p className="aso-easa">Community guidelines</p>
            <p className="aso-easa">Privacy</p>
            <p className="aso-easa">Terms</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Foot;
