/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import "./BidList.css";
import { Link, useParams } from "react-router-dom";
import { web3Connector } from "../../../Web3/web3";
import { abi721 } from "../../../../Utils/getExchangeData";
import { rpcapi } from "../../../Web3/RPCChain";
import { ethers } from "ethers";
import ethereum from "../../../../Assets/Images/jhj.png";
import { getCurrentEthPrice } from "../../../../Utils/accountStats";
import { app } from "../../../../Database/Firebase/firebaseConfig";
import { toast } from "react-hot-toast";
import { sepoliaAPI } from "../../../../Utils/APIs/APIList";

const db = app.firestore();

const BidList = ({ Bids }) => {
  const haveBids = Boolean(Bids.length > 0);
  const [bidData, setBidData] = useState([]);
  const [wallet, setWallet] = useState();
  const [isOwner, setIsOwner] = useState(false);
  const { contract, tokenId } = useParams();
  const [isBidder, setIsBidder] = useState(false);

  async function getWallet(provider) {
    const web3 = await web3Connector(provider);
    const wallet = await web3.eth.getAccounts();
    if (wallet[0] === undefined) {
    } else {
      const lowerAddress = wallet[0].toLowerCase();
      return lowerAddress;
    }
  }

  useEffect(() => {
    async function checkOwner() {
      try {
        const bids = Bids;
        for (var i = 0; i < bids.length; i++) {
          const bidder = bids[i].bidder;
          if (bidder === wallet) {
            setIsBidder(true);
          }
        }
        const provider = new ethers.providers.Web3Provider(rpcapi);
        const signer = provider.getSigner();
        const erc721 = new ethers.Contract(contract, abi721, signer);
        const seller = await erc721.ownerOf(tokenId);
        const sellerLowerCase = seller.toLowerCase();
        ////console.log(wallet);
        if (wallet === sellerLowerCase) {
          setIsOwner(true);
        }
      } catch (err) {
       // //console.log(err);
      }
    }

    checkOwner();
  }, [wallet, contract, tokenId, Bids]);

  useEffect(() => {
    async function getWalletAddress(provider) {
      const web3 = await web3Connector(provider);
      const wallet = await web3.eth.getAccounts();
      if (wallet[0] === undefined) {
      } else {
        const lowerAddress = wallet[0].toLowerCase();
        setWallet(lowerAddress);
        return lowerAddress;
      }
    }

    getWalletAddress(rpcapi);
  }, []);

  useEffect(() => {
    if (haveBids) {
      async function bidsFormated() {
        try {
          const etherPrice = await getCurrentEthPrice();
          const web3 = await web3Connector(sepoliaAPI);
          const data = Bids.map((item) => ({
            ...item,
            amount: web3.utils.fromWei(item.amount, "ether"),
            amountInUSD: web3.utils.fromWei(item.amount, "ether") * etherPrice,
          }));
          setBidData(data);
        } catch (err) {
          ////console.log(err);
        }
      }

      bidsFormated();
    }
  }, [Bids, haveBids]);

  return (
    <React.Fragment>
      {haveBids ? (
        bidData &&
        bidData.map((data) => {
          const isZero = data.status === "0";
          return (
            isZero && (
              <React.Fragment>
                <div className="alks-sawer">
                  <div className="sak--gfg44j">
                    <div className="bidder-sas45-dg">
                      Bidder:
                      <div className="as-44hffd">
                        <Link
                          to={`/account/${data.bidder}`}
                          className="sask-ffg4"
                        >
                          {data.bidder.substring(0, 5)}...
                          {data.bidder.substring(data.bidder.length - 5)}
                        </Link>
                      </div>
                    </div>
                    <div className="a_WE_Rtt_RYUy">
                      <div className="sW_eeR_TYUuy_TGf">
                        <div className="sWFG_hT_TGF">
                          <img
                            src={ethereum}
                            alt="ETH"
                            className="sw_ErrTUHGff_DE"
                          />
                        </div>
                        <div className="Bids-sder-hh45">{data.amount}</div>
                        <div className="Bids-sder-hh46">
                          ${Number(data.amountInUSD).toFixed(2)}
                        </div>
                      </div>
                    </div>
                  </div>
                  {isOwner ? (
                    <div className="salE_RTY_fE">
                      <div className="s_EeRR_ttg_T">
                        <button
                          /* onClick={() => handleTakeBid(data.bidId)} */
                          type="button"
                          className="A_weR_tuu"
                        >
                          Take Bid
                        </button>
                      </div>
                    </div>
                  ) : (
                    isBidder && (
                      <div className="salE_RTY_fE">
                        <div className="s_EeRR_ttg_T">
                          <button type="button" className="A_weR_tuu">
                            Cancell
                          </button>
                        </div>
                      </div>
                    )
                  )}
                </div>
              </React.Fragment>
            )
          );
        })
      ) : (
        <React.Fragment>
          <div className="sask-ffg44">
            <div className="sask-fdff44">
              <div className="sals-gfg44jkj">Not Has Bids</div>
            </div>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default BidList;
