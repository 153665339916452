import axios from "axios";

const gatewayApi = `https://api.rarible.org/v0.1/data/collections/ETHEREUM:`;
const gatewayApi2 = `https://api.rarible.org/v0.1/activities/byItem?type=SELL&type=TRANSFER&itemId=ETHEREUM:`;
const gatewayApi3 = `https://api.rarible.org/v0.1/activities/byItem?type=SELL&itemId=ETHEREUM:`;
const gatewayApi4 = `https://api.rarible.org/v0.1/activities/byItem?type=BUY&itemId=ETHEREUM:`;
const gatewayApi5 = `https://api.rarible.org/v0.1/activities/byItem?type=BID&itemId=ETHEREUM:`;

const currencyStat = "/stats?currency=ETH";

const getStats = async (address) => {
  try {
    const response = await axios(`${gatewayApi}${address}${currencyStat}`);
    return response.data;
  } catch (err) {
    console.error(err);
  }
};

const getActivitiesByItem = async (address, tokenId) => {
  const url = `${gatewayApi2}${address}:${tokenId}`;
  try {
    const response = await axios(url);
    return response.data;
  } catch (err) {
    console.error(err);
  }
};

const getSalesByItem = async (address, tokenId) => {
  const url = `${gatewayApi3}${address}:${tokenId}`;
  try {
    const response = await axios(url);
    return response.data;
  } catch (err) {
    console.error(err);
  }
};

const getBuyesByItem = async (address, tokenId) => {
  const url = `${gatewayApi4}${address}:${tokenId}`;
  try {
    const response = await axios(url);
    return response.data;
  } catch (err) {
    console.error(err);
  }
};

const getBidsByItem = async (address, tokenId) => {
  const url = `${gatewayApi5}${address}:${tokenId}`;
  try {
    const response = await axios(url);
    return response.data;
  } catch (err) {
    console.error(err);
  }
};

export {
  getStats,
  getActivitiesByItem,
  getSalesByItem,
  getBuyesByItem,
  getBidsByItem,
};
