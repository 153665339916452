/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import metamask from "../../../Assets/Images/MetaMask Fox.svg";
import trustwallet from "../../../Assets/Images/trust_platform.svg";
import { app } from "../../../Database/Firebase/firebaseConfig";
import { rpcapi } from "../../Web3/RPCChain";
import { handleConnectAccount } from "../../../Utils/sytemConnector";
import { toast } from "react-hot-toast";
import { isTrustWallet, web3Connector } from "../../Web3/web3";

const db = app.firestore();

const ConnectWindow = () => {
  const [wallet, setWallet] = useState("");

  async function getWallet(provider) {
    const web3 = await web3Connector(provider);
    const wallet = await web3.eth.getAccounts();
    if (wallet[0] === undefined) {
    } else {
      const lowerAddress = wallet[0].toLowerCase();
      setWallet(lowerAddress);
      return lowerAddress;
    }
  }

  async function connect(provider) {
    try {
      const data = await provider.request({
        method: "eth_requestAccounts",
      });
      return data[0];
    } catch (err) {
      ////console.log(err);
    }
  }

  const connectWithMetamask = async () => {
    try {
      if (typeof rpcapi === "undefined") {
        toast.error("Metamask is not detected");
        return;
      }

      const account = await getWallet(rpcapi);
      const data = await connect(rpcapi);
      const dbRef = db.collection("users").doc(account);
      const dbColl = await dbRef.get();

      if (
        data.length > 0 &&
        (!dbColl.exists || dbColl.data().isLogin === false)
      ) {
        await handleConnectAccount();
        window.location.reload();
      }
    } catch (err) {
      toast.error("Error connecting MetaMask");
      ////console.log(err);
    }
  };

  /*   const connectWithTrustWallet = async () => {
    try {
      const isTrust = await isTrustWallet(rpcapi);

      if (!isTrust) {
        toast.error("TrustWallet is not detected");
        return;
      }

      if (typeof rpcapi === "undefined") {
        toast.error("TrustWallet is not detected");
        return;
      }

      const account = await getWallet(rpcapi);
      const data = await connect(rpcapi);
      const dbRef = db.collection("users").doc(account);
      const dbColl = await dbRef.get();

      if (data.length > 0) {
        if (dbColl.exists) {
          const { isLogin } = dbColl.data();
          if (isLogin === false) {
            await handleConnectAccount();
            window.location.reload();
          }
        } else {
          await handleConnectAccount();
          window.location.reload();
        }
      }
    } catch (err) {
      toast.error("Error connecting TrustWallet");
      ////console.log(err);
    }
  }; */

  return (
    <div className="ssc1-gfg1y_vrf4">
      <div className="saslk_DFsd11f">
        <div className="dsd_Gfg11">
          <div className="What-sae_SWe">
            <div className="sas_RTF_Yggf">Connect a Wallet</div>
            <div className="asassa-fdf11df">
              <div className="content_gf1" onClick={connectWithMetamask}>
                <div className="D_rT_Yyyty">
                  <img src={metamask} alt="Metamask" className="sas_Rasd" />
                </div>
                <div className="sasr_Trf">Metamask</div>
              </div>
            </div>

            {/* <div className="asassa-fdf11df">
              <div className="content_gf1" onClick={connectWithTrustWallet}>
                <div className="D_rT_Yyyty">
                  <img
                    src={trustwallet}
                    alt="Metamask"
                    className="sas_Rasd woe-ff1r"
                  />
                </div>
                <div className="sasr_Trf">Trust Wallet</div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConnectWindow;
