/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import { useState } from "react";
import {
  executeAuction,
  getDecimals,
  nullAddress,
} from "../../../../Utils/getExchangeData";
import { useParams } from "react-router-dom";
import { toast } from "react-hot-toast";
import { ethers } from "ethers";
import { rpcapi } from "../../../Web3/RPCChain";
import { web3Connector } from "../../../Web3/web3";
import {
  templateAuction,
  templateBid,
  templateOrder,
} from "../../../../Utils/templates";
import { app } from "../../../../Database/Firebase/firebaseConfig";
import DropdownAddress from "../Utils/DropdownAddress/DropdownAddress";
import { erc20Tokens } from "../../../../Utils/AddressAllowed";

const db = app.firestore();

const CreateAuction = ({ isActive, isOwner, isActiveBid, asset }) => {
  const [inputValueAddress, setInputValueAddress] = useState("");
  const [inputValuePrice, setInputValuePrice] = useState();
  const [inputValueEndTime, setInputValueEndTime] = useState();
  const { contract, tokenId } = useParams();
  const [showAuctionButton, setShowAuctionButton] = useState(false);
  const [wallet, setWallet] = useState();
  const [realData, setRealData] = useState([]);

  const addrFormatted = contract.toLowerCase();

  const handleShowAuctionOption = async () => {
    try {
      setShowAuctionButton((prevState) => !prevState);
      const firestoreRef = db
        .collection("buttons")
        .doc(`showAuctionButton:${contract}:${tokenId}:${wallet}`);
      const firestoreDoc = await firestoreRef.get();
      if (firestoreDoc.exists) {
        firestoreRef.update({ showItem: !isActive });
      } else {
        firestoreRef.set({ showItem: !isActive });
      }
    } catch (err) {
      ////console.log(err);
    }
  };

  async function getWallet(provider) {
    const web3 = await web3Connector(provider);
    const wallet = await web3.eth.getAccounts();
    if (wallet[0] === undefined) {
    } else {
      const lowerAddress = wallet[0].toLowerCase();
      return lowerAddress;
    }
  }

  useEffect(() => {
    async function getWallet(provider) {
      const web3 = await web3Connector(provider);
      const wallet = await web3.eth.getAccounts();
      if (wallet[0] === undefined) {
      } else {
        const lowerAddress = wallet[0].toLowerCase();
        setWallet(lowerAddress);
        return lowerAddress;
      }
    }

    getWallet(rpcapi);
  }, []);

  const handleChangePrice = (e) => {
    e.preventDefault();
    const value = parseFloat(e.target.value);
    setInputValuePrice(value);
  };

  /*
   *
   * New Update: New Auction Method
   *
   */

  const [selectedAddress, setSelectedAddress] = useState("");
  const [isERC1155, setIsERC1155] = useState(false);
  const [inputValueAmount, setInputValueAmount] = useState();

  const handleTokenAmount = (e) => {
    e.preventDefault();
    setInputValueAmount(e.target.value);
  };

  const handleAddressSelect = (address) => {
    setSelectedAddress(address);
  };

  useEffect(() => {
    if (asset === "ERC1155") {
      setIsERC1155(true);
    }
  }, [asset]);

  async function checkIfConnect() {
    try {
      const account = await getWallet(rpcapi);
      const dbRef = db.collection("users").doc(account);
      const dbDoc = await dbRef.get();
      if (dbDoc.exists) {
        const { isLogin } = dbDoc.data();
        return isLogin;
      }
    } catch (err) {
      ////console.log(err);
    }
  }

  const handleExecuteAuction = async () => {
    const isLogin = await checkIfConnect();
    if (isLogin) {
      try {
        await showSellModal(false);
        await handleDataTxWindow(true);
        await dataTxWindow(true, false, false);
        const web3 = await web3Connector(rpcapi);
        const account = await getWallet(rpcapi);
        let type;
        let tknAmnt;

        if (asset === "ERC721") {
          type = 0;
          tknAmnt = 1;
        } else if (asset === "ERC1155") {
          type = 1;
          tknAmnt = inputValueAmount;
        }

        let priceFormatted;

        if (selectedAddress !== "") {
          const decimals = await getDecimals(selectedAddress);
          priceFormatted = Number(inputValuePrice * 10 ** decimals).toString();
        } else {
          const newPrice = Number(inputValuePrice).toString();
          priceFormatted = web3.utils.toWei(newPrice, "ether");
        }

        const currentTimestampInSeconds = Math.floor(Date.now() / 1000);

        const execute = await executeAuction(
          "",
          type,
          2,
          contract,
          selectedAddress,
          account,
          nullAddress,
          tokenId,
          priceFormatted,
          0,
          currentTimestampInSeconds,
          0,
          tknAmnt
        );
        await execute.wait();
        if (execute.hash) {
          await sendTx(execute.hash);
          await dataTxWindow(false, true, false);
          toast.success("Auction created");
        }
      } catch (err) {
        await dataTxWindow(false, false, true);
      }
    } else {
      toast.error("Please connect your wallet");
    }
  };

  const showSellModal = async (boolean) => {
    try {
      const ref = db
        .collection("buttons")
        .doc(`showAuctionButton:${contract}:${tokenId}:${wallet}`);
      const doc = await ref.get();
      if (doc.exists) {
        const { showItem } = doc.data();
        if (showItem) {
          await ref.update({
            showItem: boolean,
          });
        }
      }
    } catch (err) {
      ////console.log(err);
    }
  };

  // Window COnfirmation

  async function dataTxWindow(isPending, isSucess, isFail) {
    try {
      const account = await getWallet(rpcapi);
      const ref = db.collection("buttons").doc(`showTxWindow:${account}`);
      const doc = await ref.get();
      if (doc.exists) {
        await ref.update({
          isPending,
          isSucess,
          isFail,
        });
      } else {
        await ref.set({
          isPending,
          isSucess,
          isFail,
        });
      }
    } catch (err) {
      console.log(err);
    }
  }

  async function sendTx(txHash) {
    try {
      const account = await getWallet(rpcapi);
      const ref = db.collection("buttons").doc(`showTxWindow:${account}`);
      const doc = await ref.get();
      if (doc.exists) {
        await ref.update({
          txHash,
        });
      } else {
        await ref.set({
          txHash,
        });
      }
    } catch (err) {
      console.log(err);
    }
  }

  async function handleDataTxWindow(showItem) {
    try {
      const account = await getWallet(rpcapi);
      const ref = db.collection("buttons").doc(`showTxWindow:${account}`);
      const doc = await ref.get();
      if (doc.exists) {
        await ref.update({
          showItem,
        });
      } else {
        await ref.set({
          showItem,
        });
      }
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <React.Fragment>
      {isOwner && (
        <React.Fragment>
          <div className="sask-e552d-saw">
            <div className="sas_Fdlld">
              <div className="sasaw_ERTTds">
                <div className="slW_ERtYF_r">Create Auction</div>
                <div className="as_Erdas">
                  <div className="saik_wer">
                    <DropdownAddress
                      addresses={erc20Tokens}
                      onSelect={handleAddressSelect}
                    />
                  </div>

                  <div className="salk_E85r">
                    Note: please leave in blank if you will not use Token ERC20.
                  </div>

                  <div className="saik_wer">
                    <input
                      className="sh_Err-teew stye_sew1-sw"
                      placeholder="ReservedPrice"
                      onChange={handleChangePrice}
                      value={inputValuePrice}
                      type="number"
                    />
                  </div>

                  {isERC1155 && (
                    <div className="saik_wer">
                      <input
                        placeholder="Token Amount"
                        type="number"
                        className="sas_WEEfd_SEd"
                        onChange={handleTokenAmount}
                        value={inputValueAmount}
                      />
                    </div>
                  )}

                  <div className="aw_swE_Sw">
                    <button
                      className="slA_we4W_k"
                      type="button"
                      onClick={() => handleExecuteAuction()}
                    >
                      Put On Auction
                    </button>
                  </div>
                  <div className="aw_swE_Sw">
                    <button
                      type="button"
                      className="slA_we4W_k W_ert_w"
                      onClick={handleShowAuctionOption}
                    >
                      Cancell
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default CreateAuction;
