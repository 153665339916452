/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import { useState } from "react";
import { EvmChain } from "@moralisweb3/common-evm-utils";
import { getTotalNFTs } from "../../../../Utils/getTotalNFTs";
import NFTCard from "./NFTCard";
import { makeId } from "../../../../Utils/nonceGenerator";
import "./CardBox.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faToggleOn, faToggleOff } from "@fortawesome/free-solid-svg-icons";
import { app } from "../../../../Database/Firebase/firebaseConfig";
import firebase from "firebase/compat/app";
import "firebase/firestore";
import { rpcapi } from "../../../Web3/RPCChain";
import Web3 from "web3";
import TokenSelected from "../Utils/TokenSelected";
import { Link } from "react-router-dom";
import { web3Connector } from "../../../Web3/web3";

const web3 = new Web3(rpcapi);

const CardBox = ({ contract }) => {
  const db = app.firestore();

  // Hooks
  const [nfts, setNfts] = useState([]);
  const [crrCursor, setCrrCursor] = useState(null);
  const [prevCursor, setPrevCursor] = useState(null);
  const [toggleOn, setToggleOn] = useState(false);
  const [tokenSelected, setTokenSelected] = useState([]);
  const [wallet, setWallet] = useState("");
  const [select, setSelect] = useState(false);

  async function getWallet(provider) {
    const web3 = await web3Connector(provider);
    const wallet = await web3.eth.getAccounts();
    if (wallet[0] === undefined) {
    } else {
      const lowerAddress = wallet[0].toLowerCase();
      return lowerAddress;
    }
  }

  //Get Wallet
  useEffect(() => {
    async function getWalletAddress(provider) {
      const web3 = await web3Connector(provider);
      const wallet = await web3.eth.getAccounts();
      if (wallet[0] === undefined) {
      } else {
        const lowerAddress = wallet[0].toLowerCase();
        setWallet(lowerAddress);
        return lowerAddress;
      }
    }

    getWalletAddress(rpcapi);
  }, []);

  /* get All Nfts */
  const getDataColl = async (cursorString, contractAddress) => {
    const dataChain = EvmChain.SEPOLIA;

    const options = {
      address: contractAddress,
      chain: dataChain,
      normalizeMetadata: true,
      cursor: cursorString,
      limit: 40,
    };
    const data = await getTotalNFTs(options);
    const dataCursor = data.cursor;
    const result = data.result;

    if (dataCursor) {
      setCrrCursor(dataCursor);
    }
    if (result) {
      return result;
    }
  };

  useEffect(() => {
    async function storeAddress() {
      try {
        const descRef = db.collection("collection").doc(contract);
        const descDoc = await descRef.get();
        if (descDoc.exists) {
          await descRef.update({
            address: contract,
          });
        }
      } catch (err) {
        ////console.log(err);
      }
    }
    storeAddress();
  }, [contract, db]);

  /*Function to get the nfts using cursor */
  const loadNFTs = async (cursor) => {
    const data = await getDataColl(cursor, contract);
    return data;
  };

  useEffect(() => {
    const getDescription = async () => {
      const options = {
        address: contract,
        chain: EvmChain.SEPOLIA,
        normalizeMetadata: true,
        limit: 1,
      };
      const nftsw = await getTotalNFTs(options);
      const response = nftsw.result;
      const data = response.map((nftData) => {
        const description = nftData.normalized_metadata.description;
        return description;
      });
      const nameColl = response.map((nftData) => {
        const name = nftData.name;
        return name;
      });
      try {
        const descRef = db.collection("collection").doc(contract);
        const descDoc = await descRef.get();

        if (descDoc.exists && data[0]) {
          if (data[0] !== null) {
            const { description, name } = descDoc.data();
            if (!description && !name) {
              await descRef.update({
                description: data[0],
                name: nameColl[0],
              });
            }
          }
        } else {
          if (data[0] !== null) {
            const { description, name } = descDoc.data();
            if (!description && name) {
              await descRef.update({
                description: data[0],
                name: nameColl[0],
              });
            }
          }
        }
      } catch (err) {
        ////console.log(err);
      }
    };
    getDescription();
  }, [contract, db]);

  /* Get Trait */
  useEffect(() => {
    async function getTrait() {
      const response = nfts;
      const data = response.map((nftData) => {
        const attributesString = JSON.stringify(
          nftData.normalized_metadata.attributes
        );
        const attributes = JSON.parse(attributesString);
        return attributes;
      });
      const traitRef = db.collection("trait").doc(contract);
      const traitDoc = await traitRef.get();
      if (!traitDoc.exists) {
        const traitData = {};
        data.forEach((attributes) => {
          attributes.forEach(({ trait_type, value }) => {
            if (!traitData[trait_type]) {
              traitData[trait_type] = new Set();
            }
            traitData[trait_type].add(value);
          });
        });
        const traitDataArray = {};
        Object.entries(traitData).forEach(([trait_type, values]) => {
          traitDataArray[trait_type] = Array.from(values);
        });
        await traitRef.set(traitDataArray);
      } else {
        const existingTraitData = traitDoc.data();
        const updatedTraitData = {};
        data.forEach((attributes) => {
          attributes.forEach(({ trait_type, value }) => {
            if (existingTraitData[trait_type]) {
              if (!existingTraitData[trait_type].includes(value)) {
                if (!updatedTraitData[trait_type]) {
                  updatedTraitData[trait_type] = new Set();
                }
                updatedTraitData[trait_type].add(value);
              }
            } else {
              if (!updatedTraitData[trait_type]) {
                updatedTraitData[trait_type] = new Set();
              }
              updatedTraitData[trait_type].add(value);
            }
          });
        });
        const updatedTraitDataArray = {};
        Object.entries(updatedTraitData).forEach(([trait_type, values]) => {
          updatedTraitDataArray[trait_type] = Array.from(values);
        });
        if (Object.keys(updatedTraitDataArray).length > 0) {
          const batch = db.batch();
          Object.entries(updatedTraitDataArray).forEach(
            ([trait_type, values]) => {
              batch.update(traitRef, {
                [trait_type]: firebase.firestore.FieldValue.arrayUnion(
                  ...values
                ),
              });
            }
          );
          await batch.commit();
        }
      }
    }
    getTrait();
  }, [contract, db, nfts]);

  /*  Handle to charge more NFTs */
  const handleLoadMore = async () => {
    if (crrCursor !== prevCursor) {
      const data = await loadNFTs(crrCursor);
      setPrevCursor(crrCursor);
      setNfts((prevNfts) => [...prevNfts, ...data]);
    }
  };

  /* Hook to fetch all data */
  useEffect(() => {
    const fetchData = async () => {
      const data = await loadNFTs(null);
      setPrevCursor(null);
      setNfts(data);
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contract]);

  /*Handle to scroll and has more items */
  const handleScroll = () => {
    const scrollTop =
      (document.documentElement && document.documentElement.scrollTop) ||
      document.body.scrollTop;
    const scrollHeight =
      (document.documentElement && document.documentElement.scrollHeight) ||
      document.body.scrollHeight;
    const clientHeight =
      document.documentElement.clientHeight || window.innerHeight;
    const scrolledToBottom =
      Math.ceil(scrollTop + clientHeight) >= scrollHeight;

    if (scrolledToBottom) {
      handleLoadMore();
    }
  };

  /* Hooks and sort settings */
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nfts]);

  /*
   *
   * New Updated sorted function
   *
   */

  const [originalNfts, setOriginalNfts] = useState([]);

  const handleSort = () => {
    setOriginalNfts([...nfts]);
    const sortedNfts = [...nfts].sort((a, b) => a.token_id - b.token_id);
    setNfts(sortedNfts);
    setToggleOn(true);
  };

  const handleSortOff = () => {
    const sortedNfts = [...originalNfts];
    setNfts(sortedNfts);
    setToggleOn(false);
  };

  async function addToBuy(token_id, image) {
    try {
      const storeTokenRef = db.collection("buyCar").doc(wallet);
      const storeTokenDoc = await storeTokenRef.get();
      if (storeTokenDoc.exists) {
        const data = storeTokenDoc.data();
        if (data[contract]) {
          if (data[contract].length < 10) {
            // Check if tokenId has already been stored
            if (data[contract].some((obj) => obj.tokenId === token_id)) {
              ////console.log(`Token with ID ${token_id} is already stored`);
            } else {
              const updatedTokens = [
                ...data[contract],
                { tokenId: token_id, image: image },
              ];
              await storeTokenRef.update({ [contract]: updatedTokens });
            }
          } else {
            ////console.log("you can only store 5 tokens to buy");
          }
        } else {
          await storeTokenRef.set({
            [contract]: [{ tokenId: token_id, image: image }],
          });
        }
      } else {
        await storeTokenRef.set({
          [contract]: [{ tokenId: token_id, image: image }],
        });
      }
    } catch (err) {
      ////console.log(err);
    }
  }

  useEffect(() => {
    async function getAllTokenSelected() {
      const account = await getWallet(rpcapi);
      try {
        const storeTokenRef = db.collection("buyCar").doc(account);
        const unsubscribe = storeTokenRef.onSnapshot((token) => {
          let tokens = []; // initialize tokens array
          let tkn = [];
          if (token.exists) {
            const data = token.data();
            if (data[contract] && data[contract].length <= 10) {
              // check if contract array exists
              for (let i = 0; i < data[contract].length; i++) {
                tokens[i] = {
                  tokenId: data[contract][i].tokenId,
                  image: data[contract][i].image,
                };
              }
            }
          }
          setTokenSelected(tokens);
        });

        unsubscribe();
      } catch (err) {
        ////console.log(err);
      }
    }
    getAllTokenSelected();
  }, [contract, db]);

  async function handleTokenId(token_id, image) {
    if (select === true) {
      try {
        // Call addToBuy function to add token ID
        await addToBuy(token_id, image);
      } catch (err) {
        // Log error message if there's an error
        ////console.log(`Error adding token ID ${token_id}: ${err}`);
      }
    }
  }

  function handleLength() {
    if (select === true) {
      if (tokenSelected.length <= 5 && tokenSelected.length > 0) {
        // show the "BUY ALL" button and the .sawhh-y div
        return (
          <div className="sawhh-y">
            <div className="soakkaw">
              <div className="akakaj">
                {tokenSelected.map((data) => (
                  <div key={makeId(10)} className="sae">
                    <div className="slk">
                      <TokenSelected
                        tokens={data.tokenId}
                        image={data.image}
                        contract={contract}
                      />
                    </div>
                  </div>
                ))}
              </div>
              <a className="sweyuu">BUY ALL</a>
            </div>
          </div>
        );
      } else {
        // hide the "BUY ALL" button and the .sawhh-y div
        return null;
      }
    }
  }

  const handleSelect = () => {
    setSelect(true);
  };

  const handleUnSelect = () => {
    setSelect(false);
  };

  return (
    <div key={contract} className="sawnnj">
      <div className="saslas-re-tdsd">
        <div className="sujuk_ERTh-d_DF">
          <div className="iki-uju">
            <div className="iky-syj">
              <div className="sasol">Sort by TokenID</div>
              <FontAwesomeIcon
                onClick={toggleOn ? handleSortOff : handleSort}
                icon={toggleOn ? faToggleOn : faToggleOff}
              />
            </div>
          </div>
          {/* <div className="iki-uju">
            <div className="iky-syj">
              <div className="sasol">Select token</div>
              <FontAwesomeIcon
                onClick={select ? handleUnSelect : handleSelect}
                icon={select ? faToggleOn : faToggleOff}
              />
            </div>
          </div> */}
        </div>
      </div>
      <div className="sik-erty">
        <div className="iksa-ert">
          <div className="lka">
            {nfts.map((nft) => (
              <div key={makeId(10)} className="liks-sle">
                <div className="sa-hj">
                  <div className="jj-awe">
                    {select ? (
                      <div
                        onClick={() =>
                          handleTokenId(
                            nft.token_id,
                            nft.normalized_metadata.image
                          )
                        }
                        className="sal"
                      >
                        <NFTCard
                          contract={contract}
                          keyPass={makeId(10)}
                          image={nft.normalized_metadata.image}
                          tokenId={nft.token_id}
                        />
                      </div>
                    ) : (
                      <React.Fragment>
                        <div className="sawAS_ER">
                          <NFTCard
                            contract={contract}
                            keyPass={makeId(10)}
                            image={nft.normalized_metadata.image}
                            tokenId={nft.token_id}
                            owner={nft.owner_of}
                          />
                        </div>
                      </React.Fragment>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        {handleLength()}
      </div>
    </div>
  );
};

export default React.memo(CardBox);
