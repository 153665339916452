import axios from "axios";
import Moralis from "moralis/.";

const gatewayApi = `https://api.rarible.org/v0.1/items/ETHEREUM:`;

const apiKey1 =
  "ghyb0bAh3B5Uyl0AgLT11JKgHVKT7CmmkfXiMwCc0UQoCPdXe3UcUcKuFOVskQNF";

const apiKey2 =
  "bhRpoD9unRtOMKHQ03MiHZnx6WoMbiV5Da33ejpCsPs7f4HOQhzNUd1zLp3TITeT";

const getItem = async (address, tokenId) => {
  try {
    const url = `${gatewayApi}${address}:${tokenId}`;
    const response = await axios(url);
    return response.data;
  } catch (err) {
    console.error(err);
  }
};

const apiGateway2 = "https://api.rarible.org/v0.1/items/ETHEREUM:";

const endApi = "resetMeta?sync=";

const syncMetadata = async (address, tokenId) => {
  const url = `${apiGateway2}${address}:${tokenId}/${endApi}${true}`;
  try {
    const response = await axios.delete(url);
    return response.data;
  } catch (err) {
    console.error("Error in syncMetadata:", err);
  }
};

const getItemByOwner = async (options) => {
  let response;
  try {
    const apiKey = apiKey1;
    if (!Moralis.Core.isStarted) {
      Moralis.start({
        apiKey,
      });
    }
    response = await Moralis.EvmApi.nft.getWalletNFTs(options);
    if (!response) {
      const apiKey = apiKey2;
      if (!Moralis.Core.isStarted) {
        Moralis.start({
          apiKey,
        });
      }
      response = await Moralis.EvmApi.nft.getWalletNFTs(options);
    }

    return response.toJSON();
  } catch (err) {
    console.error(err);
  }
};

const getNftById = async (options) => {
  let response;
  try {
    const apiKey = apiKey1;
    if (!Moralis.Core.isStarted) {
      Moralis.start({
        apiKey,
      });
    }
    response = await Moralis.EvmApi.nft.getNFTMetadata(options);
    if (!response) {
      const apiKey = apiKey2;
      if (!Moralis.Core.isStarted) {
        Moralis.start({
          apiKey,
        });
      }
      response = await Moralis.EvmApi.nft.getNFTMetadata(options);
    }

    return response.toJSON();
  } catch (err) {
    console.error(err);
  }
};

const apigateway =
  "https://api.rarible.org/v0.1/ownerships/byItem?itemId=ETHEREUM:";

const getOwnerShipByItem = async (address, tokenId) => {
  const url = `${apigateway}${address}:${tokenId}`;
  try {
    const response = await axios(url);
    return response.data;
  } catch (err) {
    console.error(Error("Error In GetOwnershipByItem: " + err));
  }
};

export {
  getItem,
  getOwnerShipByItem,
  getItemByOwner,
  getNftById,
  syncMetadata,
};
