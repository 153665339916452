/* eslint-disable no-unused-vars */
import React from "react";
import { useParams } from "react-router-dom";
import { web3Connector } from "../../../Web3/web3";
import { rpcapi } from "../../../Web3/RPCChain";
import {
  erc20Symbol,
  executeAuction,
  executeBid,
  executeSellOrCancel,
  getAllBidsByTokenId,
  getDecimals,
  getExpirationTime,
  nullAddress,
} from "../../../../Utils/getExchangeData";
import { BigNumber, ethers } from "ethers";
import { app } from "../../../../Database/Firebase/firebaseConfig";
import { toast } from "react-hot-toast";
import { useState } from "react";
import { useEffect } from "react";
import { getCurrentEthPrice } from "../../../../Utils/accountStats";
import eth from "../../../../Assets/Images/jhj.png";
import {
  convertTimestampToDate,
  getElapsedTime,
} from "../../../../Utils/getTimeElapsed";
import AuctionTimeElapsed from "./AuctionTimeElapsed";
import InfoAccordion from "./InfoDropdown";
import AuctionBidList from "./AuctionBidList";
import { templateAuctionBids } from "../../../../Utils/Templates/templates";
import { erc20Tokens } from "../../../../Utils/AddressAllowed";

const db = app.firestore();

const OrderData = ({
  asset,
  orderHash,
  seller,
  paymentToken,
  price,
  expirationTime,
  isOwner,
  buyer,
  tokenAmount,
  startTime,
  highestBidder,
  highestBid,
  inAuction,
  rescueBid,
}) => {
  const { contract, tokenId } = useParams();
  const [typeResult, setTypeResult] = useState();
  const [newPrice, setNewPrice] = useState(0);
  const [priceInUSD, setPriceInUSD] = useState(0);
  const [time, setTime] = useState();
  const [isZero, setIsZero] = useState(false);

  const addrFormatted = contract.toLowerCase();

  async function getWallet(provider) {
    const web3 = await web3Connector(provider);
    const wallet = await web3.eth.getAccounts();
    if (wallet[0] === undefined) {
    } else {
      const lowerAddress = wallet[0].toLowerCase();
      return lowerAddress;
    }
  }

  /*
   * New Update: New smart contract, new methods
   * Date: 17 August, 2023
   *
   * Uranus - Auction and cancel Orders
   */

  const [bid, setBid] = useState();
  const [auctionBids, setAuctionBids] = useState([]);
  const [isStarted, setIsStarted] = useState(false);
  const [currentBid, setCurrentBid] = useState();
  const [newEndTime, setNewEndTime] = useState();
  const [isEnded, setIsEnded] = useState(false);
  const [symbol, setSymbol] = useState();
  const [isToken, setIsToken] = useState(false);

  useEffect(() => {
    async function getBids() {
      try {
        const response = await getAllBidsByTokenId(tokenId, orderHash);
        const result = await Promise.all(
          response.map(async (item) => {
            const bidData = await templateAuctionBids(item.args);
            const web3 = await web3Connector(rpcapi);

            return {
              ...bidData,
              highestBid: web3.utils.fromWei(bidData.highestBid, "ether"),
            };
          })
        );

        setAuctionBids(result);
        setCurrentBid(result[0].highestBid);
        if (result[0].highestBid !== 0) {
          setIsStarted(true);
        }

        ////console.log(result);
      } catch (err) {
        ////console.log(err.message);
      }
    }
    getBids();
  }, [tokenId, orderHash]);

  async function checkIfConnect() {
    try {
      const account = await getWallet(rpcapi);
      const dbRef = db.collection("users").doc(account);
      const dbDoc = await dbRef.get();
      if (dbDoc.exists) {
        const { isLogin } = dbDoc.data();
        return isLogin;
      }
    } catch (err) {
      ////console.log(err);
    }
  }

  const handleInputBidValue = (e) => {
    e.preventDefault();
    const value = parseFloat(e.target.value);
    setBid(value);
  };

  const handleExecuteCancel = async (action) => {
    const isLogin = await checkIfConnect();
    if (isLogin) {
      try {
        await handleDataTxWindow(true);
        await dataTxWindow(true, false, false);
        const execute = await executeSellOrCancel(
          orderHash,
          asset,
          action,
          contract,
          paymentToken,
          seller,
          nullAddress,
          tokenId,
          price,
          expirationTime,
          tokenAmount
        );
        await execute.wait();

        if (execute.hash) {
          await sendTx(execute.hash);
          await dataTxWindow(false, true, false);
          toast.success("Order canceled");
        }
      } catch (err) {
        ////console.log(err);
        await dataTxWindow(false, false, true);
      }
    } else {
      toast.error("Please connect your wallet");
    }
  };

  const handleExecuteAuction = async (action) => {
    const isLogin = await checkIfConnect();
    if (isLogin) {
      try {
        await handleDataTxWindow(true);
        await dataTxWindow(true, false, false);

        const execute = await executeAuction(
          orderHash,
          asset,
          action,
          contract,
          paymentToken,
          seller,
          nullAddress,
          tokenId,
          price,
          highestBid,
          startTime,
          expirationTime,
          tokenAmount
        );
        await execute.wait();
        if (execute.hash) {
          await sendTx(execute.hash);
          await dataTxWindow(false, true, false);
          if (action === 5) {
            toast.success("Auction Canceled");
          } else {
            toast.success("Auction Created");
          }
        }
      } catch (err) {
        await dataTxWindow(false, false, true);
      }
    } else {
      toast.error("Please connect your wallet");
    }
  };

  const handleExecuteBid = async (action) => {
    const isLogin = await checkIfConnect();
    if (isLogin) {
      try {
        await handleDataTxWindow(true);
        await dataTxWindow(true, false, false);
        const account = await getWallet(rpcapi);
        const web3 = await web3Connector(rpcapi);

        let priceFormatted;
        let execute;

        if (paymentToken !== nullAddress) {
          const decimals = await getDecimals(paymentToken);
          priceFormatted = Number(bid * 10 ** decimals).toString();
        } else {
          const newPrice = Number(bid).toString();
          ////console.log(newPrice);
          priceFormatted = web3.utils.toWei(newPrice, "ether");
        }

        ////console.log(Number(priceFormatted, currentBid));

        const currentBidInEther = Number(currentBid).toString();
        const currentBidInWei = web3.utils.toWei(currentBidInEther, "ether");

        if (
          Number(priceFormatted > price) ||
          Number(priceFormatted > currentBidInWei)
        ) {
          execute = await executeBid(
            orderHash,
            asset,
            action,
            contract,
            paymentToken,
            seller,
            account,
            tokenId,
            price,
            priceFormatted,
            startTime,
            newEndTime,
            tokenAmount
          );
        } else {
          toast.error("Invalid New Bid");
        }
        await execute.wait();

        if (execute.hash) {
          await sendTx(execute.hash);
          await dataTxWindow(false, true, false);

          toast.success("New Bid Placed");
        }
      } catch (err) {
        await dataTxWindow(false, false, true);
      }
    } else {
      toast.error("Please connect your wallet");
    }
  };

  useEffect(() => {
    async function getPrice() {
      try {
        if (paymentToken === nullAddress) {
          if (inAuction) {
            const web3 = await web3Connector(rpcapi);
            const priceEther = web3.utils.fromWei(price, "ether");
            setNewPrice(Number(priceEther).toFixed(4));
          } else {
            const web3 = await web3Connector(rpcapi);
            const priceEther = web3.utils.fromWei(price, "ether");
            const etherPrice = await getCurrentEthPrice();
            const usdPrice = priceEther * etherPrice;
            setNewPrice(priceEther);
            setPriceInUSD(usdPrice);
            ////console.log(priceEther, usdPrice);
          }
        } else {
          const decimals = await getDecimals(paymentToken);
          const priceInToken = (Number(price) / Math.pow(10, decimals)).toFixed(
            4
          );
          setNewPrice(priceInToken);
        }
      } catch (err) {
        ////console.log(err);
      }
    }
    getPrice();
  }, [price, paymentToken, inAuction]);

  useEffect(() => {
    async function endIn() {
      try {
        const time = await getExpirationTime(orderHash);
        setNewEndTime(time);
        ////console.log(time);
      } catch (err) {}
    }
    endIn();
  }, [orderHash]);

  useEffect(() => {
    const currentTimestampInSeconds = Math.floor(Date.now() / 1000); // Returns the current timestamp in seconds
    if (newEndTime < currentTimestampInSeconds) {
      setIsEnded(true);
    }
  }, [newEndTime]);

  useEffect(() => {
    async function returnSymbol() {
      try {
        if (paymentToken !== nullAddress) {
          const matchingToken = erc20Tokens.find(
            (token) =>
              token.address.toLowerCase() === paymentToken.toLowerCase()
          );

          if (matchingToken) {
            setIsToken(true);
            setSymbol(matchingToken.logo);
          }
        }
      } catch (err) {
        console.error(err.message);
      }
    }

    returnSymbol();
  }, [paymentToken]);

  console.log(symbol);

  const [wallet, setWallet] = useState();

  async function dataTxWindow(isPending, isSucess, isFail) {
    try {
      const account = await getWallet(rpcapi);
      const ref = db.collection("buttons").doc(`showTxWindow:${account}`);
      const doc = await ref.get();
      if (doc.exists) {
        await ref.update({
          isPending,
          isSucess,
          isFail,
        });
      } else {
        await ref.set({
          isPending,
          isSucess,
          isFail,
        });
      }
    } catch (err) {
      console.log(err);
    }
  }

  async function handleDataTxWindow(showItem) {
    try {
      const account = await getWallet(rpcapi);
      const ref = db.collection("buttons").doc(`showTxWindow:${account}`);
      const doc = await ref.get();
      if (doc.exists) {
        await ref.update({
          showItem,
        });
      } else {
        await ref.set({
          showItem,
        });
      }
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    async function getWalletAddress(provider) {
      const web3 = await web3Connector(provider);
      const wallet = await web3.eth.getAccounts();
      if (wallet[0] === undefined) {
      } else {
        const lowerAddress = wallet[0].toLowerCase();
        setWallet(lowerAddress);
        return lowerAddress;
      }
    }

    getWalletAddress(rpcapi);
  }, []);

  async function sendTx(txHash) {
    try {
      const account = await getWallet(rpcapi);
      const ref = db.collection("buttons").doc(`showTxWindow:${account}`);
      const doc = await ref.get();
      if (doc.exists) {
        await ref.update({
          txHash,
        });
      } else {
        await ref.set({
          txHash,
        });
      }
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <React.Fragment>
      <div className="wW_erT_AS">
        <div className="sW_sWAW-eS">
          <div className="s_wWE_RrT_s4">
            <div className="saW_WeEE_RtD">
              <div className="sA_eeR_TttERW">
                <div className="aS_wWE_rTYYUu">
                  <div className="saWW_ereRDS_S">
                    <div className="S_A_Type_Order">{typeResult}</div>
                    {inAuction ? (
                      <React.Fragment>
                        <div className="sW_erT_Price_SW">
                          <div className="saS_WWERT_HBGfgf_GY">
                            Reserved Price:{" "}
                            <div className="sawwe_dsE_RERTFdf">
                              {newPrice} {symbol}
                            </div>
                          </div>
                          <div className="sas_EERrtTGgGHJHJGG_FDFED4">
                            <hr className="sasWW_EddFG_GHGgfdfd" />
                          </div>

                          <div className="saW_RRTFD_DSdEED">
                            <div className="s_saA_SWsr">Current Bid:</div>
                            <div className="saW_EEE_RTdS">
                              <div className="s_WeR_YuuyT">
                                <div
                                  className={`sA_WErr_Tyu ${
                                    symbol && "saS_wERTTYTY_GfDF_YTY"
                                  } `}
                                >
                                  {isToken ? (
                                    <img
                                      src={symbol}
                                      alt="ETH"
                                      className="salW_reT_f"
                                    />
                                  ) : (
                                    <img
                                      src={eth}
                                      alt="ETH"
                                      className="salW_reT_f"
                                    />
                                  )}
                                </div>
                                <div className="sW_E_e_RtY">{currentBid}</div>
                              </div>
                              <div className="sas_EERTGG_Ffg_G">
                                <div className="sW_erTTUuKH_dre">
                                  (${Number(priceInUSD).toFixed(2)})
                                </div>
                                <div className="sas_WWEFfdF_YYhf">
                                  <div className="sa_EEBOsX_s">
                                    <input
                                      onChange={handleInputBidValue}
                                      value={bid}
                                      placeholder="Bid"
                                      type="number"
                                      className="sasWWE_GVgfV_Ddf"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </React.Fragment>
                    ) : (
                      <div className="sW_erT_Price_SW">
                        <div className="saW_EEE_RTdS">
                          <div className="s_WeR_YuuyT">
                            <div className="sA_WErr_Tyu">
                              {isToken ? (
                                <img
                                  src={symbol}
                                  alt="ETH"
                                  className="salW_reT_f"
                                />
                              ) : (
                                <img
                                  src={eth}
                                  alt="ETH"
                                  className="salW_reT_f"
                                />
                              )}
                            </div>
                            <div className="sW_E_e_RtY">{newPrice}</div>
                          </div>
                          <div className="sW_erTTUuKH_dre">
                            (${Number(priceInUSD).toFixed(2)})
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {isOwner ? (
                inAuction ? (
                  <React.Fragment>
                    <div className="A_wWW_eRTRtyyg_">
                      <div className="sas44d-fgh">
                        <button
                          onClick={() => handleExecuteAuction(5)}
                          type="button"
                          className="sal11ff-rrt4-54 ksA_wws2"
                        >
                          Cancel Auction
                        </button>
                      </div>
                    </div>
                  </React.Fragment>
                ) : (
                  <div className="A_wWW_eRTRtyyg_">
                    <div className="sas44d-fgh">
                      <button
                        onClick={() => handleExecuteCancel(5)}
                        type="button"
                        className="sal11ff-rrt4-54 ksA_wws2"
                      >
                        Cancel Order
                      </button>
                    </div>
                  </div>
                )
              ) : inAuction ? (
                isStarted ? (
                  <React.Fragment>
                    {/*                     {auctionBids.map((data) => ( */}
                    <div className="saslEE_RRCfDDSFGH_fd">
                      <div className="sasE_errT_GdffgHJ_s">
                        <InfoAccordion
                          title={"Bids"}
                          info={
                            <AuctionBidList
                              bids={auctionBids}
                              reservedPrice={newPrice}
                              symbol={symbol}
                              isToken={isToken}
                            />
                          }
                        />
                      </div>
                    </div>
                    {/*                     ))} */}
                    {isEnded ? (
                      <div className="A_wWW_eRTRtyyg_">
                        <div className="sas44d-fgh">
                          <button
                            onClick={() => handleExecuteAuction(4)}
                            type="button"
                            className="sal11ff-rrt4-54 ksA_wws2"
                          >
                            Claim Auction
                          </button>
                        </div>
                      </div>
                    ) : (
                      <div className="A_wWW_eRTRtyyg_">
                        <div className="sas44d-fgh">
                          <button
                            onClick={() => handleExecuteBid(3)}
                            type="button"
                            className="sal11ff-rrt4-54 ksA_wws2"
                          >
                            Place A Bid
                          </button>
                        </div>
                      </div>
                    )}
                    {!isZero && (
                      <div className="saW_eT_ListSWE">
                        <div className="sSW_ErRF_Dssew">
                          <div className="sW_aaW_ERTR">
                            <div className="sAW_rTRTYY_gFGRerE_s">End In:</div>
                            <AuctionTimeElapsed startDate={newEndTime} />
                          </div>
                        </div>
                      </div>
                    )}
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <div className="A_wWW_eRTRtyyg_">
                      <div className="sas44d-fgh">
                        <button
                          onClick={() => handleExecuteBid(3)}
                          type="button"
                          className="sal11ff-rrt4-54 ksA_wws2"
                        >
                          Start Auction
                        </button>
                      </div>
                    </div>
                    {isZero && (
                      <div className="saW_eT_ListSWE">
                        <div className="sSW_ErRF_Dssew">
                          <div className="sW_aaW_ERTR">
                            <div className="saWW_ERTfgGG_H">
                              Auction has not started
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </React.Fragment>
                )
              ) : (
                <div className="A_wWW_eRTRtyyg_">
                  <div className="sas44d-fgh">
                    <button
                      /*  onClick={handleBuyERC721} */
                      type="button"
                      className="sal11ff-rrt4-54 ksA_wws2"
                    >
                      BUY
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default OrderData;
