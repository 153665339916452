/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable no-self-compare */
import React from "react";
import "../../../../Styles/tokenSelected.css";
import "firebase/firestore";
import { app } from "../../../../Database/Firebase/firebaseConfig";
import { useState } from "react";
import Web3 from "web3";
import { rpcapi } from "../../../Web3/RPCChain";
import { useEffect } from "react";

const web3 = new Web3(new Web3(rpcapi));

const TokenSelected = ({ tokens, image, contract }) => {
  // eslint-disable-next-line no-unused-vars
  const [wallet, setWallet] = useState("");

  const db = app.firestore();
  //Get Wallet
  async function getWallet() {
    const wallet = await web3.eth.getAccounts();
    if (wallet[0] === undefined) {
    } else {
      const lowerAddress = wallet[0].toLowerCase();
      setWallet(lowerAddress);
      return lowerAddress;
    }
  }

  useEffect(() => {
    getWallet();
  }, []);

  async function deleteToken(tokenId) {
    const account = await getWallet();
    const tokenRef = db.collection("buyCar").doc(account);
    const tokenDoc = await tokenRef.get();

    if (tokenDoc.exists) {
      const data = tokenDoc.data();
      if (data[contract]) {
        let tokenIndex = -1;
        for (let i = 0; i < data[contract].length; i++) {
          if (data[contract][i].tokenId === tokenId) {
            tokenIndex = i;
            break;
          }
        }
        if (tokenIndex >= 0) {
          const updatedTokens = [...data[contract]];
          updatedTokens.splice(tokenIndex, 1);
          await tokenRef.update({ [contract]: updatedTokens });
        } else {
          ////console.log(`Token with ID ${tokenId} not found`);
        }
      } else {
        ////console.log(`No tokens found in contract ${contract}`);
      }
    } else {
      ////console.log(`Document not found for wallet ${wallet}`);
    }
  }

  return (
    <div className="swe">
      <div>
        <div className="kfkfk">
          <div className="sswer">
            <img className="sagg" src={image} alt={tokens} />
            <p className="jjusj">Token: {tokens}</p>
          </div>
          <div className="sasyy">
            <a onClick={() => deleteToken(tokens)} className="swerr">
              X
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TokenSelected;
