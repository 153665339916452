import React from "react";
import {
  abbreviateNumber,
  calculatePercentage,
  formatNumber,
} from "../../../../Utils/NumberAbreviation";
import logo from "../../../../Assets/Images/jhj.png";
import "../../../../Styles/Stats.css";

const Stats = ({ floor, volume, items, listed, highestSale, owners }) => {
  const fxNum = (num) => {
    if (num >= 10000) {
      const numFixed = abbreviateNumber(Number(num).toFixed(0));
      return numFixed;
    } else if (num < 10000) {
      const numFixed = formatNumber(Number(num).toFixed(2));
      return numFixed;
    } else {
      return num;
    }
  };

  const fxNumOwners = (num) => {
    if (num >= 1000) {
      const numFixed = abbreviateNumber(num.toFixed(0));
      return numFixed;
    } else if (num < 1000) {
      const numFixed = formatNumber(num);
      return numFixed;
    } else {
      return num;
    }
  };

  const totalVolume = fxNum(volume);

  const topSale = fxNum(highestSale);

  const percentage = calculatePercentage(listed, items);

  const numMed = fxNumOwners(items);
  const numOwners = fxNumOwners(owners);
  const floorPrice = fxNum(floor);

  return (
    <div className="isk-llka">
      <div className="kkiuy">
        <div className="uyyt-a">
          <div className="kjy-hyj">
            <div className="lik-saaa">
              <div className="lkk-kkj">
                <div className="osl">
                  <p className="liks-ss">Floor</p>
                  <div className="sjss-suju">
                    <img src={logo} alt="currency" className="sjss-sujus" />
                    <p className="liks-ss">{floorPrice && floorPrice}</p>
                  </div>
                </div>
                <div className="osl">
                  <p className="liks-ss">Top Sale</p>
                  <div className="sjss-suju">
                    <img src={logo} alt="currency" className="sjss-sujus" />
                    <p className="liks-ss">{topSale && topSale}</p>
                  </div>
                </div>
                <div className="osl">
                  <p className="liks-ss">Listed</p>
                  <div className="sjss-suju">
                    <p className="liks-ss">
                      {percentage && percentage.toFixed(2)}%
                    </p>
                  </div>
                </div>
                <div className="osl">
                  <p className="liks-ss">Items</p>
                  <div className="sjss-suju">
                    <p className="liks-ss">{numMed && numMed}</p>
                  </div>
                </div>
                <div className="osl">
                  <p className="liks-ss">Owners</p>
                  <div className="sjss-suju">
                    <p className="liks-ss">{numOwners && numOwners}</p>
                  </div>
                </div>
                <div className="osl">
                  <p className="liks-ss">Total Volume</p>
                  <div className="sjss-suju">
                    <img src={logo} alt="currency" className="sjss-sujus" />
                    <p className="liks-ss">{totalVolume && totalVolume}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Stats;
