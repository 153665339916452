/* eslint-disable no-unused-vars */
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { app } from "../../../Database/Firebase/firebaseConfig";
import Banner from "./NewHeaders/Banner";
import Cover from "./NewHeaders/Cover";
import "./NewCollectionPage.css";
import "boxicons";
import {} from "@quicknode/icy-nft-hooks";
import Web3 from "web3";
import { rpcapi } from "../../Web3/RPCChain";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil, faBars } from "@fortawesome/free-solid-svg-icons";
import DropdownPoint from "./Utils/DropdownPoint";
import etherscan from "../../../Assets/Images/etherscan-logo-circle.svg";
import ShareButton from "./Utils/ShareButton";
import FavoriteButton from "./Utils/FavoriteWL";
import Stats from "./NewHeaders/Stats";
import { getStats } from "../../../Utils/getStats";
import CardBox from "./BodyCollection/CardBox";
import ShowMore from "./Utils/TextTruncated";
import PolarysSDK from "../../../Utils/SDKPolarysNFTs/src";
import EvmChain from "../../../Utils/SDKPolarysNFTs/lib/EvmChain";
import { web3Connector } from "../../Web3/web3";

const web3 = new Web3(rpcapi);

const NewCollectionPage = () => {
  //Hooks
  const [banner, setBanner] = useState("");
  const [coverImage, setCoverImage] = useState("");
  const [nameCollection, setNameCollection] = useState("");
  const [isBlueCheck, setIsBlueCheck] = useState("");
  const [edit, setEdit] = useState();
  const [claimColl, setClaimColl] = useState();
  const [userAddress, setUserAddress] = useState();
  const [crrFloorPrice, setFloorPrice] = useState(0);
  const [crrHighestSales, setHighestSales] = useState(0);
  const [crrVolume, setVolume] = useState(0);
  const [crrItems, setItems] = useState(0);
  const [crrOwners, setOwners] = useState(0);
  const [crrListed, setListed] = useState(0);
  const [description, setDescription] = useState("");
  const [wallet, setWallet] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, []);

  const apiKey = "polar-663ade85-ad80-4c24-bb4b-5352c455bcf1-afJq1gMJNFS2uuU";

  const handleToggleDropdown = () => {
    setIsOpen(!isOpen); // toggle the state of the dropdown
  };

  const db = app.firestore();

  const { contract } = useParams(); //Get Adress Contract from Collection URI
  const gateway = "https://ipfs.io/ipfs/"; //Gateway Image
  const bannerNoPut = "QmRLFx8ACZUy3q9yEihAUissWQEh3pKek7qAoZNRbXsTwm";
  const noCover = "QmaoRr4Rdgrnod7Hs6ULZVJibzH2xmLq4fGySR3Tg2Dfzj";
  const etherscanGateway = "https://sepolia.etherscan.io/address/";

  //Obtener Cuentas desde rpcapi

  useEffect(() => {
    const polarys = new PolarysSDK(apiKey);

    const options = {
      tokenAddress: contract,
      chain: EvmChain.ETHEREUM,
    };
    async function verifiedCollection() {
      const owner = await polarys.getContractOwner(options);
      const ownerColl = owner && owner.toLowerCase();
      const collOwnerAddr = db.collection("collection").doc(contract);
      const collOwnerAddrRef = await collOwnerAddr.get();
      const account = await getWallet(rpcapi);
      const isLogin = db.collection("users").doc(account);
      try {
        if (ownerColl === account) {
          if (collOwnerAddrRef.exists) {
            const { owner, verified, signature } = collOwnerAddrRef.data();
            if (ownerColl === account && !owner && !verified && !signature) {
              const claim = <div className="lkks">Claim Collection</div>;
              setClaimColl(claim);
            } else if (
              ownerColl === account &&
              owner &&
              verified &&
              signature
            ) {
              const edit = (
                <div className="lkks">
                  <FontAwesomeIcon className="sae-ff" icon={faPencil} />
                  <p className="ass ess">Edit Collection</p>
                </div>
              );
              setEdit(edit);
            } else if (
              ownerColl === account &&
              !owner &&
              verified &&
              !signature
            ) {
              const claim = <div className="lkks">Claim Collection</div>;
              setClaimColl(claim);
            }
          }
        }
      } catch (err) {}
    }
    verifiedCollection();
  }, [contract, db]);

  //getStats

  useEffect(() => {
    const stats = async () => {
      //Store in database
      const statRef = db.collection("stats").doc(contract);
      const statDoc = await statRef.get();
      const statsDocRef = statRef.onSnapshot((data) => {
        if (data.exists) {
          const { floorPrice, volume, owners, items, listed, highestSale } =
            data.data();
          setFloorPrice(floorPrice);
          setHighestSales(highestSale);
          setItems(items);
          setListed(listed);
          setOwners(owners);
          setVolume(volume);
        }
      });

      //GetData
      let statMp = [];
      const statsCrr = await getStats(contract);
      if (!statDoc.exists) {
        if (statsCrr) {
          await statRef.set({
            floorPrice: statsCrr.floorPrice,
            owners: statsCrr.owners,
            highestSale: statsCrr.highestSale,
            items: statsCrr.items,
            listed: statsCrr.listed,
            volume: statsCrr.volume.toFixed(2),
          });
        }
      } else {
        if (statsCrr) {
          await statRef.update({
            floorPrice: statsCrr.floorPrice,
            owners: statsCrr.owners,
            highestSale: statsCrr.highestSale,
            items: statsCrr.items,
            listed: statsCrr.listed,
            volume: statsCrr.volume.toFixed(2),
          });
        }
      }

      return () => statsDocRef();
    };
    stats();
  }, [contract, db]);

  useEffect(() => {
    //Check data in real time
    async function checkConnection() {
      const account = await getWallet(rpcapi);
      const unsuscribe = db
        .collection("users")
        .doc(account)
        .onSnapshot((data) => {
          if (data.exists) {
            const { isLogin } = data.data();
            if (!isLogin) {
              setUserAddress("");
            } else {
              setUserAddress(account);
            }
          }
        });
      return () => unsuscribe();
    }
    checkConnection();
  }, [db]);

  //Get Wallet
  async function getWallet(provider) {
    const web3 = await web3Connector(provider);
    const wallet = await web3.eth.getAccounts();
    if (wallet[0] === undefined) {
    } else {
      const lowerAddress = wallet[0].toLowerCase();
      return lowerAddress;
    }
  }

  useEffect(() => {
    async function getDataCollection() {
      try {
        const collectionRef = db.collection("collection").doc(contract);

        const unsubscribe = collectionRef.onSnapshot((doc) => {
          if (doc.exists) {
            try {
              const { description, name } = doc.data();
              setDescription(description);
              setNameCollection(name);
            } catch (err) {
              ////console.log(err);
            }
          }
        });

        // Call unsubscribe when you're ready to stop listening for changes
        return () => unsubscribe();
      } catch (err) {
        ////console.log(err);
      }
    }
    getDataCollection();
  }, [contract, db]);

  useEffect(() => {
    //CheckIsVerified
    const checkIfVerified = async () => {
      const collectionRef = db.collection("collection").doc(contract);
      const collectionDoc = await collectionRef.get();
      try {
        if (collectionDoc.exists) {
          const { verified } = collectionDoc.data();
          if (verified) {
            const isBlueCheck = (
              <box-icon name="badge-check" type="solid" color="#465ee8" />
            );
            setIsBlueCheck(isBlueCheck);
          }
        }
      } catch (err) {
        ////console.log(err);
      }
    };
    checkIfVerified();
  }, [contract, db]);

  useEffect(() => {
    async function getBanner() {
      const collectionRef = db.collection("collection").doc(contract);
      const collectionDoc = await collectionRef.get();
      try {
        if (collectionDoc.exists) {
          const { banner, cover } = collectionDoc.data();
          if (banner && cover) {
            const image = `${gateway}${banner}`;
            setBanner(image);
            const imageCover = `${gateway}${cover}`;
            setCoverImage(imageCover);
          } else if (banner) {
            const image = `${gateway}${banner}`;
            setBanner(image);
            const imageCover = `${gateway}${noCover}`;
            setCoverImage(imageCover);
          } else if (cover) {
            if (cover.startsWith("https://")) {
              // Fix typo: startsWith instead of startWith
              const image = `${gateway}${bannerNoPut}`;
              setBanner(image);
              const imageCover = cover;
              setCoverImage(imageCover);
            } else {
              const image = `${gateway}${bannerNoPut}`;
              setBanner(image);
              const imageCover = `${gateway}${cover}`;
              setCoverImage(imageCover);
            }
          } else {
            const image = `${gateway}${bannerNoPut}`;
            setBanner(image);
            const imageCover = `${gateway}${noCover}`;
            setCoverImage(imageCover);
          }
        } else {
          const image = `${gateway}${bannerNoPut}`;
          setBanner(image);
          const imageCover = `${gateway}${noCover}`;
          setCoverImage(imageCover);
        }
      } catch (err) {
        ////console.log(err);
      }
    }
    getBanner();
  }, [contract, db]);

  return (
    <section className="iaso-aweer">
      <div className="yhry-sa">
        <div className="oekei-sasw">
          <div className="aolke-saer">
            <div className="sai-lkk">
              <React.Fragment>
                <div className="uhgh-hm">
                  <Banner key={contract} banner={banner} />
                </div>
                <div className="asia-awwe">
                  <Cover
                    key={contract}
                    cover={coverImage}
                    name={nameCollection}
                  />
                </div>
              </React.Fragment>
              <div className="sas-awertyu">
                <div className="ksaw">
                  <h2 key={contract} className="as-err">
                    {nameCollection}
                  </h2>
                  {isBlueCheck}
                </div>

                <div className="aswtt-re">
                  <div className="skei-dd">
                    <div className="ijja-ww" onClick={handleToggleDropdown}>
                      <FontAwesomeIcon className="uja-hh" icon={faBars} />
                    </div>

                    <div className={`nh-sh ${isOpen ? "sa-hg" : ""}`}>
                      <div className="lskkww">
                        <a
                          target="_blank"
                          rel="noreferrer noopener"
                          href={`${etherscanGateway}${contract}`}
                          className="slk-sawwr"
                        >
                          <img
                            src={etherscan}
                            alt="etherscan"
                            className="saw-kjsu"
                          />
                        </a>
                      </div>
                      <div className="lskkww">
                        <FavoriteButton
                          collectionAddress={contract}
                          userAddress={userAddress}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="media-saw">
                    <div className="saweui">
                      <ShareButton />
                    </div>
                    <DropdownPoint
                      option1={claimColl}
                      option2={edit}
                      option3={"Report"}
                      contract={contract}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="kjs-swer">
              <div className="loal-weio">
                <div className="akiwk">
                  <div className="sakkje">
                    <Stats
                      floor={crrFloorPrice}
                      volume={Number(crrVolume)}
                      items={crrItems}
                      listed={crrListed}
                      owners={crrOwners}
                      highestSale={crrHighestSales}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="kajw">
              <div className="kjau">
                <div className="awwt">
                  <ShowMore text={description} maxLength={100} />
                </div>
              </div>
            </div>
            <div className="asw-ert">
              <div className="ik-swu">
                <CardBox contract={contract} wallet={wallet} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NewCollectionPage;
