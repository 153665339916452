/* eslint-disable no-unused-vars */
import { toast } from "react-hot-toast";
import { rpcapi } from "../Components/Web3/RPCChain";
import { isTrustWallet, web3Connector } from "../Components/Web3/web3";
import { app } from "../Database/Firebase/firebaseConfig";
import abi from "./ExchangeABI.json";
import abi721 from "./abiERC721.json";
import { BigNumber, ethers } from "ethers";
import abiERC20 from "./abiERC20.json";
import { templateAuction, templateBid, templateOrder } from "./templates";
import { sepoliaAPI } from "./APIs/APIList";

const db = app.firestore();

//const exchangeAddress = "0x491AeDbF920b81eCdcccD7CBfa422cc000658106";
//const exchangeProxy = "0x99e2DAB460066F0Eb8993Fccc9ab7768e037158C";
const exchangeData = "0xd65D25d879f48C5b22E1789821c98eF211e2c744";
const transferHelper = "0x2571501aC2b00c5B2488efE590c9DDfc9d4006F3";
const nullAddress = "0x0000000000000000000000000000000000000000";
const nullHex =
  "0x0000000000000000000000000000000000000000000000000000000000000000";

async function getDecimals(erc2Address) {
  try {
    const provider = new ethers.providers.JsonRpcProvider(sepoliaAPI);
    const signer = provider.getSigner();
    const erc20 = new ethers.Contract(erc2Address, abiERC20, provider);
    const decimals = await erc20.decimals();
    return decimals;
  } catch (err) {
    //console.log(err);
  }
}

async function getWallet(provider) {
  const web3 = await web3Connector(provider);
  const wallet = await web3.eth.getAccounts();
  if (wallet[0] === undefined) {
  } else {
    const lowerAddress = wallet[0].toLowerCase();
    return lowerAddress;
  }
}

async function signMsg(address, hexData) {
  const msg = hexData;
  const account = address;
  const isTrust = await isTrustWallet(rpcapi);
  if (rpcapi || isTrust === true) {
    try {
      const signature = await rpcapi.request({
        method: "personal_sign",
        params: [msg, account],
      });
      return signature;
    } catch (err) {
      //console.log(err);
    }
  }
}

async function approveERC20(tokenCurrency, amount, spender) {
  let tx;
  try {
    const provider = new ethers.providers.Web3Provider(rpcapi);
    const signer = provider.getSigner();
    const contract = new ethers.Contract(tokenCurrency, abiERC20, signer);
    const approveCost = async (e) => {
      const response = await contract.approve(spender, amount, {
        gasLimit: e,
      });
      return response;
    };
    tx = approveCost();
  } catch (err) {
    //console.log(err);
  }

  return tx;
}

async function isApprovedForAll(tokenAddress, spender) {
  try {
    const account = await getWallet(rpcapi);
    const provider = new ethers.providers.Web3Provider(rpcapi);
    const signer = provider.getSigner();
    const erc721 = new ethers.Contract(tokenAddress, abi721, signer);
    const isApproved = await erc721.isApprovedForAll(account, spender);
    return isApproved;
  } catch (err) {}
}

async function approveERC721(tokenAddress, spender) {
  let tx;
  try {
    const provider = new ethers.providers.Web3Provider(rpcapi);
    const signer = provider.getSigner();
    const erc721 = new ethers.Contract(tokenAddress, abi721, signer);
    const approveOperation = async (e) => {
      const response = await erc721.setApprovalForAll(spender, true, {
        gasLimit: e,
      });
      return response;
    };
    tx = approveOperation();
  } catch (err) {
    //console.log(err);
  }
  return tx;
}

async function erc20Symbol(tokenCurrency) {
  try {
    const provider = new ethers.providers.JsonRpcProvider(sepoliaAPI);
    const contract = new ethers.Contract(tokenCurrency, abiERC20, provider);
    const symbol = await contract.symbol();
    if (symbol) {
      return symbol;
    } else {
      //console.log(Error(`Error getting symbol for address: ${tokenCurrency}`));
    }
  } catch (err) {
    //console.log(err);
  }
}

/*
 * New Update remove older address address and methods, update of smart contract
 * changing the method for create new orders and cancel this,
 *
 * Date: 16 August, 2023
 */

async function executeBuy(
  _orderHash,
  asset,
  action,
  collection,
  tokenPayment,
  seller,
  buyer,
  tokenId,
  price,
  expirationTime,
  tokenAmount
) {
  try {
    let tx;
    let erc20;

    const provider = new ethers.providers.Web3Provider(rpcapi);
    const signer = provider.getSigner();
    const contract = new ethers.Contract(exchangeData, abi, signer);
    const account = await getWallet(rpcapi);

    if (tokenPayment === "" || tokenPayment === nullAddress) {
      erc20 = nullAddress;
    } else {
      erc20 = tokenPayment;
      const erc20Approval = await approveERC20(
        tokenPayment,
        price,
        transferHelper
      );
      await erc20Approval.wait();
    }

    const tuple = [
      asset,
      action,
      collection,
      erc20,
      seller,
      buyer,
      tokenId,
      price,
      expirationTime,
      tokenAmount,
    ];

    const signature = await signMsg(account, _orderHash);

    const execute = async (e) => {
      let result;
      if (erc20 === nullAddress) {
        result = await contract.executeBuy(tuple, _orderHash, signature, {
          gasLimit: e,
          value: price,
        });
      } else {
        result = await contract.executeBuyWithERC20(
          tuple,
          _orderHash,
          signature,
          {
            gasLimit: e,
          }
        );
      }
      return result;
    };
    tx = execute();
    return tx;
  } catch (err) {
    //console.log(err.message);
  }
}

async function verifyAuctionCanceled(data) {
  try {
    let hash;
    if (data.event === "AuctionCreated") {
      const args = data.args;
      const tuple = [
        args.auction.asset,
        5,
        args.auction.collection,
        args.auction.paymentToken,
        args.auction.seller,
        args.auction.highestBidder,
        args.auction.tokenId,
        args.auction.reservedPrice,
        args.auction.highestBid,
        args.auction.startTime,
        args.auction.expirationTime,
        args.auction.tokenAmount,
      ];
      const provider = new ethers.providers.JsonRpcProvider(sepoliaAPI);
      const contract = new ethers.Contract(exchangeData, abi, provider);

      hash = await contract._hashAuction(tuple);
    }
    return hash;
  } catch (err) {
    //console.log(err.message);
  }
}

async function getOrders(tokenId) {
  const provider = new ethers.providers.JsonRpcProvider(sepoliaAPI);
  const contract = new ethers.Contract(exchangeData, abi, provider);

  const orderCreatedEvents = await contract.queryFilter(
    contract.filters.OrderCreated()
  );

  const orderCanceledEvents = await contract.queryFilter(
    contract.filters.OrderCanceled()
  );

  const orderFilledEvents = await contract.queryFilter(
    contract.filters.OrderFilled()
  );

  const auctionCreatedEvents = await contract.queryFilter(
    contract.filters.AuctionCreated()
  );

  const auctionCanceledEvents = await contract.queryFilter(
    contract.filters.AuctionCanceled()
  );

  const addOrderCancelEvent = orderCanceledEvents.map((cancelEvent) => {
    // Find the corresponding OrderCreated event
    const correspondingCreateEvent = orderCreatedEvents.find(
      (createEvent) => createEvent.args.orderHash === cancelEvent.args.orderHash
    );

    // Extract tokenId from the corresponding OrderCreated event
    const correspondingTokenId = correspondingCreateEvent
      ? correspondingCreateEvent.args.tokenId
      : ethers.constants.Zero; // You can use a default value here

    const orderHash = cancelEvent.args.orderHash;
    const tokenId = correspondingTokenId;
    return {
      ...cancelEvent,
      args: {
        ...cancelEvent.args,
        orderHash: orderHash,
        tokenId: tokenId,
      },
    };
  });

  const events = [
    ...orderCreatedEvents,
    ...orderFilledEvents,
    ...auctionCreatedEvents,
  ];

  const canceledEvents = [...auctionCanceledEvents];

  // Filter events by tokenId
  const filteredEvents = events.filter(
    (event) => event.args?.tokenId.eq(tokenId)
    // Use .eq() for BigNumber comparison
  );

  const filterCanceledOrders = addOrderCancelEvent.filter((event) =>
    event.args?.tokenId.eq(tokenId)
  );

  // Sort events by blockNumber
  filteredEvents.sort((a, b) => b.blockNumber - a.blockNumber);

  const hash = await verifyAuctionCanceled(filteredEvents[0]);

  // Filter events by tokenId
  const filteredAuctionEvents = canceledEvents.filter(
    (event) => event.args?.orderHash === hash
    // Use .eq() for BigNumber comparison
  );

  const auctionCanceled = await Promise.all(
    filteredAuctionEvents.map((item) => {
      return {
        ...item,
        args: {
          ...item.args,
          tokenId,
          orderHash: hash,
        },
      };
    })
  );

  const newEvents = [
    ...filteredEvents,
    ...filterCanceledOrders,
    ...auctionCanceled,
  ];

  newEvents.sort((a, b) => b.blockNumber - a.blockNumber);

  //console.log(newEvents);

  return newEvents;
}

async function getActivitiesByToken(contractAddr, tokenId) {
  try {
    const provider = new ethers.providers.JsonRpcProvider(sepoliaAPI);
    const contract = new ethers.Contract(exchangeData, abi, provider);
    const erc721 = new ethers.Contract(contractAddr, abi721, provider);

    const orderFilledEvents = await contract.queryFilter(
      contract.filters.OrderFilled()
    );

    const transferEvent = await erc721.queryFilter(erc721.filters.Transfer());

    const events = [...orderFilledEvents, ...transferEvent];

    const filteredEvents = events.filter(
      (event) => event.args?.tokenId.eq(tokenId)
      // Use .eq() for BigNumber comparison
    );

    filteredEvents.sort((a, b) => b.blockNumber - a.blockNumber);

    const formattedEvents = filteredEvents;

    return formattedEvents;
  } catch (err) {
    //console.log(err.message);
  }
}

async function executeSellOrCancel(
  _orderHash,
  asset,
  action,
  collectionAddress,
  erc20Addr,
  seller,
  buyer,
  tokenId,
  orderPrice,
  expirationTime,
  tokenAmount
) {
  try {
    let erc20;
    let tx;
    const provider = new ethers.providers.Web3Provider(rpcapi);
    const signer = provider.getSigner();
    const contract = new ethers.Contract(exchangeData, abi, signer);
    const account = await getWallet(rpcapi);

    if (erc20Addr === "" || erc20Addr === nullAddress) {
      erc20 = nullAddress;
    } else {
      erc20 = erc20Addr;
    }

    /*
     * The new method require of a tuple to can realize the order,
     * this way we have implemented one function in one caller function.
     */
    const tuple = [
      asset,
      action,
      collectionAddress,
      erc20,
      seller,
      buyer,
      tokenId,
      orderPrice,
      expirationTime,
      tokenAmount,
    ];

    let orderHash;

    if (action === 0) {
      orderHash = await contract._hashOrder(tuple);
    } else {
      orderHash = _orderHash;
    }

    const signature = await signMsg(account, orderHash);

    ////console.log(tuple, signature);

    const isApproval = await isApprovedForAll(
      collectionAddress,
      transferHelper
    );
    if (!isApproval) {
      const approve = await approveERC721(collectionAddress, transferHelper);
      await approve.wait();
    }

    const execute = async (e) => {
      const executeCall = await contract.executeSellOrCancel(tuple, signature, {
        gasLimit: e,
      });
      return executeCall;
    };
    tx = execute();

    //console.log(tx.hash);
    return tx;
  } catch (err) {
    //console.log(err);
  }
}

async function balanceOf(tokenPayment) {
  try {
    const provider = new ethers.providers.JsonRpcProvider(sepoliaAPI);
    const contract = new ethers.Contract(tokenPayment, abiERC20, provider);
    const account = await getWallet(rpcapi);

    const balance = await contract.balanceOf(account);
    const balaceFormatted = BigNumber.from(balance).toString();
    const decimals = await getDecimals(tokenPayment);
    const priceInToken = (
      Number(balaceFormatted) / Math.pow(10, decimals)
    ).toFixed(4);
    return priceInToken;
  } catch (err) {}
}

async function executeAuction(
  _orderHash,
  asset,
  action,
  collection,
  paymentToken,
  seller,
  highestBidder,
  tokenId,
  reservedPrice,
  highestBid,
  startTime,
  expirationTime,
  tokenAmount
) {
  try {
    let erc20;
    let tx;
    const provider = new ethers.providers.Web3Provider(rpcapi);
    const signer = provider.getSigner();
    const contract = new ethers.Contract(exchangeData, abi, signer);
    const account = await getWallet(rpcapi);

    if (paymentToken === "" || paymentToken === nullAddress) {
      erc20 = nullAddress;
    } else {
      erc20 = paymentToken;
    }

    const tuple = [
      asset,
      action,
      collection,
      erc20,
      seller,
      highestBidder,
      tokenId,
      reservedPrice,
      highestBid,
      startTime,
      expirationTime,
      tokenAmount,
    ];

    //console.log(tuple);

    let orderHash;
    if (action === 2 || action === 5) {
      orderHash = await contract._hashAuction(tuple);
    } else {
      orderHash = _orderHash;
    }

    const signature = await signMsg(account, orderHash);

    const isApproval = await isApprovedForAll(collection, transferHelper);
    if (!isApproval) {
      const approve = await approveERC721(collection, transferHelper);
      await approve.wait();
    }

    const execute = async (e) => {
      const executeCall = await contract.executeAuction(tuple, signature, {
        gasLimit: e,
      });
      return executeCall;
    };
    tx = execute();

    //console.log(tx.hash);
    return tx;
  } catch (err) {
    //console.log(err.message);
  }
}

async function executeBid(
  _orderHash,
  asset,
  action,
  collection,
  paymentToken,
  seller,
  highestBidder,
  tokenId,
  reservedPrice,
  highestBid,
  startTime,
  expirationTime,
  tokenAmount
) {
  try {
    let erc20;
    let tx;
    const provider = new ethers.providers.Web3Provider(rpcapi);
    const signer = provider.getSigner();
    const contract = new ethers.Contract(exchangeData, abi, signer);
    const account = await getWallet(rpcapi);

    if (paymentToken === "" || paymentToken === nullAddress) {
      erc20 = nullAddress;
    } else {
      erc20 = paymentToken;
    }

    const tuple = [
      asset,
      action,
      collection,
      erc20,
      seller,
      highestBidder,
      tokenId,
      reservedPrice,
      highestBid,
      startTime,
      expirationTime,
      tokenAmount,
    ];

    //console.log(tuple);

    const signature = await signMsg(account, _orderHash);

    const execute = async (e) => {
      let executeCall;
      if (erc20 !== nullAddress) {
        executeCall = await contract.executeBidWithERC20(
          tuple,
          _orderHash,
          signature,
          {
            gasLimit: e,
          }
        );
      } else {
        executeCall = await contract.executeBid(tuple, _orderHash, signature, {
          gasLimit: e,
          value: highestBid,
        });
      }
      return executeCall;
    };
    tx = execute();

    //console.log(tx.hash);
    return tx;
  } catch (err) {
    //console.log(err.message);
  }
}

async function getAllBidsByTokenId(tokenId, orderHash) {
  try {
    const provider = new ethers.providers.JsonRpcProvider(sepoliaAPI);
    const contract = new ethers.Contract(exchangeData, abi, provider);

    const auctionBidEvents = await contract.queryFilter(
      contract.filters.NewBidPlaced()
    );

    const events = [...auctionBidEvents];

    const filteredEvents = events.filter(
      (event) =>
        event.args?.tokenId.eq(tokenId) && event.args.orderHash === orderHash
      // Use .eq() for BigNumber comparison
    );

    filteredEvents.sort((a, b) => b.blockNumber - a.blockNumber);

    const formattedEvents = filteredEvents;

    return formattedEvents;
  } catch (err) {
    //console.log(err.message);
  }
}

async function getExpirationTime(orderHash) {
  try {
    const provider = new ethers.providers.JsonRpcProvider(sepoliaAPI);
    const contract = new ethers.Contract(exchangeData, abi, provider);

    const expirationTime = await contract._expirationTime(orderHash);

    const timeFormatted = BigNumber.from(expirationTime).toNumber();
    return timeFormatted;
  } catch (err) {
    //console.log(err.message);
  }
}

export {
  getOrders,
  executeSellOrCancel,
  nullAddress,
  abi721,
  getDecimals,
  erc20Symbol,
  exchangeData,
  nullHex,
  executeBuy,
  getActivitiesByToken,
  balanceOf,
  executeAuction,
  executeBid,
  getAllBidsByTokenId,
  getExpirationTime,
  abi,
};
