import Web3 from "web3";

const ipfsGateway = "https://ipfs.io/ipfs/";

async function web3Connector(provider) {
  return new Web3(provider);
}

async function isTrustWallet(provider) {
  const trust = provider.isTrust;
  return trust;
}

export { web3Connector, isTrustWallet, ipfsGateway };
