/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import Web3 from "web3";
import { rpcapi } from "../../../Web3/RPCChain";
import { Link, useLocation, useParams } from "react-router-dom";
import { app } from "../../../../Database/Firebase/firebaseConfig";
// Add import statement for Banner component
import Banner from "../NewHeaders/Banner";
import Cover from "../NewHeaders/Cover";
import "../../../../Styles/Profile.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import Holding from "./Holding";

const web3 = new Web3(new Web3(rpcapi));

const gateway = "https://ipfs.io/ipfs/"; //Gateway Image
const bannerNoPut = "QmRLFx8ACZUy3q9yEihAUissWQEh3pKek7qAoZNRbXsTwm";
const noCover = "QmaoRr4Rdgrnod7Hs6ULZVJibzH2xmLq4fGySR3Tg2Dfzj";
const etherscanGateway = "https://etherscan.io/address/";

const noCoverData = `${gateway}${noCover}`;
const noBannerData = `${gateway}${bannerNoPut}`;

const Profile = () => {
  const [wallet, setWallet] = useState("");
  // Use single instance of useParams
  const { address } = useParams();
  const [usernameData, setUsernameData] = useState("");
  // Add default state to banner
  const [banner, setBanner] = useState(noBannerData);
  const [cover, setCover] = useState(noCoverData);
  const [copied, setCopied] = useState(false);
  const [balanceData, setBalanceData] = useState([]);

  const location = useLocation();

  let componentToRender;

  switch (location.hash) {
    case "#holding":
      componentToRender = <Holding address={address} />;
      break;
    /*     case "#created":
      componentToRender = <Created />;
      break; */
    default:
      componentToRender = <Holding address={address} />;
      break;
  }

  const handleCopyClick = async () => {
    try {
      const account = await getWallet();
      if (navigator.clipboard) {
        await navigator.clipboard.writeText(address);
        setCopied(true);
      } else {
        console.error("Clipboard API not supported");
        setCopied(false);
      }
    } catch (error) {
      console.error("Failed to copy text: ", error);
      setCopied(false);
    }
  };

  //Get Wallet
  async function getWallet() {
    try {
      const wallet = await web3.eth.getAccounts();
      if (wallet[0] === undefined) {
        return "";
      } else {
        const lowerAddress = wallet[0].toLowerCase();
        setWallet(lowerAddress);
        return lowerAddress;
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    async function fetchData() {
      await getWallet();
    }
    fetchData();
  }, []);

  const db = app.firestore();

  useEffect(() => {
    async function checkUser() {
      try {
        const checkRef = db.collection("users").doc(address);
        // Fix typo in unsubscribe variable name
        const unsubscribe = checkRef.onSnapshot((data) => {
          if (data.exists) {
            const { username } = data.data();
            if (username) {
              setUsernameData(username);
            } else {
              setUsernameData(
                address.substring(0, 8) +
                  "..." +
                  address.substring(address.length - 8)
              );
            }
          } else {
            setUsernameData(
              address.substring(0, 8) +
                "..." +
                address.substring(address.length - 8)
            );
          }
        });
        return unsubscribe;
      } catch (err) {
        ////console.log(err);
      }
    }

    checkUser();

    // Call checkUser only once when the component mounts
  }, [address, db]);

  return (
    <div className="als5-ae5">
      <div className="a85">
        <div className="jjyh">
          <div className="kjh">
            <div className="hgt">
              <div className="l84j">
                {/* Use banner state to render Banner component */}
                <div className="ujys-aay">
                  <Banner banner={banner} />
                </div>
              </div>
            </div>
            <div className="aa7w5">
              <div className="as4a5e">
                <div className="4aswwe3">
                  <Cover cover={cover} />
                </div>
              </div>
            </div>
            <div className="yh-44sa">
              <div className="1cs2">
                <div className="a4s5">
                  <div className="aioe554">
                    <div className="sd45eyt"> {usernameData}</div>
                  </div>
                  <div className="4s15">
                    <p className="s4a1w">{usernameData}</p>
                    <div className="45ike-r">
                      <FontAwesomeIcon
                        onClick={handleCopyClick}
                        icon={faCopy}
                      />
                      {copied ? "Copied!" : "Copy"}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="45fryu">
          <div className="74a5awertr">
            <div className="as4a1e-tty">
              <div className="as54r">
                <div className="7sw4">
                  <Link to={`/account/${address}#holding`} className="ol4a5">
                    <div className="lask45">
                      <p className="a4d5j">Holding</p>
                    </div>
                  </Link>
                </div>
                {/*                 <div className="7sw4">
                  <Link to={`/account/${address}#created`} className="ol4a5">
                    <div className="lask45">
                      <p className="a4d5j">Created</p>
                    </div>
                  </Link>
                </div> */}
              </div>
            </div>
            <div className="awe41">
              <div className="a45we">
                <div className="1f2y5">
                  <div className="a41tt">
                    <div className="as5f4">{componentToRender}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
