import React from "react";
import BoxAttribute from "./boxAttribute";
import { makeId } from "../../../../Utils/nonceGenerator";

const ContentAttribute = ({ data }) => {
  return (
    <div className="sdsfj-fdsss">
      {data.map((a) => (
        <BoxAttribute key={makeId(10)} keyAtt={a.trait_type} value={a.value} />
      ))}
    </div>
  );
};

export default ContentAttribute;
