import ERC721Abi from "./abiERC721.json";
import { web3Connector } from "../Components/Web3/web3";
import abi from "../Utils/SDKPolarysNFTs/lib/web3/abi.json";
import { sepoliaAPI } from "./APIs/APIList";

const getCurrentOwner = async (tokenAddress, chainUrl, tokenId) => {
  try {
    const web3 = await web3Connector(chainUrl);
    const contract = new web3.eth.Contract(ERC721Abi, tokenAddress);
    const checkOwner = await contract.methods.ownerOf(tokenId).call();
    return checkOwner.toLowerCase();
  } catch (error) {
    //console.error("Error in getCurrentOwner:", error.message);
    //throw error; // Rethrow the error for the calling function to handle
  }
};

const getName = async (contractAddress) => {
  try {
    const web3 = await web3Connector(sepoliaAPI);
    const contract = new web3.eth.Contract(abi, contractAddress);
    const name = await contract.methods.name().call();
    return name;
  } catch (err) {
    //console.log("Error in getName" + err.message);
    //throw err;
  }
};

export { getCurrentOwner, getName };
