import React from "react";
import "./TokenInfo.css";
import { makeId } from "../../../../Utils/nonceGenerator";
import { useState } from "react";
import { useEffect } from "react";

const TokenInfo = ({ contractAddress, tokenId, tokenOwner }) => {
  const [ownerLength, setOwnerLength] = useState(false);

  useEffect(() => {
    if (tokenOwner.length > 1) {
      setOwnerLength(true);
    } else {
      setOwnerLength(false);
    }
  }, [tokenOwner]);

  return (
    <div className="kkf-hg11fd">
      <div className="akki1d52">
        <div className="ds15-gfg">
          <div className="sldi455fd">
            <div className="slaks-gff">
              <div className="box-container-skjd">
                <div className="content-skajt-s">Contract:</div>
                <a
                  rel="noreferrer"
                  target="_blank"
                  href={`https://sepolia.etherscan.io/address/${contractAddress}`}
                  className="content-skajt-s"
                >
                  {contractAddress && contractAddress.substring(0, 5)}...
                  {contractAddress &&
                    contractAddress.substring(
                      contractAddress && contractAddress.length - 5
                    )}
                </a>
              </div>
              <hr className="sas-gg" />
              <div className="box-container-skjd">
                <div className="content-skajt-s">TokenId: </div>
                <div className="content-skajt-s">{tokenId}</div>
              </div>
              <hr className="sas-gg" />
              {ownerLength ? (
                <div key={makeId(10)} className="box-container-skjd">
                  <div className="content-skajt-s">Current Owner: </div>
                  <div className="saslk-ff">{tokenOwner.length}</div>
                </div>
              ) : (
                tokenOwner.map((data) => (
                  <div key={makeId(10)} className="box-container-skjd">
                    <div className="content-skajt-s">Current Owner: </div>
                    <a
                      rel="noreferrer"
                      target="_blank"
                      href={`https://etherscan.io/address/${data.owner}`}
                      className="content-skajt-s"
                    >
                      {data.owner && data.owner.substring(0, 5)}...
                      {data.owner &&
                        data.owner.substring(
                          data.owner && data.owner.length - 5
                        )}
                    </a>
                  </div>
                ))
              )}
              <hr className="sas-gg" />
              <div className="box-container-skjd">
                <div className="content-skajt-s">Blockchain:</div>
                <div className="content-skajt-s">Sepolia</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TokenInfo;
