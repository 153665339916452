import React from "react";
import { makeId } from "../../../../Utils/nonceGenerator";

const BoxAttribute = ({ keyAtt, value }) => {
  return (
    <div key={makeId(10)} className="sja4fg5">
      <div className="aks-rtyui4">
        <div className="sak45h key">{keyAtt}</div>
        <div className="sak45h value">{value}</div>
      </div>
    </div>
  );
};

export default BoxAttribute;
