import React from "react";
import "./DropdownAddress.css";

const DropdownAddress = ({ addresses, onSelect }) => {
  return (
    <React.Fragment>
      <div className="saW_EWRTYUjjj_U">Select ERC20 Address</div>
      <select className="sE_Ttg_D" onChange={(e) => onSelect(e.target.value)}>
        <option value="">Select an address</option>
        {addresses.map((address) => (
          <option key={address.symbol} value={address.address}>
            {address.symbol}
          </option>
        ))}
      </select>
    </React.Fragment>
  );
};

export default DropdownAddress;
