import React from "react";
import "../../../../Styles/Banner.css";

const Banner = ({ banner }) => {
  return (
    <div className="ju-we">
      <div className="lo-sa">
        <div className="la-aw">
          <div className="aki-ee">
            <img
              src={banner}
              alt="Banner"
              className="asw-saawr"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
