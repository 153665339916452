import React from "react";
import NFTCard from "./NFTCard";
import "../../../Styles/data2.css";

const Data2 = () => {
  return (
    <div className="kias">
      <div className="alo-a fadeIn">
        <div className="allw-rt">
          <NFTCard />
          <div className="ooi-ooi">
            <div className="skek-es">
              <span className="sjkk-ass">Buy,</span>
              <div className="saslk-sasw4">
                <span className="sas--f11hy">Sell and</span>
                <span className="sas--f11hy">Discover</span>
              </div>
              <div className="sakl-44dse">
                At TAGWEB3 you can buy and sell your digital assets, as
                well as discover new trending collections every day.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Data2;
