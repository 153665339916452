/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from "react";
import "../../../../Styles/Showmore.css";

function ShowMore({ text, maxLength }) {
  const [showFullText, setShowFullText] = useState(false);

  if (text === undefined) {
    text = "";
  }

  if (text.length <= maxLength) {
    return <p>{text}</p>;
  }

  const truncatedText = showFullText ? text : `${text.slice(0, maxLength)}...`;

  return (
    <>
      <p className="dse">{truncatedText}</p>
      <a className="ika-ww" onClick={() => setShowFullText(!showFullText)}>
        {showFullText ? "Show Less" : "Show More"}
      </a>
    </>
  );
}

export default ShowMore;
