import axios from "axios";

const apiRarible =
  "https://api.rarible.org/v0.1/collections/all?blockchains=ETHEREUM";

const apiRarible2 =
  "https://api.rarible.org/v0.1/data/rankings/collections/volume?limit=30&blockchain=ETHEREUM";

const apiRarible3 = "https://api.rarible.org/v0.1/collections/ETHEREUM:";

const api = "3dab2eba-c515-41c4-8b0c-b3c29f0d4d65";

const getAllNfts = async (nextData) => {
  try {
    const headers = {
      "X-API-KEY": api, // Replace 'YOUR_API_KEY_HERE' with your actual API key
    };

    const url =
      nextData !== null
        ? `${apiRarible}&continuation=${nextData}`
        : `${apiRarible}`;
    const response = await axios.get(url, { headers });
    return response.data;
  } catch (err) {
    //console.log(err);
  }
};

const getCollectionDataById = async (address) => {
  try {
    const headers = {
      "X-API-KEY": api, // Replace 'YOUR_API_KEY_HERE' with your actual API key
    };
    const url = `${apiRarible3}${address}`;
    const response = await axios.get(url, { headers });
    return response.data;
  } catch (err) {
    //console.log(err);
  }
};

const getAllNftsByVolume = async (nextData) => {
  try {
    const headers = {
      "X-API-KEY": api, // Replace 'YOUR_API_KEY_HERE' with your actual API key
    };

    const url =
      nextData !== null
        ? `${apiRarible2}&continuation=${nextData}`
        : `${apiRarible2}`;
    const response = await axios.get(url, { headers });
    return response.data;
  } catch (err) {
    //console.log(err);
  }
};

export { getAllNfts, getAllNftsByVolume, getCollectionDataById };
