import { ETHEREUM, SEPOLIA } from "../lib/web3/chains.js";

class EvmChainClass {
  constructor() {
    this.ETHEREUM = ETHEREUM;
    this.SEPOLIA = SEPOLIA;
  }
}

const EvmChain = new EvmChainClass();

export default EvmChain;
