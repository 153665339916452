export function generateToken(length) {
  const characters =
    "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  const tokenLength = length; // The total length of the token, including the tag and hyphens

  let token = "tagweb3-";

  for (let i = 0; i < tokenLength - 8; i++) {
    token += characters.charAt(Math.floor(Math.random() * characters.length));
  }

  return token;
}
